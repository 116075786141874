import React,{ useState} from 'react';
import { Grid } from '@mui/material';
import Icon from 'components/icons/icon'
import { styled } from '@mui/material/styles';
import {Link} from 'react-router-dom'

const ContainerGrid = styled(Grid)`
        display: flex;
        flex-direction: column;
        height: 170px;
        padding: 15px;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        background-color: white;
        border-radius: 15px;
        color:var(--pxft-darkgrey);
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.17));
        transition: 0.2s linear;

        &:hover {
            background-color: var(--ynt-primary);
        },

        & .icon {
            padding: 10px 0;
        }
        & .content {
            display: flex;
            flex-direction: column;
            width: 80%;
        }
        & .content h5{
            padding: 0 0 10px 0;
        }
`;

const IconCardB = ({IconCard})=> {
        const [isHover,setIsHover] = useState(false);
        const mouseEnter = () =>{
            setIsHover(true);
        }
        const mouseOut = () =>{
            setIsHover(false);
        }
        return (
            
            <ContainerGrid item xs={6} md={3} sx={{m:2}} width={250} minHeight={IconCard.height} onMouseEnter={() => mouseEnter()} onMouseLeave={() => mouseOut()}>
            <Link to={IconCard.link} className='center-align' style={{flexDirection:'column'}}>
                <div className='icon'>
                    <Icon icon={IconCard.icon} width={IconCard.iconWidth} height={IconCard.iconHeight} fill={isHover? 'white':'var(--ynt-primary)'}/>
                </div>
                <div className='content' style={{color:isHover? 'white':'var(--ynt-darkgrey)'}}>
                    <h5>{IconCard.title}</h5>
                </div>
            </Link>
            </ContainerGrid>
        );
}

export default IconCardB;