import Support from 'components/solution/support/Support';
import React,{ useEffect} from 'react';

const SolutionMarketing = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)}
    )

    return (
        <div>
            <Support/>
        </div>
    );
}

export default SolutionMarketing;