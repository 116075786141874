import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MobileMenu from '../base/Navi/mobileMenu';
import naviData from '../../data/navi/naviLinkItem.json';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const HeaderNavigation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!naviData) return null;

  if (isMobile) {
    return <MobileMenu />;
  }
  return (
    <nav className="site-main-menu">
      <ul>
        {naviData.map((item, i) => {
          // Hide the Product element. TODO: show when needed
          // if (i === 2) return null;

          if ((item.list.length > 1 || (item.list.length === 1 && item.title !== item.list[0].text))) {
            return (
              <li key={`nav-${item.id}-${i}`} className="has-children">
                <NavLink to="#">
                  <span className="menu-text">
                    {item.title}
                    <KeyboardArrowDownIcon
                      fontSize=""
                      sx={{ pl: 0.3, mt: 0.3 }}
                    />
                  </span>
                </NavLink>
                <ul className="sub-menu">
                  {item.list.map((listItem, i) => (
                    <li key={`subnav-${listItem.id}-${i}`}>
                      {
                        <Link
                        to={listItem.url}
                        target={listItem.outSource ? '_blank' : ''}
                        rel="noopener noreferrer"
                      >
                        {listItem.text}
                      </Link>
                      }

                    </li>
                  ))}
                </ul>
              </li>
            )
          }

          return (
            <li key={i}>
              {
              <Link
                to={item.list[0].url}
                target={item.list[0].outSource ? '_blank' : ''}
                rel="noopener noreferrer"
              >
                {item.title}
              </Link>
          }
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default HeaderNavigation;