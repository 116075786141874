import React from 'react';
import { Box,Link } from '@mui/material';
import BasicButton from '../buttons/basicButton';
import { styled } from '@mui/material/styles';

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;
        color: white;

        & .title {
            display: flex;
            flex-direction: column;
            text-align:left;
            white-space: pre-line;
        }

        & .line {
            padding: 12px 0;
            border-top:2px solid var(--ynt-blue);
        }

        & .content {
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
            color: var(--ynt-midgrey);
        }

        & .btn-container {
            position: absolute;
            bottom: 0;
            right:0;
            width: 200px;
            height:40;
        }
`;

const HeaderCard = ({long=true,title='',content='',button=true,btnText='Learn More',link=''}) =>{ 
    return (
        <ArticleBox className='headerBox' sx={{py:6,px:3,width:{xs:'100%',md:'60%'},height:long?{xs:'350px',sm:'400px',md:'450px'}:{}}}>
            <Box className='title'>
                <h1>{title}</h1>
            </Box>
            <p className='content'>{content}</p>
            {button? 
            <div>
                <Link href={link} target="_blank" rel="noopener noreferrer">
                    <BasicButton content={btnText} width='70%'/>
                </Link>
            </div>:''}
        </ArticleBox>
    );
}

export default HeaderCard;