import React from 'react';
import { Grid, Grow, Slide, Link } from '@mui/material';
import { useInView } from 'react-intersection-observer'
import CenterTitle from 'components/mui/containers/centerTitle';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ArticleCardA from 'components/mui/cards/articleCardA';
import BasicRowA from 'components/mui/containers/basicRowA';
import OverviewSteps from './OverviewSteps';
import OverviewFaq from './OverviewFaq';
import OverviewFeatures from './OverviewFeatures';
import { Box } from '@mui/material';
import BasicButton from 'components/mui/buttons/basicButton';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from './ScrollableQuotes';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;

        & .title {
            display: flex;
            flex-direction: row;
            color:black;
            text-align:left;
            white-space: pre-line;
        }
        & .title h1{
            font-size: 38px;
        }

        & .content {
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
        }

        & .btn-container {
            position: absolute;
            bottom: 0;
            left:20px;
            width: 130px;
            height:40;
        }
`;
const OverviewArticle = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title: 'Create your AI model in minutes',
            content: 'Design tailored AI models that integrate directives, enhanced knowledge, and a diverse array of skills to suit your needs.',
            src: '/images/home/feature-1.png'
        },
        {
            title: 'Communicate with it',
            content: 'Have a personalized AI model at your disposal for any queries related to business, analytics, writing, education, personal tasks, or any cognitive work that you require assistance with. You maintain authority over the type of data that the AI can comprehend and analyze.',
            src: '/images/home/feature-2.png'
        },
        {
            title:'Train AI to mimic your style of writing',
            content:'By connecting your Gmail/Outlook conversations to your AI model, you can train it to write the same style as you. It will also learn what you do and how you communicate allowing it to gain more knowledge about your business.',
            src:'/images/business/feature-2.png'
        },
        {
            title: 'Use it anywhere you want',
            content: 'Add the Younet browser extension and simplify your response across all platforms, including CRM, email, help desk, social media, online forms, websites, and more.',
            src: '/images/home/feature-3.png'
        },
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const containerRef1 = React.useRef(null);
    return (
        <div className='home-article-container'>
            <WideContainer>
                <Grid container sx={{ py: { md: 3 } }}>
                    <SupportedCompanies/>
                </Grid>
                <div className='home-feature-column column-container center-align'>
                    <Grid container sx={{ py: { md: 3 } }} className='center-align'>
                        <BasicRowA title={rows[0].title} contents={rows[0].content} picSrc={rows[0].src} button={false} long={false} />
                    </Grid>

                    <Grid container sx={{ py: { md: 3 } }} className='center-align'>
                        <WideContainer>
                            <div className='center-align' style={{ overflow: 'hidden' }}>
                                <NarrowContainer>
                                    <Slide timeout={{ enter: 800 }} in={inView1} direction="right" container={containerRef1.current}>
                                        <Grid item xs={12} sm={12} md={5} className='center-align'>
                                            <ArticleCardA title={rows[1].title} content={rows[1].content} button={false} long={false} />
                                        </Grid>
                                    </Slide>
                                    <Grow timeout={{ enter: 1200 }} in={inView1} className='center-align'>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <OverviewFeatures />
                                        </Grid>
                                    </Grow>
                                </NarrowContainer>
                                <div className='observer' ref={inViewRef1}></div>
                            </div>
                        </WideContainer>
                    </Grid>

                    <Grid container sx={{ py: { md: 3 } }} className='center-align'>
                        <BasicRowA title={rows[2].title} contents={rows[2].content} picSrc={rows[2].src} button={false} long={false} />
                    </Grid>

                    <Grid container sx={{ py: { md: 3 } }} className='center-align'>
                        <WideContainer>
                            <div className='center-align' style={{ overflow: 'hidden' }}>
                                <NarrowContainer>
                                    <Slide timeout={{ enter: 800 }} in={inView} direction="right" container={containerRef.current}>
                                        <Grid item xs={12} sm={12} md={6} className='center-align'>
                                            <ArticleBox sx={{ py: 6, px: 3, width: '80%' }}>
                                                <Box className='title'>
                                                    <h1>{rows[3].title}</h1>
                                                </Box>
                                                <p className='content'>{rows[3].content}</p>
                                                <Grid my={2}>
                                                    <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'center', md: 'start' }}>
                                                        <Link py={1} href='https://chrome.google.com/webstore/detail/younet-personalized-ai-to/ahabdciobjaamioehoaifaoabkimhegj'
                                                            target='_blank' rel='noreferrer' underline="none">
                                                            <BasicButton width='200px' content='Add to Chrome, it‘s free' />
                                                        </Link>
                                                        <Link href='/chrome-extension' underline="none" py={2.5} px={{ md: 3 }} sx={{ color: 'var(--ynt-primary)', fontSize: '14px', fontWeight: 400 }}>
                                                            Learn more...
                                                        </Link>
                                                    </Box>
                                                    <Box my={2}>
                                                        {isMobile ? <img src='/images/home/extension-support-mobile.png' alt='extension-logos' /> : <img src='/images/home/extension-support.png' alt='extension-logos' />}
                                                    </Box>
                                                </Grid>
                                            </ArticleBox>
                                        </Grid>
                                    </Slide>
                                    <Grow timeout={{ enter: 1200 }} in={inView}>
                                        <Grid item xs={12} sm={12} md={6} className='center-align'>
                                            <img loading='lazy' className='contentPic' src={rows[3].src} alt='' />
                                        </Grid>
                                    </Grow>
                                </NarrowContainer>
                                <div className='observer' ref={inViewRef}></div>
                            </div>
                        </WideContainer>
                    </Grid>
                </div>
            </WideContainer>
            <WideContainer>
                <ScrollableQuotes showLink={true}/>
            </WideContainer>
            <WideContainer>
                <div className='home-steps-column column-container center-align'>
                    <Grid container sx={{ py: 2 }}>
                        <CenterTitle title='How can you start using it?' />
                    </Grid>
                    <NarrowContainer>
                        <OverviewSteps />
                    </NarrowContainer>
                </div>
            </WideContainer>
            <WideContainer>
                <div className='home-faq-column column-container center-align'>
                    <Grid container sx={{ py: 2 }}>
                        <CenterTitle title='Frequently Asked Questions' />
                    </Grid>
                    <NarrowContainer className='center-align'>
                        <OverviewFaq />
                    </NarrowContainer>
                </div>
            </WideContainer>
        </div>
    );
}

export default OverviewArticle;