import React,{ useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Grid, Box } from '@mui/material';
import { Navigation, FreeMode} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';

function VideoSlider({videos}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [activeIndex, setActiveIndex] = useState(); 
    const swiperRef = useRef();

    const changeSwiperHandler = (id) => {
        swiperRef.current.slideToLoop(id) 
    }

    return (
        <Grid container className='swiper-slide'>
            <Grid item xs={12} className='slider-container'>
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#9292F5',
                      }}
                    modules={[FreeMode, Navigation]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    loop
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                    >
                    {
                    videos.map((video,i) => (
                        <SwiperSlide key={i}>
                        <div className='video-container center-align'>
                            {isMobile ? <iframe width="280" height="215" src={video.videoSrc} title={video.videoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                : <iframe width="840" height="472.5" src={video.videoSrc} title={video.videoTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                        </div>
                        </SwiperSlide>))
                    }
                </Swiper>
            </Grid>
            <Grid item xs={12} className='center-align'>
                <Grid container className='center-align'>
                    {
                        videos.map((video,i)=> (
                                video.videoThumb &&
                                    <Grid key={i} className={`thumb-swiper ${i === activeIndex ? "thumb-active":""}`} 
                                    onClick={()=>{changeSwiperHandler(i)}}
                                    >
                                        <img loading='lazy' src={video.videoThumb} alt=''/>
                                    </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default VideoSlider;