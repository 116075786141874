import React from 'react';

const CenterTitle=({title,article})=> {
    return (
        <div className='center-align title-container'>
            <h1>{title}</h1>
            <p>{article}</p>
        </div>
    );
}

export default CenterTitle;