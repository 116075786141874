import LandingArticle from 'components/landing/LandingArticle';
import React,{useEffect} from 'react';
import 'assets/styles/home.css'

const Landing=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <LandingArticle/>
        </div>
    );
}

export default Landing;