import React from 'react';
import {Grid,Grow,Slide,Link} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import CenterTitle from 'components/mui/containers/centerTitle';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ArticleCardA from 'components/mui/cards/articleCardA';
import { Box } from '@mui/material';
import BasicButton from 'components/mui/buttons/basicButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ChromeExtensionVideo=()=> {
    const rows=[
        {
            title:'Reply in seconds',
            content:'Generate responses to emails effortlessly, reflecting your unique communication style.',
        },
        {
            title:'Write in any text form',
            content:'Generate personalized content anywhere. Let your personalized AI model assist you in writing.',
        },        
        {
            title:'Customize response',
            content:'To add a personal touch, highlight text, then use the arrow button and provide additional context.',
        },
    ]
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const containerRef1 = React.useRef(null);

    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const containerRef3 = React.useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <WideContainer>
            <div className='center-align'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CenterTitle title='Write & Respond Anywhere'/>
                        <Box className='center-align'>
                        <p style={{textAlign:'center'}}>With the Younet Chrome Extension, you can now bring the power of personalized AI assistance to any website or platform.</p>
                        </Box>
                        <Box pt={3} className='center-align' display='flex' flexDirection='column'>
                        <Link underline='none' href='https://chrome.google.com/webstore/detail/younet-personalized-ai-to/ahabdciobjaamioehoaifaoabkimhegj' target='_blank' rel='noreferrer' ><BasicButton width='200px' content='Add to Chrome, it‘s free'/></Link>
                        <Box pt={3} sx={{width:'80%',maxWidth:'800px'}} className='center-align'>
                        {isMobile? <img width='100%' src='/images/home/extension-support-mobile.png' alt='extension-logos'/>:
                        <img width='100%' src='/images/home/extension-support.png' alt='extension-logos'/>}
                        </Box>
                        </Box>
                    </Grid>
            </div>
            <div className='center-align'>
            <Grid sx={{pt:{md:5,xs:3},px:2}}>
                <Grid container sx={{py:{md:5}}} className='center-align'>
                    <WideContainer className='chrome-extension-article-container'>
                        <div className='center-align' style={{overflow:'hidden'}}>
                            <NarrowContainer>
                                <Slide timeout={{enter:800}} in={inView1} direction="right" container={containerRef1.current}>
                                    <Grid item xs={12} sm={12} md={5} className='center-align'>
                                        <ArticleCardA title={rows[0].title} content={rows[0].content} button={false} long={false}/>
                                    </Grid>
                                </Slide>
                                <Grow timeout={{enter:1200}} in={inView1} className='center-align'>
                                    <Grid item xs={12} sm={12} md={7}>
                                        <div className='video-container center-align'>
                                        {isMobile? <iframe width="288" height="216" src="https://www.youtube.com/embed/_Y4YLB0nDe4?si=Av1-UK4KkWBfUI_Y?&controls=1&autopause=0&&autoplay=1&mute=1&loop=1&playlist=_Y4YLB0nDe4&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        :<iframe width="576" height="432" src="https://www.youtube.com/embed/_Y4YLB0nDe4?si=Av1-UK4KkWBfUI_Y?&controls=1&autopause=0&autoplay=1&mute=1&loop=1&playlist=_Y4YLB0nDe4&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                        </div>
                                    </Grid>
                                </Grow>
                            </NarrowContainer>
                            <div className='observer' ref={inViewRef1}></div>
                            </div>
                    </WideContainer>
                    </Grid>
                    
                    <Grid container sx={{py:{md:5}}} className='center-align'>
                    <WideContainer>
                        <div className='center-align' style={{overflow:'hidden'}}>
                            <NarrowContainer>
                                <Slide timeout={{enter:800}} in={inView2} direction="right" container={containerRef3.current}>
                                        <Grid item xs={12} sm={12} md={5} className='center-align'>
                                            <ArticleCardA title={rows[1].title} content={rows[1].content} button={false} long={false}/>
                                        </Grid>
                                    </Slide>
                                    <Grow timeout={{enter:1200}} in={inView2} className='center-align'>
                                        <Grid item xs={12} sm={12} md={7}>
                                        <div className='video-container center-align'>
                                        {isMobile? <iframe width="288" height="216" src="https://www.youtube.com/embed/ASYBk2eXGnw?si=GkSw02OB6oGI1oxr?&controls=1&autopause=0&&autoplay=1&mute=1&loop=1&playlist=ASYBk2eXGnw&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        :<iframe width="576" height="432" src="https://www.youtube.com/embed/ASYBk2eXGnw?si=GkSw02OB6oGI1oxr?&controls=1&autopause=0&&autoplay=1&mute=1&loop=1&playlist=ASYBk2eXGnw&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                            </div>
                                        </Grid>
                                    </Grow>
                            </NarrowContainer>
                            <div className='observer' ref={inViewRef2}></div>
                            </div>
                    </WideContainer>
                    </Grid>

                    <Grid container sx={{py:{md:5}}} className='center-align'>
                    <WideContainer>
                        <div className='center-align' style={{overflow:'hidden'}}>
                            <NarrowContainer>
                                <Slide timeout={{enter:800}} in={inView3} direction="right" container={containerRef3.current}>
                                    <Grid item xs={12} sm={12} md={5} className='center-align'>
                                        <ArticleCardA title={rows[2].title} content={rows[2].content} button={false} long={false}/>
                                    </Grid>
                                </Slide>
                                <Grow timeout={{enter:1200}} in={inView3} className='center-align'>
                                    <Grid item xs={12} sm={12} md={7}>
                                    <div className='video-container center-align'>
                                        {isMobile? <iframe width="288" height="216" src="https://www.youtube.com/embed/mq5CelBPbgE?si=9fyu-Uo4caqhXcBU?&controls=1&autopause=0&&autoplay=1&mute=1&loop=1&playlist=mq5CelBPbgE&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        :<iframe width="576" height="432" src="https://www.youtube.com/embed/mq5CelBPbgE?si=9fyu-Uo4caqhXcBU?&controls=1&autopause=0&&autoplay=1&mute=1&loop=1&playlist=mq5CelBPbgE&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                        </div>
                                    </Grid>
                                </Grow>
                            </NarrowContainer>
                            <div className='observer' ref={inViewRef3}></div>
                            </div>
                    </WideContainer>
                    </Grid>
                </Grid>
            </div> 
        </WideContainer>
    );
}

export default ChromeExtensionVideo;