import React,{ useState} from 'react';
import { Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import {Link} from 'react-router-dom';

const ContainerGrid = styled(Grid)`
        display: flex;
        flex-direction: column;
        padding: 15px;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        background-color: white;
        border-radius: 15px;
        color:var(--pxft-darkgrey);
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.17));
        transition: 0.2s linear;

        &:hover {
            background-color: var(--ynt-primary);
        },

        & .icon {
            padding: 10px 0;
        }
        & .content {
            display: flex;
            flex-direction: column;
            width: 80%;
        }
        & .content h3{
            padding: 0 0 10px 0;
        }
`;

const LinkCard = ({card}) => {
    const [isHover,setIsHover] = useState(false);
    const mouseEnter = () =>{
        setIsHover(true);
    }
    const mouseOut = () =>{
        setIsHover(false);
    }
    return (
        <ContainerGrid item xs={8} lg={12} minHeight={'150px'} sx={{m:2}} onMouseEnter={() => mouseEnter()} onMouseLeave={() => mouseOut()}>
        <Link to={card.link} className='center-align' style={{flexDirection:'column'}}>
            <div className='content' style={{color:isHover? 'white':'var(--ynt-darkgrey)'}}>
                <Box py={1}>
                <p style={{fontSize:'24px',fontWeight:'600',color:isHover? 'white':'var(--ynt-primary)'}}>{card.title}</p>
                </Box>
                <Box py={1}>
                <p>{card.content}</p>
                </Box>
            </div>
        </Link>
        </ContainerGrid>
    );
}

export default LinkCard;