import Marketing from 'components/solution/marketing/Marketing';
import React,{ useEffect} from 'react';

const SolutionMarketing = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)}
    )

    return (
        <div>
            <Marketing/>
        </div>
    );
}

export default SolutionMarketing;