import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Finance = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Centralized knowledge',
            contents:'Imagine a Financial expert equipped with instant access to a centralized AI knowledge hub, capable of answering questions about all financial operations at the click of a button. No more forgetting payments or calculation mistakes.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'How can we optimize our portfolio for the upcoming fiscal quarter based on current market trends?',
                answerContent:'To optimize your portfolio for the upcoming fiscal quarter, consider diversifying your assets to mitigate risk, focusing on <…>'
            }
        },
        {
            title:'Automated Financial Data Analysis with "Speak to File" Feature',
            contents:'The "Speak to File" feature on Younet AI allows Finance specialists to effortlessly analyze financial documents and spreadsheets by simply conversing with the AI, which interprets and provides insights into the data in real time.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'Can you provide a summary of financial trends from the latest quarterly report?',
                answerContent:'Absolutely, after analyzing the quarterly report, I‘ve identified a consistent upward trend in revenue, with a notable increase in <…>'
            }
        },
        {
            title:'Custom AI Model Training for Financial Forecasting',
            contents:'Custom Training enables financial experts to train personalized AI models with their specific data for more accurate and tailored financial predictions and insights.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'Predict our cash flow for the next quarter based on historical data and current market conditions.',
                answerContent:'Based on the historical data and considering the current market trends, the AI model forecasts a 15% increase in cash flow for the next quarter, with a confidence interval of 10-20%. <…>'
            }
        },
        {
            title:'Integration with Financial Tools via Chrome Extension',
            contents:'Chrome Extension allows seamless integration of AI models with financial tools, enhancing data analysis and decision-making directly within your browser.',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'Help me analyze the risk of this investment portfolio.',
                answerContent:' I‘ve assessed the investment portfolio’s risk profile and found it to be moderately conservative with a strong emphasis on fixed-income securities, suggesting a low <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },  
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },      
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for Finance Teams',
        'Empower Your',
        'Finance Team with AI'
    ]
    const headerContent = 'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends. Younet AI equips your finance professionals with advanced tools for risk assessment, compliance management, and strategic financial planning.'
    const tagContentObj ={
        label: 'Analytics',
        avatar1: 'P',
        avatar2: '/images/btn.png',
        role:'Prompt to Financial Analytics',
        fileNames:[
          'YOY Sales Report 2021-2023.xlsx',
        ],
        sentence1: 'Analyze our sales by year and show me results.',
        sentence2: 'Certainly, let‘s take a look at the sales data by year:\n\nIn 2021, a total of 2,774,093 units were sold, generating a total revenue of $708,170,461.04.\nIn 2022, there was a slight increase with 2,831,548 units sold and a revenue of $722,837,573.44.\nThe year 2023 saw a small decrease in units sold to 2,664,883, but the revenue was still quite high at $680,291,332.24. \n< ... >',
      }

    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Finance;