import React,{useEffect} from 'react';
import 'assets/styles/product.css'
import Header from 'components/rabbitAI/Header';
import RabbitAIArticle from 'components/rabbitAI/RabbitAIArticle';

function RabbitAI(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <Header/>
            <RabbitAIArticle/>
        </div>
    );
}

export default RabbitAI;