import React from 'react';
import CenterTitle from 'components/mui/containers/centerTitle';
import { Grid } from '@mui/material';
import ContactGrids from 'components/contactUs/contactGrids';

const ContactUs= ()=> {
    return (
        <Grid container className='basic-header-container' sx={{minHeight:'calc(100vh - 74px)'}}>
            <Grid item xs={12} className='center-align' flexDirection='column'>
                <CenterTitle title='We are happy to help.'/>
                <p>Please select the option that best fits you.</p>
            </Grid>
            <Grid item xs={12} pt={6} sx={{height:{md:'55vh'}}}>
                <ContactGrids/>
            </Grid>
        </Grid>
    );
}

export default ContactUs;