import React, { memo } from 'react';
import { Grid, Grow, Slide } from '@mui/material';
import { useInView } from 'react-intersection-observer'
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ArticleCardA from 'components/mui/cards/articleCardA';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const UseCasesVideo = ({ info = { title: 'Younet info', content: '', youtubeMobileSrc: '', youtubeEmbededSrc: '', youtubeTitle: 'Youtube video', } }) => {

    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container sx={{ py: { md: 5 } }} className='center-align'>
            <WideContainer >
                <div className='center-align' style={{ overflow: 'hidden' }}>
                    <NarrowContainer className='center-align'>
                        <Slide timeout={{ enter: 800 }} in={inView} direction="right" >
                            <Grid item my={4} xs={12} sm={10} md={5} className='center-align'>
                                <ArticleCardA title={info.title} content={info.content} button={true} btnText='Sign up for free' long={true} link='https://younet.ai/portal/sign-up' />
                            </Grid>
                        </Slide>
                        <Grow timeout={{ enter: 1200 }} in={inView} className='center-align'>
                            <Grid item xs={12} sm={12} md={7}  ref={inViewRef}>
                                <div className='video-container center-align'>
                                    {isMobile ? <iframe width="332" height="249" src={info.youtubeMobileSrc} title={info.youtubeTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                        : <iframe width="576" height="432" src={info.youtubeEmbededSrc} title={info.youtubeTitle} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                </div>
                            </Grid>
                        </Grow>
                    </NarrowContainer>
                </div>
            </WideContainer>
        </Grid>
    );
}

export default memo(UseCasesVideo);