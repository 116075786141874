import React,{useEffect} from 'react';
import 'assets/styles/product.css'
import ChromeExtensionArticle from 'components/chromeExtenstion/ChromeExtensionArticle';

const ChromeExtension=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <ChromeExtensionArticle/>
        </div>
    );
}

export default ChromeExtension;