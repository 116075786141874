import React from 'react';
import styled from '@emotion/styled';
import { Grid, Rating, Typography } from '@mui/material';

const HFBtn = styled.button`
background: white;
border: 0px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
padding: 5px 8px;

& .text-content{
    padding: 0px;
    font-size: 14px;
}
`
function HighPerformanceBadgeButton(props) {
    return (
        <HFBtn>
            <img loading='lazy' src='/images/high-performer-2024-badge.png' height='80px' alt='g2-button'/>
        </HFBtn>
    );
}

export default HighPerformanceBadgeButton;