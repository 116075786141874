import React from 'react';
import { Grid, Grow, Slide, Link } from '@mui/material';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ContentRowA from './ContentRowA';
import ContentRowB from './ContentRowB';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import YoutubeVideo from './YoutubeVideo';

const AIBotArticle=()=> {
    const rows = [
        {
            title: 'Based on Your Information',
            content: 'The chatbot is uniquely designed to utilize your company’s specific information regarding services or products. It can provide responses that are not only relevant but also deeply aligned with your business’s offerings.',
            picSrc: '/images/products/aibot/figure-1.png',
            picSrc2x:'/images/products/aibot/figure-1@2x.png',
        },
        {
            title: 'Human-Like Interactions',
            content: 'Boost engagement and conversion rates by providing personalized responses that cater to individual user preferences and behaviors.',
            picSrc: '/images/products/aibot/figure-2.png',
            picSrc2x:'/images/products/aibot/figure-2@2x.png',
        },
        {
            title:'24/7 Availability',
            content:'Ensure your customers receive immediate help at any time of the day with the AI Chatbot, enhancing user satisfaction and retention.',
            picSrc:'/images/products/aibot/figure-3.png',
            picSrc2x:'/images/products/aibot/figure-3.png',
        },
        {
            title: 'Effortless Website Integration',
            content: 'Seamlessly integrate the chatbot into your website, maintaining the aesthetic and functionality of your site without any disruptions.',
            picSrc: '/images/products/aibot/figure-4.png',
            picSrc2x:'/images/products/aibot/figure-4@2x.png',
        },
        {
            title: 'Consistent Quality of Service',
            content: 'Each interaction with the AI Chatbot is governed by the quality and depth of training it has received, ensuring that every customer receives the same high level of service.',
            picSrc: '/images/products/aibot/figure-5.png',
            picSrc2x:'/images/products/aibot/figure-5@2x.png',
        },
    ]

    return (
        <div className='home-article-container'>
            <WideContainer>
                <Grid container sx={{ py: { md: 3 } }} flexDirection='column' className='center-align ai-bot-video-background-cover'>
                    <Grid pt={4} pb={2} px={2}>
                    <h1 style={{textAlign:'center'}}>Start your personalized Chatbot without coding</h1>
                    </Grid>
                    <Grid className='overview-header-pic center-align'>
                        <Grid width='90vw' maxWidth='840px'>
                        <YoutubeVideo videoHeight='472.5' videoWidth='840'videoId='uPdZKf4wvN0' poster='/images/products/aibot/aibot-poster.jpeg'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: { xs:2, md: 3 } }}>
                <SupportedCompanies/>
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[0].title} contents={rows[0].content} picSrc={rows[0].picSrc} picSrc2x={rows[0].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[1].title} contents={rows[1].content} picSrc={rows[1].picSrc} picSrc2x={rows[1].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[2].title} contents={rows[2].content} picSrc={rows[2].picSrc} picSrc2x={rows[2].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[3].title} contents={rows[3].content} picSrc={rows[3].picSrc} picSrc2x={rows[3].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[4].title} contents={rows[4].content} picSrc={rows[4].picSrc} picSrc2x={rows[4].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid px={2}>
                    <GetStartedCard/>
                </Grid>
            </WideContainer>
        </div>
    );
}

export default AIBotArticle;