import React from 'react';
import { NarrowContainer} from 'components/mui/containers/PxftContainer';
import {Grid,Grow,Button,Link} from '@mui/material';
import { useInView } from 'react-intersection-observer'

const GetStartedCard=()=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <div className='center-align'>
            <NarrowContainer className='center-align'>
                        <Grow timeout={{enter:1200}} in={inView} >
                            <Grid item xs={12} sm={12} md={8} 
                            sx={{flexDirection:'column',backgroundImage: `url('/images/home/business.png')`,backgroundPosition: "center",
                            color:'white',padding:'40px 30px',margin:'60px 0',borderRadius:'25px'}} 
                            className='center-align'>
                                <h1 style={{color:'white',textAlign:'center',margin:'20px 0',width:'80%'}}>Train your AI agent to know your business just like you</h1>
                                <Link href='https://younet.ai/portal/sign-up'>
                                <Button variant="contained" sx={{padding:'15px',margin:'15px 0'}}>Sign up for free</Button>
                                </Link>
                            </Grid>
                        </Grow>
            </NarrowContainer>
            <div className='observer' ref={inViewRef}></div>
        </div>
    );
}

export default GetStartedCard;