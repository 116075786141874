import React,{ useState,useEffect} from 'react';
import {Grid,Select,MenuItem} from '@mui/material';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import {Link, useLocation} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const TermsArticle =()=> {
    const[tag,setTag] = useState('terms-and-conditions');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation()

    const selectChange = (event) => {
        setTag(event.target.value);
    }
    useEffect(()=>{
        let hash = location.hash
        if(hash===''){
            setTag('terms-and-conditions')
        }
        let tabs = ['#terms-and-conditions','#privacy-policy']
        if (tabs.includes(hash)){
            let keyword = hash.split('#');
            setTag(keyword[1])
        }
    },[location])

    return(
        <Grid container className='terms-and-conditions-container'>
            <Grid item xs={12} px={{xs:2,lg:10}} className='center-align' flexDirection='column'>
                {isMobile? 
                <Select
                    labelId="tag-select-label"
                    id="tag-select"
                    value={tag}
                    label=""
                    onChange={selectChange}
                    fullWidth
                    >
                    <MenuItem value={'terms-and-conditions'}>Terms of Service</MenuItem>
                    <MenuItem value={'privacy-policy'}>Privacy Policy</MenuItem>
                </Select>
                 :
                <Grid container display='flex' flexDirection='row' sx={{borderBottom: '1px solid var(--ynt-mildgrey)'}}>
                    <Grid item xs={6} className='center-align'>
                        <Link to="#terms-and-conditions" className={`tablinks ${tag === 'terms-and-conditions'?'tab-active':''}`}>Terms of Service</Link>
                    </Grid>
                    <Grid item xs={6} className='center-align'>
                        <Link to="#privacy-policy" className={`tablinks ${tag === 'privacy-policy'?'tab-active':''}`}>Privacy Policy</Link>
                    </Grid>
                </Grid>
                }
                <Grid px={2} pb={4}>
                    {
                        tag === 'terms-and-conditions' && <TermsAndConditions/>
                    }
                    {
                        tag === 'privacy-policy' && <PrivacyPolicy/>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TermsArticle;