import React, { useState, useEffect, useMemo } from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import parse from 'html-react-parser';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const BlogDetailsArticle = ({ data }) => {

    console.log(data)


    const html = useMemo(() => { return data.blog?.html }, [data.blog?.html]);


    const mappings = data.mappings;
    const nextUrl = mappings?.nextUrl;
    const prevUrl = mappings?.prevUrl;

    return (
        <WideContainer>
            <div className='blog-content center-align'>
                <NarrowContainer>
                    <div className='blog-preview ck-content'>
                    {parse('' + html)}
                    </div>
                    <div className='btn-container'>
                        {prevUrl && <Button size="small" href={`/blogs/details/${prevUrl}`}><KeyboardArrowLeft />Prev</Button>}
                        {nextUrl && <Button size="small" href={`/blogs/details/${nextUrl}`}>Next<KeyboardArrowRight /></Button>}
                    </div>
                </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default BlogDetailsArticle;