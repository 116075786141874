import React,{useEffect} from 'react';
import { Grid,Paper,Grow } from '@mui/material';
import AboutUsArticle from 'components/about/AboutArticle';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import 'assets/styles/company.css'
import { useInView } from 'react-intersection-observer'

function About(props) {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <Grid sx={{backgroundImage: `url('/images/about/header-pic.png')`,backgroundRepeat: 'no-repeat', backgroundPosition: 'right',backgroundSize: 'auto 100%'}}>
            <Grow timeout={{enter:1200}} in={inView} >
            <Grid container className='company-aboutus-header center-align'>   

                <Grid item xs={12}>
                <h1>About</h1>
                </Grid>
                <Grid item xs={12} className='center-align'>
                <Paper elevation={0} className='header-content'>
                    <div className='quotation'>
                    <img loading='lazy' src='/images/quotation.png' alt=''/>
                    </div>
                    <p>At Younet, we are dedicated to the advancement of machine intelligence for the benefit of all humanity. </p>
                    <p>Our mission is to create a future where Artificial Intelligence technology serves as a powerful tool that positively impacts every aspect of human life.</p>
                </Paper>
                <div className='observer' ref={inViewRef}></div>
                </Grid>
            </Grid>
            </Grow>
            <AboutUsArticle/>
        </Grid>
    );
}

export default About;