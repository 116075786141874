import React from 'react';
import {Grid} from '@mui/material';

const PrivacyPolicy=()=> {
    return (
        <Grid item xs={12}>
        <div className="updated-time">Last Revised: Jun 1, 2023</div>
        <h3 className="policy-title">Privacy Policy</h3>
        <h3>Introduction</h3>
            <p>Welcome to Younet, 2624465 Ontario Inc. and the https://younet.ai website!</p>
            <p>This Privacy Policy governs your visit to https://younet.ai/ and is designed to inform you of how we collect and use information resulting from your use of our Service.</p>
            <p>By accessing and using our Service, you accept the terms of this Privacy Policy and our Terms of Service, which together constitute an agreement (&ldquo;agreement&rdquo;) between us. In case of any discrepancy between this Privacy Policy and our Terms of Service, our Terms of Service shall take precedence.</p>
        <h3>Definitions</h3>
            <p>Younet is a data controller responsible for your Personal Data. We collect a range of data from users of the Service, including Personal Data and Usage Data. We use this data to facilitate the Service and provide you with the best possible experience. All data collected through the Service is used to: &bull; Administer the Service; &bull; Offer you personalized features; &bull; Improve the Service; &bull; Contact you; &bull; Monitor the usage of the Service; We also use Cookies to track your activity on the Service. This helps us learn how to improve the Service for future users. We use data processors to insure the security and integrity of your data. We do not sell, rent, or share your data with third parties, except in cases where such third parties may be employed to provide the Service or to improve your overall experience. You have the right to request access to your data stored by us at any time. You may also have the right to restrict or delete your data. If you wish to exercise this right, please contact us at support@younet.ai. We take the security of your data seriously and take steps to protect it from unauthorised access. We use industry-standard measures,</p>
        <h3>Data Collection and Utilization</h3>
            <p>We gather diverse forms of information for multiple objectives, aiming to deliver and enhance our Service for your benefit.</p>
        <h3>Types of Data Collected</h3>
            <p>Personal Data When you use our Service, we may request certain personally identifiable information from you that can be used to contact or identify you ("Personal Data"). This may include, but is not limited to:</p>
            <p>Email address</p>
            <p>First name and last name</p>
            <p>Cookies and Usage Data</p>
            <p>We may utilize your Personal Data to communicate with you through newsletters, marketing or promotional materials, and other relevant information that may be of interest to you. If you wish to opt out of receiving any or all of these communications from us, you can do so by following the unsubscribe link or by contacting us at <a href="mailto:support@younet.ai">   support@younet.ai   </a>.</p>
            <p>Usage Data We may also gather information that your browser sends whenever you visit our Service or access it through a mobile device ("Usage Data").</p>
            <p>This Usage Data may consist of details such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the duration of time spent on those pages, unique device identifiers, and other diagnostic data.</p>
            <p>When you access our Service using a mobile device, the Usage Data collected may include information such as the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser you use, unique device identifiers, and other diagnostic data.</p>
            <p>Cookies and Tracking Technologies We employ cookies and similar tracking technologies to monitor the activity on our Service and store certain information.</p>
            <p>Cookies are small files containing a limited amount of data, including an anonymous unique identifier. They are sent to your browser from a website and stored on your device. Additionally, we utilize other tracking technologies such as beacons, tags, and scripts to gather and track information, as well as to enhance and analyze our Service.</p>
            <p>You have the option to instruct your browser to reject all cookies or to notify you when a cookie is being sent. However, please note that if you choose not to accept cookies, certain portions of our Service may not be accessible to you.</p>
            <p>Examples of Cookies We Use:</p>
            <p>Session Cookies: We use Session Cookies to operate our Service.</p>
            <p>Preference Cookies: Preference Cookies are employed to remember your preferences and various settings.</p>
            <p>Security Cookies: Security Cookies are used for security purposes.</p>
            <p>Advertising Cookies: Advertising Cookies are utilized to provide you with advertisements that may be relevant to you and your interests.</p>
            <p>Google (Gmail) Data</p>
            <p>At Younet, we understand the importance of privacy and the sensitive nature of personal data. When it comes to using Gmail data, we prioritize the security and confidentiality of your information.</p>
            <p>We do not store email conversations from Gmail unless you explicitly choose to train your AI model with a specific message(s). In such cases, we store the conversation in a special format (called brain cell) that allows our AI model to be trained with it.</p>
            <p>It's important to note that you have full control over which Gmail conversations you use to train your AI model. You can selectively choose the messages you want to include in the training process. We respect your privacy and ensure that your data is handled securely and in accordance with our Privacy Policy.</p>
            <p>We adhere to strict privacy policies and comply with all applicable laws and regulations to ensure the security and confidentiality of your data. If you have any concerns or questions about the usage of your Gmail data, please feel free to contact us at <a href="mailto:support@younet.ai">   support@younet.ai</a>.</p>
        <h3>Data Usage</h3>
            <p>Younet, 2624465 Ontario Inc. utilizes the collected data for various lawful purposes, which include:</p>
            <p>Personal Data</p>
            <p>Providing and maintaining our Service.</p>
            <p>Notifying you about changes to our Service.</p>
            <p>Enabling your participation in interactive features of our Service when you choose to do so.</p>
            <p>Offering customer support.</p>
            <p>Conducting analysis and gathering valuable information to improve our Service.</p>
            <p>Monitoring the usage of our Service.</p>
            <p>Detecting, preventing, and addressing technical issues.</p>
            <p>Fulfilling any other purpose for which you provide the data.</p>
            <p>Carrying out our obligations and enforcing our rights arising from any contracts entered into between you and us, including billing and collection purposes.</p>
            <p>Sending you notices about your account and/or subscription, such as expiration and renewal notices, email instructions, and similar communications.</p>
            <p>Providing you with news, special offers, and general information about other goods, services, and events similar to those you have already purchased or inquired about, unless you have opted out of receiving such information.</p>
            <p>Utilizing the data in any other way described at the time you provide the information.</p>
            <p>Using the data for any other purpose with your explicit consent.</p>
        <h3>Data Retention</h3>
            <p>Your Personal Data will be kept for the duration required to fulfill the purposes outlined in this Privacy Policy. We will keep and utilize your Personal Data only to the extent necessary to adhere to our legal obligations, settle disputes, and enforce our legal agreements and policies. Additionally, we will retain Usage Data for internal analysis purposes. Generally, Usage Data is stored for a shorter period, unless it is utilized to enhance the security or functionality of our Service, or when legal obligations mandate its retention for longer durations.</p>
        <h3>Data Transfer</h3>
            <p>The transfer of your information, including Personal Data, may occur to computers situated outside of your state, province, country, or any other governmental jurisdiction where data protection laws may vary from those in your jurisdiction.</p>
            <p>If you are located outside of the United States and choose to provide us with information, please be aware that we will transfer the data, including Personal Data, to the United States and process it there.</p>
            <p>By giving your consent to this Privacy Policy and submitting such information, you agree to the transfer of your data.</p>
            <p>Younet, 2624465 Ontario Inc. will take all reasonable steps to ensure that your data is treated securely and in accordance with this Privacy Policy. No transfer of your Personal Data will occur to an organization or country unless adequate controls are in place, including the security of your data and other personal information.</p>
        <h3>Data Disclosure</h3>
            <p>We may disclose the personal information we collect or that you provide:</p>
            <p>Disclosure for Law Enforcement:</p>
            <p>Under certain circumstances, we may be obligated to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>
            <p>Business Transaction:</p>
            <p>If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.</p>
            <p>Other Cases:</p>
            <p>We may disclose your information for the following purposes:</p>
            <p>To fulfill the purpose for which you provided it.</p>
            <p>To include your company's logo on our website with your consent.</p>
            <p>For any other purpose with your consent.</p>
        <h3>Data Security</h3>
            <p>The security of your data is important to us. However, please remember that no method of transmission over the Internet or electronic storage is 100% secure. While we make commercially acceptable efforts to protect your Personal Data, we cannot guarantee its absolute security.</p>
        <h3>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h3>
            <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. &ndash; See more at&nbsp;<a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a></p>
            <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
            <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at&nbsp;<a href="mailto:support@younet.ai">   support@younet.ai   </a>. &nbsp;</p>
            <p>In certain circumstances, you have the following data protection rights:</p>
            <p>the right to access, update or to delete the information we have on you;</p>
            <p>the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</p>
            <p>the right to object. You have the right to object to our processing of your Personal Data;</p>
            <p>the right of restriction. You have the right to request that we restrict the processing of your personal information;</p>
            <p>the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</p>
            <p>the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</p>
            <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</p>
            <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
        <h3>Your Rights Regarding Data Protection under the General Data Protection Regulation (GDPR)</h3>
            <p>If you are a resident of the European Union (EU) or the European Economic Area (EEA), you are entitled to certain data protection rights as outlined by the GDPR. For more detailed information, please refer to the official GDPR documentation available at <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj">   https://eur-lex.europa.eu/eli/reg/2016/679/oj   </a>.</p>
            <p>We are committed to taking appropriate measures to enable you to exercise these rights, including the right to correct, amend, delete, or restrict the use of your personal data.</p>
            <p>If you would like to know what personal data we have stored about you and request its removal from our systems, please contact us via email at <a href="mailto:support@younet.ai">   support@younet.ai   </a>.</p>
            <p>In specific circumstances, you have the following data protection rights:</p>
            <p>The Right to Access, Update, or Delete Information: You have the right to access, update, or request the deletion of the personal information we hold about you.</p>
            <p>The Right of Rectification: If the information we have about you is inaccurate or incomplete, you have the right to request its correction.</p>
            <p>The Right to Object: You have the right to object to the processing of your personal data by us.</p>
            <p>The Right of Restriction: You can request that we limit the processing of your personal information.</p>
            <p>The Right to Data Portability: You have the right to receive a copy of your personal data in a structured, machine-readable format commonly used and transfer it to another data controller.</p>
            <p>The Right to Withdraw Consent: If we rely on your consent for processing your personal information, you have the right to withdraw your consent at any time.</p>
            <p>Please note that we may ask you to verify your identity before addressing your requests. Additionally, please be aware that certain necessary data may be required to provide our services, and we may not be able to offer those services without it.</p>
            <p>If you believe that we have not adequately addressed your data protection rights, you have the right to lodge a complaint with a Data Protection Authority. To obtain more information, we recommend contacting your local data protection authority within the European Economic Area (EEA).</p>
        <h3>Your Data Protection Rights under the California Online Privacy Protection Act (CalOPPA)</h3>
            <p>CalOPPA is a state law that mandates commercial websites and online services to display a privacy policy. This law applies not only to entities based in California but also to those within the United States (and potentially worldwide) that operate websites collecting personally identifiable information from California consumers. It requires such entities to present a conspicuous privacy policy on their websites, detailing the collected information and the parties with whom it is shared. Compliance with this policy is also mandated. For more comprehensive details on CalOPPA, please visit <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/">   https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/   </a>.</p>
            <p>In compliance with CalOPPA, we adhere to the following:</p>
            <p>Anonymous Site Visits: Users can visit our site anonymously.</p>
            <p>Clear Privacy Policy Link: Our privacy policy link, clearly marked as "Privacy," is easily accessible on the specified webpage, typically located on our website's homepage.</p>
            <p>Notification of Privacy Policy Changes: Users will be informed about any changes to our privacy policy on our Privacy Policy page.</p>
            <p>Personal Information Modification: Users have the ability to modify their personal information by contacting us via email at <a href="mailto:support@younet.ai">   support@younet.ai   </a>.</p>
        <h3>Our Policy on "Do Not Track" Signals:</h3>
            <p>In accordance with CalOPPA, we respect "Do Not Track" signals and refrain from tracking, placing cookies, or engaging in advertising when a user's browser has enabled the "Do Not Track" feature. "Do Not Track" is a preference that users can configure within their web browser to communicate their desire not to be tracked.</p>
            <p>To enable or disable the "Do Not Track" feature, please access the Preferences or Settings page of your web browser.</p>
        <h3>Service Providers:</h3>
            <p>We may engage third-party companies and individuals (referred to as "Service Providers") to assist with our services, provide services on our behalf, perform service-related tasks, or help us analyze how our services are used. These Service Providers may have access to your personal data solely for the purpose of performing these tasks and are obligated not to disclose or use it for any other reason.</p>
        <h3>Analytics:</h3>
            <p>We may utilize third-party Service Providers to monitor and analyze the usage of our services.</p>
        <h3>Google Analytics:</h3>
            <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. The data collected by Google Analytics is used to monitor and analyze the usage of our services. This data is also shared with other Google services. Google may utilize the collected data to personalize and contextualize advertisements through its advertising network. For more information regarding Google's privacy practices, please consult their Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">   https://policies.google.com/privacy?hl=en   </a>. Additionally, we recommend reviewing Google's data safeguarding policies at: <a href="https://support.google.com/analytics/answer/6004245">   https://support.google.com/analytics/answer/6004245   </a>.</p>
        <h3>Firebase:</h3>
            <p>Firebase is an analytics service provided by Google Inc. Certain Firebase features can be opted out of by adjusting the settings on your mobile device, such as device advertising settings, or by following the instructions provided in Google's Privacy Policy: <a href="https://policies.google.com/privacy?hl=en">   https://policies.google.com/privacy?hl=en   </a>. For additional information on the data collected by Firebase and its purposes, please refer to the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">   https://policies.google.com/privacy?hl=en   </a>.</p>
        <h3>CI/CD Tools:</h3>
            <p>We may employ third-party Service Providers to automate the development process of our services.</p>
        <h3>GitHub:</h3>
            <p>GitHub is a development platform offered by GitHub, Inc. It facilitates code hosting, project management, and software development. For comprehensive details on the data collected by GitHub, its purposes, and the measures taken to protect data, please consult the GitHub Privacy Policy page: <a href="https://help.github.com/en/articles/github-privacy-statement">   https://help.github.com/en/articles/github-privacy-statement   </a>.</p>
        <h3>Behavioral Remarketing:</h3>
            <p>Younet, 2624465 Ontario Inc. employs remarketing services to display ads on third-party websites to users who have previously visited our services. Cookies are used by us and our third-party vendors to inform, optimize, and serve ads based on a user's past visits to our services.</p>
        <h3>Google Ads (AdWords):</h3>
            <p>Google Ads (AdWords) is a remarketing service provided by Google Inc. To customize Google Display Network ads and opt out of Google Analytics for Display Advertising, please visit the Google Ads Settings page: <a href="http://www.google.com/settings/ads">   http://www.google.com/settings/ads   </a>. Google also recommends installing the Google Analytics Opt-out Browser Add-on available at <a href="https://tools.google.com/dlpage/gaoptout">   https://tools.google.com/dlpage/gaoptout   </a>&nbsp;to prevent data collection and usage by Google Analytics. For detailed information on Google's privacy practices, please refer to their Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en">   https://policies.google.com/privacy?hl=en   </a>.</p>
        <h3>Facebook:</h3>
            <p>Facebook remarketing service is provided by Facebook Inc. Further information about interest-based advertising can be obtained from Facebook's page at <a href="https://www.facebook.com/help/164968693837950">   https://www.facebook.com/help/164968693837950   </a>. To opt out of Facebook's interest-based ads, follow the instructions provided on this page: <a href="https://www.facebook.com/help/568137493302217">   https://www.facebook.com/help/568137493302217   </a>. Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising set by the Digital Advertising Alliance. Users can also opt out of Facebook and other participating companies through the Digital Advertising Alliance in the USA (<a href="http://www.aboutads.info/choices/">   http://www.aboutads.info/choices/   </a>), the Digital Advertising Alliance of Canada in Canada (<a href="http://youradchoices.ca/">   http://youradchoices.ca/   </a>), or the European Interactive Digital Advertising Alliance in Europe (<a href="http://www.youronlinechoices.eu/">   http://www.youronlinechoices.eu/   </a>), or by adjusting mobile device settings. For comprehensive details on Facebook's privacy practices, please consult their Data Policy: <a href="https://www.facebook.com/privacy/explanation">   https://www.facebook.com/privacy/explanation   </a>.</p>
        <h3>Payments</h3>
            <p>We offer paid products and/or services within our Service. For payment processing, we utilize the services of third-party providers (e.g., payment processors).</p>
            <p>We do not store or collect your payment card details. Instead, this information is directly provided to our third-party payment processors. These processors handle your personal information in accordance with their own Privacy Policy. They also comply with the standards set by PCI-DSS (Payment Card Industry Data Security Standard), as managed by the PCI Security Standards Council. PCI-DSS requirements ensure the secure handling of payment information and are supported by major brands such as Visa, Mastercard, American Express, and Discover.</p>
            <p>The payment processors we work with are:</p>
            <p>PayPal: You can review their Privacy Policy at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full">   https://www.paypal.com/webapps/mpp/ua/privacy-full   </a>.</p>
            <p>Stripe: You can review their Privacy Policy at <a href="https://stripe.com/us/privacy">   https://stripe.com/us/privacy   </a>.</p>
        <h3>Links to Other Sites</h3>
            <p>Our Service may contain links to external websites that are not operated by us. If you click on a third-party link, you will be directed to that specific site. We strongly recommend reviewing the Privacy Policy of any website you visit.</p>
            <p>We have no control over, nor do we assume any responsibility for, the content, privacy policies, or practices of any third-party sites or services.</p>
        <h3>Children's Privacy</h3>
            <p>Our Services are not intended for use by individuals under the age of 18 ("Children").</p>
            <p>We do not knowingly collect personally identifiable information from children under 18. If you become aware that a child has provided us with personal data, please contact us immediately. If we discover that we have collected personal data from children without obtaining verifiable parental consent, we take steps to remove that information from our servers.</p>
        <h3>Changes to This Privacy Policy</h3>
            <p>We may periodically update our Privacy Policy. Any changes will be notified to you by posting the revised Privacy Policy on this page.</p>
            <p>We will also notify you via email and/or through a prominent notice on our Service prior to the changes taking effect. The "effective date" at the top of this Privacy Policy will be updated accordingly.</p>
            <p>We recommend reviewing this Privacy Policy periodically for any updates. Changes to this Privacy Policy become effective when they are posted on this page.</p>
        <br/>
        <h3>Use of Sharing For a Fee Tool</h3>
        {/* <hr/> */}
        <p>This section of the Privacy Policy pertains to the "Sharing For a Fee" tool provided by Younet. This tool allows creators ("Creators") of AI models to share their models with others for a subscription fee.</p>
        <h3>Personal Information Handling</h3>
        <p>· Collection of Information: Information collected through the "Sharing For a Fee" tool includes subscriber's name, email and payment details.</p>
        <p>· Use of Information: This information is used to process payments and manage subscriptions.</p>
        <p>· Sharing of Information: Payment-related information is shared with payment gateways such as Stripe for transaction processing.</p>
        <h3>Data Protection</h3>
        <p>· Security Measures: Younet. implements security measures to protect the data collected through the "Sharing For a Fee" tool.</p>
        <p>· Access to Information: Copyright holders may contact support (<a href='https://support.younet.ai/submit-ticket/1-Support' target='a_blank'>https://support.younet.ai/submit-ticket/1-Support</a>) in the event of unauthorised use of their information by Creators.</p>
        <h3>Amendments to Privacy Policy</h3>
        <p>Younet reserves the right to modify this Privacy Policy at any time. Changes will be effective immediately upon posting on the Younet platform. Creators are encouraged to review this policy periodically.</p>
        <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <p>Email: <a href="mailto:support@younet.ai">   support@younet.ai   </a></p>
        </Grid>
    );
}

export default PrivacyPolicy;