import Header from 'components/useCases/Header';
import React, { useEffect } from 'react';
import './useCasesStyle.css';
import UseCasesArticle from 'components/useCases/UseCasesArticle';

const UseCases = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    })
    return (
        <>
            <Header />
            <UseCasesArticle />
        </>
    );
}

export default UseCases;