
import { Grid } from '@mui/material';
import QuoteCard from 'components/mui/cards/quoteCard';
import Carousel from 'react-material-ui-carousel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const ScrollableQuotes = ({showLink}) => {
    const quotesItems = [
        {
            "id": 1,
            "signatureName": "Dr. Johan Emius, Wellgo CEO",
            "logoPicSrc": "/images/home/employer-logo-1.png",
            "picSrc": "/images/home/avatar-1.png",
            "content": "Human-to-human interaction remains the primary framework, but at this stage, Younet AI is a very helpful tool, and the future looks quite exciting!",
            "link": "https://younet.ai/blogs/details/how-health-coaching-is-being-revolutionized-with-ai-for-wellgo"
        },
        {
            "id": 2,
            "signatureName": "Matt Rasner, Business Development Manager",
            "logoPicSrc": "/images/home/employee-logo-1.png",
            "picSrc": "/images/home/employee-1.png",
            "content": "Younet helped us automate our client communications. We trained the AI model like a new team member, and it started answering questions and writing emails for us. This cool automation boosted our efficiency by a whopping ten times!",
            "link": "https://younet.ai/blogs/details/how-ad-tech-company-boosting-productivity-and-streamlining-processes"
        },
        {
            "id": 3,
            "logoPicSrc": "/images/home/inktuitive-logo.png",
            "content": "Younet is a revelation! Our team at Inktuitive no longer spends exhausting hours capturing our brand's voice for creating product descriptions and ads. Younet AI understood us instantly after we uploaded our past work. Tasks that once took hours now take minutes. It's like our super-efficient, always-ready employee. A true game changer for efficiency while maintaining quality!",
            "link": "https://younet.ai/blogs/details/revolutionizing-content-creation-for-inktuitive"
        },
        {
            "id": 4,
            "signatureName": "Eity K., Information Technology and Services Co-Founder",
            "picSrc": "/images/home/avatar-2.png",
            "content": "The Chrome extension is really good option. It allows me to use AI across various websites and CRM that I use to track my work. Also a great feature is responding to client emails or drafting texts with AI. It really saves my time. Especially with emails.",
            "link": ""
        },
    ]

    return (
        <Grid container
            sx={{
                width: { xs: "100%", md: "80%" },
                margin: "auto"
            }}
            className="quotes-slider"
        >

            <Grid item xs={12} sm={12} md={12} className='center-align'>
                <Carousel
                    duration={700}
                    interval={7000}
                    NextIcon={<ChevronRightIcon />}
                    PrevIcon={<ChevronLeftIcon />}
                    animation="slide"
                    sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: { xs: '600px', sm: '500px', md: '400px' } }}>
                    {quotesItems.map((item) => (
                        <Grid key={item.id} item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", padding: { xs: '0 20px', sm: '0 40px', md: '0 80px' }, minHeight:{xs:'420px',sm:'320px',md:'280px'}}}>
                            <QuoteCard signatureName={item.signatureName} logoPicSrc={item.logoPicSrc} picSrc={item.picSrc} content={item.content} link={item.link} showLink={showLink}/>
                        </Grid>
                    )
                    )}

                </Carousel>
            </Grid>
        </Grid>
    )

}



export default ScrollableQuotes;