const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.younet.ai/api';
const dashboardUrl = 'https://admin.younet.ai/api'

export const API_PATHS = {
  loginCheck: () => `${baseUrl}/login_check`,
  googleAuth: (url) => `${baseUrl}/${url}`,
  facebookAuth: () => `${baseUrl}/facebook/oauth2/url`,
  getBlogs: () => `${dashboardUrl}/content/blogs/all`,
  getBlogByUrl: () => `${dashboardUrl}/content/blogs/getBlog`
};
