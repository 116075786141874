import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Legal = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Draft documents based on the uploaded templates',
            contents:'Don‘t waste time manually typing, reviewing, or editing legal documents. Upload your templates and create new ones based on your proprietary agreements 10 times faster than you normally would.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'Create an mNDA for a strategic partnership with a local business based on our standard template.',
                answerContent:'I can certainly assist in drafting a mutual non-disclosure agreement. \nBelow is a template for it: <…>'
            }
        },
        {
            title:'Analyze massive legal data to generate insights and strategies',
            contents:'Reply or write anywhere with a browser extension and write content information in under a minute. Responding to clients has never been that fast.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'What steps can we take to counter local competitive laws regarding providing our services in a new country as an international vendor?',
                answerContent:'To address the challenges of local competitive laws and leverage the benefits of tax rebates and <…>'
            }
        },
        {
            title:'Get a highly detailed analysis of documents using the “Speak to file” feature',
            contents:'Effortlessly obtain a comprehensive analysis of a document with AI, ensuring accurate and detailed insights and predictions.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'/MasterAgreement.doc point out areas that have not been given enough consideration in the agreement.',
                answerContent:'Based on the information provided, there are a few areas that may require additional consideration: <…>'
            }
        },
        {
            title:'Seamless employee onboarding',
            contents:'No more lengthy training. New employees can hit the ground running from day one, as they have immediate access to an AI model well-versed in the company‘s legal documents and templates. ',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'Give me a summary of the agreement prepared for John Dawn',
                answerContent:'Sure! Here‘s a brief overview of the agreement prepared for John <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for Legal Teams',
        'Empower Your',
        'Legal Skills with AI'
    ]
    const headerContent = 'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests with intelligent, automated reviews and suggestions.'

    const tagContentObj =           
      {
        label: 'Legal',
        avatar1: 'P',
        avatar2: '/images/btn.png',
        role:'Prompt to Legal Consultant',
        fileNames:[
          'MasterServiceAgreement.docx',
        ],
        sentence1: 'Summarize the key points of the agreement.',
        sentence2: 'The Master Service Agreement (Exhibit 10.2) is a contract effective as of May 14, 2023. The agreement outlines the terms under which the Provider will outsource certain services for the Customer, who is in the business of healthcare services.\nKey points include:\n\tDefinitions of terms used within the agreement.\n\tThe Provider‘s commitment to deliver customer contact center and < ... >',
      }
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Legal;