import { WideContainer } from 'components/mui/containers/PxftContainer';
import React from 'react';
import {Grid,Box,Link} from '@mui/material';
import CenterTitle from 'components/mui/containers/centerTitle';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import BasicButton from 'components/mui/buttons/basicButton';

const CareerArticle=()=> {
    const rows=[
        {
            title:'Join the Younet team',
            content:'Joining Younet means being part of a dynamic and collaborative environment where you can contribute to cutting-edge AI solutions. Whether you are a software engineer, data scientist, or customer support specialist, we offer exciting opportunities to grow and develop your skills.',
            src:'/images/career/career-feature-1.png'
        },
        {
            title:'Our commitment to diversity',
            content:'We value diversity and inclusivity, and we foster a culture of continuous learning and professional growth. As a member of our team, you will have the chance to work on challenging projects, collaborate with industry experts, and make a real impact in the world of AI.',
            src:'/images/career/career-feature-2.png'
        },
        {
            title:'AI careers await you',
            content:'If you are driven, ambitious, and ready to be at the forefront of AI innovation, we invite you to explore our current job openings and submit your application. We are always on the lookout for talented individuals who can help us shape the future of AI.',
            src:'/images/career/career-feature-3.png'
        },
    ]
    const containerStyle = {
        backgroundImage: `url('/images/brandbook/header-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }

    return (
        <div className='career-article-container'>
            <WideContainer>
                <div className='career-feature-column column-container center-align'>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowA title={rows[0].title} contents = {rows[0].content} picSrc={rows[0].src} button={false} long={false}/>
                    </Grid>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowB title={rows[1].title} contents = {rows[1].content} picSrc={rows[1].src} button={false} long={false}/>
                    </Grid>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowA title={rows[2].title} contents = {rows[2].content} picSrc={rows[2].src} button={false} long={false}/>
                    </Grid>
                </div>
                <div className='center-align' style={containerStyle}>
                    <Box className='career-join-column center-align' width='80%'>
                    <CenterTitle title='Join us on this exciting journey' article='unlock your full potential with Younet'/>
                    <h4>Together, we can revolutionize the way people leverage AI to achieve their goals.</h4>
                    <h4>Apply now and be part of our team!</h4>
                    <p>Please submit your resume and cover letter through our careers portal</p>
                    <Link href='https://support.younet.ai/submit-ticket/5-Careers' target='_blank' rel='noreferrer'>
                        <BasicButton width='200px' content='Visit Careers Portal'/>
                    </Link>
                    </Box>
                </div>
            </WideContainer>
        </div>
    );
}

export default CareerArticle;