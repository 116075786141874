import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Engineering = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Personalized AI Model Creation',
            contents:'Software engineers can create AI models that understand their coding style, project requirements, and technical documentation.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'Can you generate a function to connect to a REST API using Python?',
                answerContent:'Absolutely! Here‘s a Python function that uses the requests library to connect to a REST API: \n\nimport requests def connect_to_api(endpoint): response = requests.get(endpoint)<…>'
            }
        },
        {
            title:'Code Review and Optimization',
            contents:'The AI can assist in reviewing code for potential errors and suggest optimizations for better performance.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'Can you review this SQL query for any inefficiencies?',
                answerContent:'After reviewing the SQL query, I suggest adding an index to the columns used in the WHERE clause to improve the query‘s performance. Also, <…>'
            }
        },
        {
            title:'Automated Documentation',
            contents:'Younet AI can help generate and update technical documentation based on the codebase and commit history.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'Can you update the documentation to reflect the new authentication module we added?',
                answerContent:'Certainly! The documentation has been updated to include the new authentication module. It outlines the <…>'
            }
        },
        {
            title:'Integration and Deployment Assistance',
            contents:'AI model can guide software engineers through the process of integrating new tools into their workflow and assist with deployment strategies.',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'What steps should I follow to integrate our new monitoring tool into the existing CI/CD pipeline?',
                answerContent:'To integrate the new monitoring tool, you‘ll need to update the CI/CD pipeline configuration file to include the monitoring tool’s script after the deployment <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
    ]
    const headerTitles = [
        'Solution for Engineering Teams',
        'Empower Your',
        'Engineering Team with AI'
    ]
    const headerContent = 'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing. Younet AI enables your engineering team to rapidly prototype, optimize code quality, and accelerate product releases with cutting-edge AI assistance.'
    const tagContentObj ={
        label: 'Engineering',
        avatar1: 'P',
        avatar2: '/images/btn.png',
        role:'Prompt to Customized Coding',
        fileNames:[
          'Master Code.txt',
          'API documentation.txt'
        ],
        sentence1: 'Write a form that authorize user with our authorization API.',
        sentence2: 'Certainly! Below is an example of a simple HTML form that could be used to authorize a user with your API:\n\n\t<!DOCTYPE html>\n\t<html lang="en">\n\t<head><meta charset="UTF-8">\n\t<meta name="viewport" content="width=device-width, initial-scale=1.0">\n\t<title>Login Form</title>\n\t< ... >',
      }
    
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Engineering;