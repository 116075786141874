import React from 'react';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Grid, Link } from '@mui/material';;

const ArticlePaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    padding: 30px;
    margin: 40px 0;
    background-color:var(--ynt-lightgrey);

    & .title {
        color: black;
        white-space: pre-line;
    }
    & .quotation img{
        width:80px;
        position:absolute;
        top:-40px;
        left:40px;
    }
    & p {
        color: black;;
        white-space: pre-line;
        padding:0 10px;
        font-weight:400;
        font-size:14px;
        line-height: 1.5;
        user-select: none;
    }
    & .signature{
        font-size:14px;
        font-weight:500;
    }
`
const QuoteCard = ({ content = '', picSrc = '', logoPicSrc = '', signatureName = '',link = '', showLink }) => {

    return (
                <ArticlePaper elevation={10}>
                    <div className='quotation'>
                        <img loading='lazy' src='/images/quotation.png' alt='' />
                    </div>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{ flexDirection: 'column', py: 3 }}>
                            <p>
                                {content}
                                {
                                    showLink && link.length>0 && <Link href={link} pl={1}>Read more...</Link>
                                }
                            </p>
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} sx={{ padding: "0 10px" }} display={"flex"} justifyContent={"space-between"}>
                            <Grid item xs={12} sm={8} md={8} display={"flex"} alignItems={"center"}>
                                {/* <Box display={"flex"} alignItems={"center"}> */}
                                {picSrc && <img style={{ borderRadius: '50px' }} loading='lazy' src={picSrc} alt='' width='50px' />}
                                {signatureName && <p className='signature' style={{ paddingLeft: "10px" }}>{signatureName}</p>}
                                {/* </Box> */}
                            </Grid>
                            {logoPicSrc && <Grid item xs={12} sm={4} md={4} display={"flex"} alignItems={'center'} justifyContent={"flex-end"} sx={{ paddingTop: { xs: "40px", sm: 0, md: 0, lg: 0 } }}>
                                <img style={{maxHeight:'45px' }} loading='lazy' src={logoPicSrc} alt="Quote signature" />
                            </Grid>}
    
                        </Grid>
                    </Grid>
                </ArticlePaper>
        );
}

export default QuoteCard;