import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid, Grow, Slide, Link } from '@mui/material';
import BasicButton from 'components/mui/buttons/basicButton';
import ChatIcon from '@mui/icons-material/Chat';
import { useInView } from 'react-intersection-observer'

const Header=()=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
        <WideContainer>
        <div className='center-align'>
                <Grid container className='rabbit-ai-header-container' pt={{xs:'150px'}}>
                    <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={7} lg={7} className='center-align'>
                            <div className='rabbit-ai-title'>
                                <h1>Meet Rabbit</h1>
                                <div style={{padding:'40px 0'}}>
                                <p>With Rabbit by your side, you have a dedicated AI assistant who can answer any questions you have about the platform or provide assistance in any area you need. </p>
                                <br/>
                                <p>Simply reach out to Rabbit whenever you require assistance, and experience the convenience of having an AI-powered companion at your fingertips.</p>
                                </div>
                                <Link py={1} href='/N85LNs' rel='noreferrer' underline="none" sx={{ display: 'inline-block', verticalAlign: 'top' }}>
                                  <BasicButton width='190px' content={<>{<ChatIcon sx={{ marginRight: '4px' }} />} CHAT WITH RABBIT</>} />
                                </Link>
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item py={5} xs={12} sm={12} md={5} lg={5} className='center-align'>
                            <img className='rabbit-ai-pic' src='/images/rabbit-ai/rabbit-ai.png' alt='rabbit-ai'/>
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
        </div>
        </WideContainer>
    );
}

export default Header;