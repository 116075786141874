import React from 'react';
import { Grid,Paper,Chip} from '@mui/material';
import ShareButton from 'components/mui/buttons/shareButton';
import { styled } from '@mui/material/styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';

const CardPaper = styled(Paper)`
    position: relative;
    display: flex;
    flex-direction:column;
    max-width:300px;
    height:550px;
    margin:10px 5px;

    & .cardpaper-pic {
        cursor: pointer;
        overflow: hidden;
    }
    & .cardpaper-pic img{
        border-radius:5px;
        width:100%;
        height:220px;
        object-fit: cover;
        -webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        -o-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
        transition: all 1s cubic-bezier(0, 0, 0.2, 1); };
    }

    & .cardpaper-pic:hover img{
        width:100%;
        height:220px;
        object-fit: cover;
        -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }

    & .cardpaper-title {
        color: black;
        white-space: pre-line;
        justify-content:center;
        margin:10px 0;
        border-bottom:2px solid var(--pxft-primary);
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2em;
        max-height: 4.2em; 
        text-overflow: ellipsis;
        min-height: 100px;
    }
    & .cardpaper-time {
        margin:10px 0;
        color: var(--pxft-midgrey);
        display:flex;
        flex-direction:row;
        align-items:center;
    }

    & .cardpaper-content {
        margin:10px 0;
        color: var(--pxft-midgrey);
        white-space: pre-line;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-height: 7em; 
        text-overflow: ellipsis;
        min-height: 85px;
    }
`

const BlogCard =({card})=> {

    const navigate = useNavigate();
    const blogLink ='/blogs/details/'+card.url;
    const blogCategory = card.categories;


    const handleRedirect = () => {
        navigate(blogLink)
    }

    const chipClick =(value)=>{
        value = value.toLowerCase()
        window.location.href = `/blogs/${value}`
    }
    
    
    const zipCategory = blogCategory.split(",") || []
    return (
        <div>
            <CardPaper elevation={3}>
                <div className='cardpaper-pic'>
                    <img loading='lazy' src={card.img_src} alt='' onClick={handleRedirect}/>
                </div>
                <Grid container sx={{p:2}}>
                    <Grid item xs={10}>
                     <h2 className='cardpaper-title' onClick={handleRedirect}>
                        {card.title}
                    </h2>
                        <div className='cardpaper-time'>
                            <CalendarMonthIcon/>
                            <p style={{padding:'0 5px'}}>{card.time}</p>
                        </div>

                            <p className='cardpaper-content' onClick={handleRedirect}>
                                {card.text}
                            </p>

                        {
                            zipCategory.map((category,i)=>
                            <Chip sx={{my:0.5}} key={i} label={category} variant="outlined" 
                            onClick={()=>chipClick(category)}
                             />
                            )
                        }
                    </Grid>
                    <Grid item xs={2} style={{position:'relative'}}>
                        <div style={{position:'absolute',bottom:0}}>
                        <ShareButton url={'https://younet.ai' + blogLink} title={card.title}/>
                        </div>
                    </Grid>
                </Grid>
            </CardPaper>
        </div>
    );
}

export default BlogCard;