import TermsArticle from 'components/resources/termsAndConditions/TermsArticle';
import React,{ useEffect} from 'react';
import 'assets/styles/resource.css'

const TermsAndConditions =()=> {
    useEffect(()=>{
        window.scrollTo(0, 0)})
    return (
        <div className='terms-and-conditions-container'>
            <TermsArticle/>
        </div>

    );
}

export default TermsAndConditions;