export const getItem = (name, type = '') => {
  try {
    const data = localStorage.getItem(name);
  
    if (type === 'json') {
      return JSON.parse(data);
    }

    return data || '';
  } catch {
    return '';
  }
}

export const removeItem = (name) => {
  try {
    localStorage.removeItem(name);
  } catch {
  }
}

export const setItem = (name, val) => {  
  try {
    if (typeof val === 'object') {
      val = JSON.stringify(val);
    }
    localStorage.setItem(name, val);
  } catch {
  }
}