import React from 'react';
import { Grid,Link } from '@mui/material';

const StickyLink = ()=> {
    return (
        <Grid container className='sticky-link-container'>
            <Grid item xs={12} className='sticky-link'>
                <Link href="https://younet.ai/portal/sign-up" underline='none'>Sign up for Free</Link>
            </Grid>
        </Grid>
    );
}

export default StickyLink;