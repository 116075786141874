import Sales from 'components/solution/sales/Sales';
import React, {useEffect} from 'react';
import createPixel from "helpers/createPixel";

const pixelUrl = 'https://www.facebook.com/tr?id=569201103273541&ev=PageView&noscript=1';

const SolutionSales = () => {

    useEffect(() => {
            window.scrollTo(0, 0)
        }
    )

    useEffect(() => {
        const sendFacebookPixel = async () => {
            await createPixel(pixelUrl, undefined, undefined, {allowError: true});
        }

        sendFacebookPixel();
    }, []);

    return (
        <div>
            <Sales/>
        </div>
    );
}

export default SolutionSales;