import React,{useEffect,useState} from "react"
import { useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import 'assets/styles/base.css';
import NavigationBar from "components/base/Navi/navbar";
import { getItem, setItem } from 'helpers/localStorageManagement';
import Footer from "components/base/Footer/footer";
import { Grid } from '@mui/material';
import HomeNavigator from "components/home/HomeNavigator";

const Layout = () => {
  const [hideNaviLogo,setHideNaviLogo] = useState(false)
  let location = useLocation();

  useEffect(()=>{
    const hideLogoNavi =[
      '/',
      '/landing'
    ]
      if(hideLogoNavi.includes(location.pathname)){
        setHideNaviLogo(true)
      }else{
        setHideNaviLogo(false)
      }
  },[hideNaviLogo,location])

  useEffect(()=>{
    const referrer = getItem('referrer');
    const userAgent = getItem('userAgent');

    if (!referrer?.length) {
      setItem('referrer', document.referrer);
    }
    if (!userAgent) {
      setItem('userAgent', navigator?.userAgent);
    }
  }, []);

  const containerStyle = {
    backgroundImage: 'url(\'/images/home/background-white.png\')',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    overflow: 'hidden'
  };

  return (
    <Grid container style={hideNaviLogo ? containerStyle : {}} width='100%' display='flex' flexDirection='column' justifyContent='center'>
      {hideNaviLogo ?
      <HomeNavigator/>:<NavigationBar/>}
      <Outlet />
      <Footer/>
    </Grid>
  )
};

export default Layout;