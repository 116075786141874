import React from 'react';
import { Grid, Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer'

const Header = () => {

    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    
    return (
        <Grid container
         className='center-align usescase-header-container usecases-background-cover'>
            <Grow timeout={{ enter: 800 }} in={inView}>
                <Grid item xs={12} className="center-align header-title">
                    <h1>The new way to work 10X faster</h1>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </Grow>
        </Grid>
    );
}

export default Header;