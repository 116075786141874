import React from 'react';
import { Grid, Grow, Slide, Link } from '@mui/material';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import ContentRowA from './ContentRowA';
import ContentRowB from './ContentRowB';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import YoutubeVideo from './YoutubeVideo';

const MonetizeAIArticle=()=> {
    const rows = [
        {
            title: 'Custom Subscription Settings',
            content: 'Tailor your monetization approach by setting up subscription prices. Design your model’s subscription page with images and descriptions that reflect the value of your AI model.',
            picSrc: '/images/products/monetize-ai/figure-1.png',
            picSrc2x:'/images/products/monetize-ai/figure-1@2x.png',
        },
        {
            title: 'Direct Link Sharing',
            content: 'Once your monetization is set up, you will generate a unique link to your model. Share this link through social media, emails, or directly with potential subscribers to expand your reach and increase your subscriber base.',
            picSrc: '/images/products/monetize-ai/figure-2.png',
            picSrc2x:'/images/products/monetize-ai/figure-2@2x.png',
        },
        {
            title:'Real-Time Earnings Dashboard',
            content:'Track your earnings and subscriber count in real time through the Monetization Center on the Younet platform. This dashboard provides a clear view of your model’s financial performance.',
            picSrc:'/images/products/monetize-ai/figure-3.png',
            picSrc2x:'/images/products/monetize-ai/figure-3@2x.png',
        },
        {
            title: 'Flexible Payment Options',
            content: 'Choose how you receive payments from subscribers with options like PayPal or Wire Transfer, ensuring convenience for both you and your subscribers.',
            picSrc: '/images/products/monetize-ai/figure-4.png',
            picSrc2x:'/images/products/monetize-ai/figure-4@2x.png',
        },
    ]

    return (
        <div>
            <WideContainer>
                <Grid container sx={{ py: { md: 3 } }} flexDirection='column' className='center-align ai-bot-video-background-cover'>
                    <Grid pt={4} pb={2} px={2}>
                    <h1 style={{textAlign:'center'}}>Turn Your Expertise into Revenue</h1>
                    </Grid>
                    <Grid className='overview-header-pic center-align'>
                        <Grid width='90vw' maxWidth='840px'>
                        <YoutubeVideo videoHeight='472.5' videoWidth='840'videoId='uLHfgWJpaqk' poster='/images/products/monetize-ai/monetize-ai-poster.jpeg'/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ pt: { xs:2, md: 3 } }}>
                <SupportedCompanies/>
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[0].title} contents={rows[0].content} picSrc={rows[0].picSrc} picSrc2x={rows[0].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[1].title} contents={rows[1].content} picSrc={rows[1].picSrc} picSrc2x={rows[1].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowA title={rows[2].title} contents={rows[2].content} picSrc={rows[2].picSrc} picSrc2x={rows[2].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid container sx={{ py: { xs:2, md: 3 } }} className='center-align'>
                    <ContentRowB title={rows[3].title} contents={rows[3].content} picSrc={rows[3].picSrc} picSrc2x={rows[3].picSrc2x} button={false} long={false} />
                </Grid>
                <Grid px={2} mb={{ xs:4, md: 8 } }>
                    <GetStartedCard/>
                </Grid>
            </WideContainer>
        </div>
    );
}

export default MonetizeAIArticle;