import React, { useState, useEffect } from 'react';
import BlogDetailsArticle from 'components/blogDetails/BlogDetailsArticle';
import BlogDetailsHeader from 'components/blogDetails/Header';
import { useParams } from 'react-router-dom';
import { API_PATHS } from 'constants/apiPaths';
import axios from 'axios';
import 'assets/styles/blogs.css'
import 'assets/styles/blogsPreview.css'


const BlogDetails = () => {
    const [data, setData] = useState({})
    const [dataLoading, setDataLoading] = useState(true)

    const params = useParams();

    const urlLink = params.id;


    const getBlogByUrl = async () => {

        let params = {
            urlLink: urlLink,
            pointer: true
        }

        await axios.post(API_PATHS.getBlogByUrl(), params)
            .then(
                (res) => {
                    console.log('response')
                    setData(res.data || {});
                    setDataLoading(false);
                }
            )
            .catch((error) => {
                setDataLoading(false);
            });
    }


    useEffect(() => {
        getBlogByUrl();
    }, []);


    if (dataLoading) {
        return <></>;
    }

    return (
        <>
            <BlogDetailsHeader blog={data.blog} />
            <BlogDetailsArticle data={data} />
        </>
    );
}

export default BlogDetails;