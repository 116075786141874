import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import {Grid,Grow,Button,Paper,Link} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import AboutCard from './AboutCard';

function AboutArticle(props) {
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const [inViewRef4, inView4] = useInView({
        triggerOnce: true,
    });
        const rows=[
            {
                title:'Story',
                content:'Our journey began at the tail end of 2021, sparked by a simple yet revolutionary idea: to automate the algorithm in ad delivery. This concept was the seed that sprouted into the formation of Younet in 2022. Our initial aim was to completely automate responses in Account Management, a task that was both time-consuming and complex.\n\nHowever, as we delved deeper into the world of AI and automation, we realized that our vision could be expanded. We saw the potential for a platform that could empower individuals and businesses to customize their own AI models, a tool that could revolutionize their workflow and productivity.\n\nThe post-Covid era presented its own unique challenges, with a significant shortage of personnel across various industries. We saw this not as a hurdle, but as an opportunity. An opportunity to fill a gap, to provide a solution, to make a difference.\n\nOur dedicated team embarked on an intense period of Research and Development, tirelessly working to bring our vision to life. The result was Younet, a platform that was more than just an automated response system. It was a tool that could be tailored to individual needs, a tool that could adapt and evolve, a tool that could truly expedite work.\n\nIn 2023, after countless hours of development and testing, our platform was finally ready. Younet was launched commercially, making personalized AI models accessible to the public.\n\nToday, we stand proud as a leading AI platform, committed to helping you harness the power of AI to optimize your work. At Younet, we believe in the power of technology to transform lives, and we are excited to be a part of your journey.\n\nWelcome to the future of work. Welcome to Younet.',
                src:''
            },
            {
                title:'Vision',
                content:'At Younet, our vision is as expansive as the horizon. We see a world where the boundaries of technology are constantly pushed, where the possibilities of AI are endless, and where the potential for growth is limitless.\n\nOur mission is simple yet profound: to automate human work. We believe that by harnessing the power of AI, we can revolutionize the way work is done. We aim to create a world where tasks are completed efficiently and effectively, where productivity is maximized, and where time is utilized to its fullest potential.\n\nWe envision a future where individuals and businesses can focus on what truly matters. A future where creativity and innovation are not hindered by mundane tasks. A future where time is spent on personal growth, on building relationships, on pursuing passions.\n\nAt Younet, we are not just creating AI models. We are creating opportunities. Opportunities for individuals to excel in their work. Opportunities for businesses to thrive. Opportunities for humanity to advance.\n\nWe believe in the power of AI to transform lives. We believe in the potential of technology to change the world. And we believe in our mission to make this vision a reality.\n\nJoin us on this journey. Together, we can redefine the future of work. Together, we can make a difference. Together, we can create a world where work is not a chore, but a choice.',
                src:''
            },
            {
                title:'Leadership',
                content:'The leadership at Younet is comprised of visionary individuals who are deeply committed to driving innovation in the field of artificial intelligence. Our leaders bring a wealth of experience and expertise in technology, business, and strategy, ensuring that Younet remains at the forefront of AI development.\n\nOur leadership team is dedicated to fostering a culture of excellence, collaboration, and continuous learning. They are responsible for guiding the company‘s strategic direction, overseeing the development of our advanced AI platform, and ensuring that we deliver cutting-edge solutions that meet the evolving needs of our clients.\n\nAt Younet, our leaders are not just managers but also mentors who empower our talented team to push the boundaries of what’s possible with AI. They prioritize the security, privacy, and satisfaction of our users, making sure that Younet not only provides exceptional AI-powered tools but also maintains the highest standards of ethical practice and compliance with global regulations.\n\nThe leadership‘s commitment to innovation is matched by their dedication to providing outstanding support and service, ensuring that every user of Younet can harness the full potential of personalized AI models to achieve their goals.',
                src:''
            },
        ]
        const persons = [
            {
                name:'Alex Kapralov',
                title:'Chief Executive Officer',
                des:'Over a decade of entrepreneurship in leading technology companies.',
                avatarSrc:'/images/about/avatar-alex.png',
                linkedIn:'https://www.linkedin.com/in/alexkapralov/',
            },
            {
                name:'Oleksandr Polishchuk',
                title:'Chief Technology Officer',
                des:'Versatile technology leader with a blend of programming expertise and executive leadership.',
                avatarSrc:'/images/about/avatar-sasha.png',
                linkedIn:'https://www.linkedin.com/in/polishchuk-oleksandr/',
            },
            {
                name:'Rakesh Malholtra',
                title:'Chief Financial Officer',
                des:'Results-driven CFO with over 20 years in leading financial operations and risk management.',
                avatarSrc:'/images/about/avatar-rakesh.png',
                linkedIn:'',
            }
        ]
    return (
        <div className='company-aboutus-container center-align'>
            <WideContainer className='company-aboutus-content'>
                <Grow timeout={{enter:1200}} in={inView1} >
                <Grid container sx={{py:{xs:3,md:2}}} className='center-align'>
                <Paper elevation={0} className='header-content'>
                    <div className='quotation'>
                    <img loading='lazy' src='/images/quotation-reverse.png' alt=''/>
                    </div>
                    <h1>{rows[0].title}</h1>
                    <p>{rows[0].content}</p>
                    <div className='long-observer' ref={inViewRef1}></div>
                </Paper>
                </Grid>
                </Grow>
                <Grow timeout={{enter:1200}} in={inView2} >
                <Grid container sx={{py:{xs:3,md:2}}} className='center-align'>
                <Paper elevation={0} className='header-content'>
                    <div className='quotation'>
                    <img loading='lazy' src='/images/quotation-reverse.png' alt=''/>
                    </div>
                    <h1>{rows[1].title}</h1>
                    <p>{rows[1].content}</p>
                    <div className='long-observer' ref={inViewRef2}></div>
                </Paper>
                </Grid>
                </Grow>

                <Grow timeout={{enter:1200}} in={inView3} >
                <Grid container sx={{py:{xs:3,md:2}}} className='center-align'>
                <Paper elevation={0} className='header-content'>
                    <Grid container>
                        <Grid item xs={12} lg={6}>
                        <h1>{rows[2].title}</h1>
                        <p>{rows[2].content}</p>
                        </Grid>
                        <Grid item xs={12} lg={6} pt={2}>
                            {
                                persons.map((person,i)=>
                                <AboutCard info={person} key={i}/>
                                )
                            }

                        </Grid>
                    </Grid>
                    <div className='long-observer' ref={inViewRef3}></div>
                </Paper>
                </Grid>
                </Grow>

                <Grow timeout={{enter:1200}} in={inView4}>
                <Grid container sx={{py:{xs:3,md:0}}} className='center-align'>
                    <Grid item xs={12} md={10.5} className='center-align'
                        sx={{flexDirection:'column',backgroundImage: `url('/images/home/business.png')`,backgroundPosition: "center",
                        color:'white',maxWidth:'1000px',padding:'40px 30px',margin:'60px 0',borderRadius:'25px'}} >
                                <h1 style={{color:'white',textAlign:'center',margin:'20px 0'}}>Embrace the Future with Younet</h1>
                                <p style={{fontSize:'12px'}}>Join us in our mission to revolutionize work through the power of AI</p>
                                <Link href='https://younet.ai/portal/sign-up'>
                                <Button variant="contained" sx={{padding:'15px',margin:'15px 0'}}>Create Your AI Model</Button>
                                </Link>
                            </Grid>
                <div className='observer' ref={inViewRef4}></div>
                </Grid>
                </Grow>
            </WideContainer>
        </div>
    );
}

export default AboutArticle;