import React from 'react';
import {Grid,Avatar,Link} from '@mui/material';

const AboutCard=({info})=> {
    return (
        <Grid container py={3} className='center-align'>
            <Grid item xs={4} lg={4} display='flex' justifyContent={{xs:'auto',lg:'center'}}>
                <Avatar src={info?.avatarSrc} sx={{width:{xs:'60px',lg:'90px'},height:{xs:'60px',lg:'90px'}}}/>
            </Grid>
            <Grid item xs={8} lg={7}>
                <Grid display='flex' flexDirection='row' alignItems='center'>
                    <h3>{info?.name}</h3>
                    {
                        info?.linkedIn.length === 0? 
                        '':
                        <Link display='flex' alignItems='center' px='5px' href={info?.linkedIn} target='_blank'>
                        <img width='20px' src='/images/about/linkedin_icon.png' alt='linkedin link'/>
                        </Link>
                    }
                </Grid>
                <Grid>
                <h6>{info?.title}</h6>
                </Grid>
                <Grid>
                <p>{info?.des}</p>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AboutCard;