import React,{useEffect} from 'react';
import Header from 'components/overview/Header';
import 'assets/styles/home.css'
import OverviewArticle from 'components/overview/OverviewArticle';

const Overview = ()=>{
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <Header/>
            <OverviewArticle/>
        </div>
    );
}

export default Overview;