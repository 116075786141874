import React, { useState, useEffect } from 'react';
import { Grid, Grow, Slide } from '@mui/material';
import { useInView } from 'react-intersection-observer'

const OverviewSteps = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [smallScreen, setSmallScreen] = useState();

    const handleCheckWidth = () => {
        setWindowWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleCheckWidth);
        return (() => {
            window.removeEventListener('resize', handleCheckWidth);
        })
    }, [])
    useEffect(() => {
        setSmallScreen(windowWidth < 900 ? true : false)
    }, [windowWidth])

    const rows = [
        {
            title: 'Step 1: Create an account',
            content: "Ready to become a part of the AI revolution and create your personalized model? Let’s begin by logging in to your Younet account. If you haven’t signed up yet, create a free account today.\n\nNext, head over to model creation and pick from a set of pre-existing models or customize your own.",
            src: '/images/home/step-1-pic.png',
            iconSrc: '/images/home/step-1.png'
        },
        {
            title: 'Step 2: Personalize',
            content: 'To further customize it to your area of expertise, our intuitive Q&A feature will guide you through the process.\n\nYou can describe your responsibilities and roles by answering a set of questions. Additionally, you can establish a knowledge base by sourcing text data, and files, or connecting your email conversations from Gmail.',
            src: '/images/home/step-2-pic.png',
            iconSrc: '/images/home/step-2.png'
        },
        {
            title: 'Step 3: Chat with your personalized AI',
            content: 'See how AI has learned about your expertise and start using it internally as a chatbot via Playground, or proceed to Step 4 to integrate it with an external communication application.',
            src: '/images/home/step-3-pic.png',
            iconSrc: '/images/home/step-3.png'
        },
        {
            title: 'Step 4: Add browser extension',
            content: 'Chrome Extension makes it easy to bring AI to any application with just a click, whether you want to respond to a message or draft a new one.\n\n It uses the knowledge you‘ve provided to communicate effectively.\n\nYou may edit the response if you don‘t like it and then click the Add to Brain icon in the Playground or Chrome Extension to train your model. Next time it will be more accurate to respond.',
            src: '/images/home/step-4-pic.png',
            iconSrc: '/images/home/step-4.png'
        }
    ]
    const [inViewRef0, inView0] = useInView({
        triggerOnce: true,
    });
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const [inViewRef3, inView3] = useInView({
        triggerOnce: true,
    });
    const containerRef0 = React.useRef(null);
    const containerRef1 = React.useRef(null);
    const containerRef2 = React.useRef(null);
    const containerRef3 = React.useRef(null);
    return (
        <div className='steps-content'>
            <div className='steps-content-column'>
                <Grid container className='center-align'>
                    <Grow timeout={{ enter: 1200 }} in={inView0}>
                        <Grid item md={5.5} className='center-align' sx={{ display: smallScreen ? 'none' : 'flex' }}>
                            <img loading='lazy' className='contentPic right-align' src={rows[0].src} alt='' />
                        </Grid>
                    </Grow>
                    <Grid item xs={2} md={1} className='center-align' sx={{ width: '40px', height: '100%' }}>
                        <div className='step-marker' >
                            <div style={{ height: '40%' }} className='step-block-block'></div>
                            <div style={{ height: '60%' }} className='step-line-top center-align'>
                                <img loading='lazy' src={rows[0].iconSrc} width='40px' height='40px' alt='' />
                                <div className='step-marker-line'></div>
                            </div>
                        </div>
                    </Grid>
                    <Slide timeout={{ enter: 800 }} in={inView0} direction="left" container={containerRef0.current}>
                        <Grid item xs={10} md={5.5} sx={{ p: 3 }}>
                            <img style={{ display: smallScreen ? 'flex' : 'none' }} loading='lazy' className='contentPic' src={rows[0].src} alt='' />
                            <h2 className='title'>{rows[0].title}</h2>
                            <br />
                            <p>{rows[0].content}</p>
                        </Grid>
                    </Slide>
                </Grid>
                <div className='observer' ref={inViewRef0}></div>
            </div>
            <div className='steps-content-column'>
                <Grid container className='center-align'>
                    <Slide timeout={{ enter: 800 }} in={inView1} direction="right" container={containerRef1.current}>
                        <Grid item xs={10} md={5.5} sx={{ display: smallScreen ? 'none' : 'flex', flexDirection: 'column' }}>
                            <img style={{ display: smallScreen ? 'flex' : 'none' }} loading='lazy' className='contentPic' src={rows[1].src} alt='' />
                            <h2 className='title'>{rows[1].title}</h2>
                            <br />
                            <p>{rows[1].content}</p>
                        </Grid>
                    </Slide>
                    <Grid item xs={2} md={1} className='center-align' sx={{ width: '40px', height: '100%' }}>
                        <div className='step-marker' >
                            <div style={{ height: '100%' }} className='step-line center-align'>
                                <img loading='lazy' src={rows[1].iconSrc} width='40px' height='40px' alt='' />
                                <div className='step-marker-line'></div>
                            </div>
                        </div>
                    </Grid>
                    <Grow timeout={{ enter: 1200 }} in={inView1}>
                        <Grid item xs={10} md={5.5} className='center-align' sx={{ p: 3, flexDirection: 'column' }}>
                            <img loading='lazy' className='contentPic left-align' src={rows[1].src} alt='' />
                            <div style={{ display: smallScreen ? 'flex' : 'none', flexDirection: 'column' }}>
                                <h2 className='title'>{rows[1].title}</h2>
                                <br />
                                <p>{rows[1].content}</p>
                            </div>
                        </Grid>
                    </Grow>
                </Grid>
                <div className='observer' ref={inViewRef1}></div>
            </div>
            <div className='steps-content-column'>
                <Grid container className='center-align'>
                    <Grow timeout={{ enter: 1200 }} in={inView2}>
                        <Grid item md={5.5} className='center-align' sx={{ display: smallScreen ? 'none' : 'flex' }}>
                            <img loading='lazy' className='contentPic right-align' src={rows[2].src} alt='' />
                        </Grid>
                    </Grow>
                    <Grid item xs={2} md={1} className='center-align' sx={{ width: '40px', height: '100%' }}>
                        <div className='step-marker' >
                            <div style={{ height: '100%' }} className='step-line center-align'>
                                <img loading='lazy' src={rows[2].iconSrc} width='40px' height='40px' alt='' />
                                <div className='step-marker-line'></div>
                            </div>
                        </div>
                    </Grid>
                    <Slide timeout={{ enter: 800 }} in={inView2} direction="left" container={containerRef2.current}>
                        <Grid item xs={10} md={5.5} sx={{ p: 3 }}>
                            <img style={{ display: smallScreen ? 'flex' : 'none' }} loading='lazy' className='contentPic' src={rows[2].src} alt='' />
                            <h2 className='title'>{rows[2].title}</h2>
                            <br />
                            <p>{rows[2].content}</p>
                        </Grid>
                    </Slide>
                </Grid>
                <div className='observer' ref={inViewRef2}></div>
            </div>
            <div className='steps-content-column'>
                <Grid container className='center-align'>
                    <Slide timeout={{ enter: 800 }} in={inView3} direction="right" container={containerRef3.current}>
                        <Grid item xs={10} md={5.5} sx={{ p: 3, display: smallScreen ? 'none' : 'flex', flexDirection: 'column' }}>
                            <img style={{ display: smallScreen ? 'flex' : 'none' }} loading='lazy' className='contentPic' src={rows[3].src} alt='' />
                            <h2 className='title'>{rows[3].title}</h2>
                            <br />
                            <p>{rows[3].content}</p>
                        </Grid>
                    </Slide>
                    <Grid item xs={2} md={1} className='center-align' sx={{ width: '40px', height: '100%' }}>
                        <div className='step-marker' >
                            <div style={{ height: '100%' }} className='step-line center-align'>
                                <img loading='lazy' src={rows[3].iconSrc} width='40px' height='40px' alt='' />
                                <div className='step-marker-line'></div>
                                <div style={{ height: '100%' }} className='step-block-block'></div>
                            </div>
                        </div>
                    </Grid>
                    <Grow timeout={{ enter: 1200 }} in={inView3}>
                        <Grid item xs={10} md={5.5} className='center-align' sx={{ p: 3, flexDirection: 'column' }}>
                            <img loading='lazy' className='contentPic left-align' src={rows[3].src} alt='' />
                            <div style={{ display: smallScreen ? 'flex' : 'none', flexDirection: 'column' }}>
                                <h2 className='title'>{rows[3].title}</h2>
                                <br />
                                <p>{rows[3].content}</p>
                            </div>
                        </Grid>
                    </Grow>
                </Grid>
                <div className='observer' ref={inViewRef3}></div>
            </div>
        </div>
    );
}

export default OverviewSteps;