import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import {Grid,Button} from '@mui/material';
import React from 'react';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import CenterTitle from 'components/mui/containers/centerTitle';


const BrandbookArticle=()=> {
    const containerStyle = {
        backgroundImage: `url('/images/brandbook/header-background.png')`, 
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
    return (
        <div className='brandbook-container' style={containerStyle}>
            <WideContainer>
            <CenterTitle title='Brand Assets of Younet'/>
            <div className='center-align brandbook-content'>
                <NarrowContainer className='mobile-reverse'>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                          <img loading='lazy' className='contentPic' src='/images/brandbook/brandbook-name.png' alt=''/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <BasicRowA title='Our Name' contents='"younet" is one word, spelled without any space between letters.' button={false}/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <BasicRowB title='Our Logo' contents='Our logo is using two colors or only white color when the background is dark.' button={false}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                          <img loading='lazy' className='contentPic' src='/images/brandbook/brandbook-logo.png' alt=''/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer className='mobile-reverse'>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <Grid container className='center-align'>
                            <img loading='lazy' className='contentPic' src='/images/brandbook/brandbook-color.png' alt=''/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <BasicRowA title='Our Theme Color' contents='We use four colors as our main color, and their transition is applied in the logo.' button={false}/>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer>
                        <Grid item xs={12} sm={6} md={6} className='center-align'>
                            <BasicRowB title='The Fine Print' contents='This is a friendly legal reminder that all the graphics are proprietary and protected under intellectual property laws.' button={false}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} className='center-align fine-print-container' style={{flexDirection:'column'}}>
                            <h3 className='title-container'>Please Do:</h3>
                            <div className='content'>
                                <p>Provide enough space around Younet logo.</p>
                                <p>Make the logo clear to be recognize.</p>
                            </div>
                            <h3 className='title-container'>Please Don't:</h3>
                            <div className='content'>
                                <p>Alter the files in any way.</p>
                                <p>Use these graphics as part of your own product, business, or service’s name.</p>
                            </div>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
            <WideContainer>
            <div className='center-align brandbook-content'>
                <NarrowContainer>
                        <Grid item xs={12} sm={12} md={12} className='center-align'>
                            <CenterTitle title='Downloads' article=''/>
                        </Grid>
                        <Grid item xs={12} mt={4}>
                            <Grid container className='center-align'>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='60px' src='/images/brandbook/logo-color.png' alt=''/>
                                  <a href='/files/brandbook/logo-white-background.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with white background</Button></a>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='60px' src='/images/brandbook/extension-icon.png' alt=''/>
                                  <a href='/files/brandbook/logo-color-background.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with color background</Button></a>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='60px' src='/images/brandbook/logo-black.png' alt=''/>
                                  <a href='/files/brandbook/logo-with-name.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with company name</Button></a>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='60px' src='/images/brandbook/logo-black.png' alt=''/>
                                  <a href='/files/brandbook/logo-with-name-400.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with company name(width:400px)</Button></a>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='60px' src='/images/brandbook/logo-svg.svg' alt=''/>
                                  <a href='/files/brandbook/logo-svg.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with white background(vector file)</Button></a>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} className='center-align' sx={{flexDirection:'column'}}>
                                  <img loading='lazy' height='50px' src='/images/brandbook/logo-younet.svg' alt=''/>
                                  <a href='/files/brandbook/logo-younet-svg.zip'><Button sx={{p:2,m:3}} variant='contained'>Our Logo with company name(vector file)</Button></a>
                                </Grid>
                            </Grid>
                        </Grid>
                </NarrowContainer>
            </div>
            </WideContainer>
        </div>
    );
}

export default BrandbookArticle;