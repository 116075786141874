import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Support = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Provide detailed replies anywhere',
            contents:'Use AI to respond to emails, fill out helpdesk, or answer messages using all available problem-solving information. ',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'I can‘t log in to my account. What should I do?',
                answerContent:'If you cannot log into your account, please ensure that you are using the correct login credentials. If the issue persists, you can  <…>'
            },
            picsrc:'/images/home/extension-support.png',
            picsrcMobile:'/images/home/extension-support-mobile.png',
        },
        {
            title:'Centralized knowledge',
            contents:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, enabling them to deliver faster, more personalized customer service experiences, and respond 10x faster to customer inquiries.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'I need API documentation information for a client. Can you help?',
                answerContent:' I can help you with that. Here is information for API integration <…>'
            }
        },
        {
            title:'Utilize the automatic support using the “Sharing” feature',
            contents:'Share your support AI model trained on technical documentation with colleagues or clients. 24/7 available to answer any questions.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'How can I update my payment information for my subscription?',
                answerContent:'To update your payment information, please go to your account settings where you can edit your billing details <…>'
            }
        },
        {
            title:'Seamless employee onboarding',
            contents:'No more lengthy training and searching through all FAQ information. New employees can hit the ground running from day one, as they have immediate access to an AI model well-versed in the company‘s products, features, and possible issues.',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'What steps should I take if I suspect unauthorized access to my account?',
                answerContent:'Immediately change your password and contact the support agent to report the incident and secure your account. <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for Support Teams',
        'Empower Your',
        'Support Team with AI'
    ]
    const headerContent = 'Boost your support team‘s efficiency and accuracy with Younet AI‘s intelligent automation, enabling them to deliver faster, more personalized customer service experiences, and analyze and respond to customer inquiries.'
    const tagContentObj =           
    {
      label: 'Support',
      avatar1: 'P',
      avatar2: '/images/btn.png',
      role:'Prompt to Customer Support',
      fileNames:[
        'Knowledge base.pdf',
        'Troubleshooting-tips.xlsx'
      ],
      sentence1: 'My client is asking how to update our Chrome Extension on Mac. What are the steps?',
      sentence2: 'To update the Chrome extension on a Mac computer, your client can follow these steps:\n\n\t1. Open the Google Chrome browser on the Mac.\n\t2. Click on the three-dot menu icon in the upper-right corner of the browser window.\n\t3. From the dropdown menu, select "More Tools" and then "Extensions."\n\t4. This will open the Extensions page. Here, your client should enable < ... >',
    }
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item  px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Support;