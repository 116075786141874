import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Box } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import ConversationCard from './ConversationCard';

const ContentRowB=({title='',card = '',contents='',picSrc=''})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align' style={{overflow:'hidden'}}>
                    <NarrowContainer>
                    <Slide timeout={{enter:800}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={6} flexDirection='column'>
                            <Box p={2}>
                                <Box py={{xs:1,lg:3}}><h1>{title}</h1></Box>
                                <Box py={{xs:1,lg:2}}><p>{contents}</p></Box>
                                {picSrc && 
                                <Box py={{xs:1,lg:2}}><img src={picSrc} alt='' width='150px'/></Box>
                                }
                            </Box>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={12} md={6} className='center-align'>
                              <ConversationCard card={card}/>
                        </Grid>
                    </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default ContentRowB;