import { Grid } from '@mui/material';
import AuthForm from './auth/AuthForm';
import LogoSvgBig from 'components/logoSvg/logoSvgBig';
import TextAnimation from './components/TextAnimation';

const HomeArticle = () => {

  return (
      <Grid item xs={12} className="home-article-container">
        <Grid container className="home-article">
          <Grid
            item
            xs={12}
            md={5}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid className="home-logo">
              <LogoSvgBig />
            </Grid>
            <Grid py={3} className="title-wrapper">
              <TextAnimation />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} mt={3}>
            <AuthForm />
          </Grid>
        </Grid>
      </Grid>
  );
};
export default HomeArticle;
