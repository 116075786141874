import BrandbookArticle from 'components/resources/brandbook/BrandbookArticle';
import React,{useEffect} from 'react';
import 'assets/styles/resource.css'


const Brandbook = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <BrandbookArticle/>
        </div>
    );
}

export default Brandbook;