import React from 'react';
import { Box } from '@mui/material';
import BasicButton from '../buttons/basicButton';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'

const ArticleBox = styled(Box)`
        position: relative;
        display: flex;
        flex-direction:column;

        & .title {
            display: flex;
            flex-direction: row;
            color:black;
            text-align:left;
            white-space: pre-line;
        }
        & .title h1{
            font-size: 38px;
        }

        & .content {
            padding:40px 0;
            line-height:1.3;
            white-space: pre-line;
        }

        & .btn-container {
            position: absolute;
            bottom: 0;
            left:20px;
            width: 130px;
            height:40;
        }
`;

const ArticleCardA = ({title='',content='',button=true,btnText='Learn More',link=''}) =>{
    return (
        <ArticleBox sx={{py:6,px:3,width:'90%'}}>
            <Box className='title'>
                <h1>{title}</h1>
            </Box>
            <p className='content'>{content}</p>
            {button? 
            <div className='btn-container'>
            <Link to={link}> <BasicButton width='200px' content={btnText}/></Link>
            </div>:''}
        </ArticleBox>
    );
}

export default ArticleCardA;