import React from 'react';
import { NarrowContainer, WideContainer} from 'components/mui/containers/PxftContainer';
import IconCardB from 'components/mui/cards/iconCardB';
import { Grid,Grow} from '@mui/material';
import { useInView } from 'react-intersection-observer'

const ContactGrids = ()=> {
    const iconcardList = [
        {
            id:1,
            icon:'demand',
            title:'I have Younet account, and I need support.',
            contents:'',
            iconWidth:'50',
            iconHeight:'50',
            link:'https://support.younet.ai/login'
        },
        {
            id:2,
            icon:'monetize',
            title:'I don‘t have Younet account, and I have questions.',
            contents:'',
            iconWidth:'50',
            iconHeight:'50',
            link:'https://support.younet.ai/submit-ticket'
        }
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    })
    return (
        <WideContainer width='100%'>
            <div className='center-align'>
            <NarrowContainer vertical>
                <Grow timeout={{enter:800}} in={inView}>
                    <div>
                    <Grid container className='center-align'>
                            {
                                iconcardList.map(listItem =>(
                                        <IconCardB key={listItem.id} IconCard={listItem}/>
                                ))
                            }
                    </Grid>
                    </div>
                </Grow>
            </NarrowContainer>
            </div>
            <div className='observer' ref={inViewRef}></div>
        </WideContainer>
    );
}

export default ContactGrids;