import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Hr = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Provide detailed replies anywhere',
            contents:'Use AI to respond to internal emails, fill out helpdesk, or provide documents using all company policy documents and legal datasets.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'I want to take a vacation from April 5. What should I do?',
                answerContent:'Hello, To request vacation time starting April 5th, please follow these steps:\n\n1.Check your current vacation balance to ensure <…>'
            }
        },
        {
            title:'Centralized knowledge',
            contents:'Imagine an HR equipped with instant access to a centralized AI knowledge hub, capable of answering questions about policies and providing documents at the click of a button. No more forgetting documents or manual drafting letters.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'Do the perks of this employment role include health insurance?',
                answerContent:'Yes, one of the perks of this employment role includes comprehensive health insurance coverage. Our health insurance benefits are <…>'
            }
        },
        {
            title:'Automate HR work using the “Sharing” feature',
            contents:'Share your HR AI model trained on all policy and internal documentation with colleagues. It will be 24/7 available to answer any questions. There is no need to wait for human response anymore.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'How can I update my emergency contact information in the company records?',
                answerContent:'To update your emergency contact information, please log in to our employee self-service portal and navigate to <…>'
            }
        },
        {
            title:'Seamless employee interviewing',
            contents:'No more lengthy preparation and searching through all FAQ information. A List of interview questions can be drafted in a couple of seconds based on each candidate‘s resume.',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'Prepare questions for the interview with candidate 4.',
                answerContent:'Here are some interview questions that an HR AI might prepare for a candidate: \n\n1.What strategies do you use to maintain effective communication in a remote or <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for HR Teams',
        'Empower Your',
        'HR Team with AI'
    ]
    const headerContent = 'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement. With Younet AI, your HR team can access predictive analytics to make informed decisions and foster a dynamic, inclusive workplace culture.'
    const tagContentObj ={
        label: 'HR',
        avatar1: 'P',
        avatar2: '/images/btn.png',
        role:'Prompt to HR',
        fileNames:[
          'Employment agreement.pdf',
          'Policy.pdf',
          'Employment letter template.doc'
        ],
        sentence1: 'Write an employment letter for David Jefferson.',
        sentence2: 'Subject: Employment Confirmation Letter\n\nWe are pleased to confirm your employment. This letter serves to affirm the terms of your employment and your contributions to our team as a valued member.\nPosition: Senior Software Engineer\nStart Date: December 2, 2019\nAnnual Compensation: $150,000\n\nAs a Senior Software Engineer, you have consistently demonstrated exceptional < ... >',
      }
    
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Hr;