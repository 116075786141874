import React from 'react';
import {Grid} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function OverviewFaq(props) {
    return (
            <Grid container sx={{maxWidth:'800px'}} px={3}>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>What is Younet?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>At Younet, we are dedicated to the advancement of machine intelligence for the benefit of all humanity. Our mission is to create a future where Artificial Intelligence technology serves as a powerful tool that positively impacts every aspect of human life.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>

                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>What is Rabbit?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>The Rabbit AI model is a platform virtual companion created by Younet and serves as a little artificial intelligent assistant for users seamlessly integrate Younet into their lives. With Rabbit, users can effortlessly interact with technology and harness its power to construct personalized AI models that align perfectly with their unique requirements.  </p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>Do I need to sign a long-term contract for your services?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>No, that's not necessary. You have the option to cancel at any time and account will be paused on the date until you paid.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>

                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>How does Younet work?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Younet works by storing your data to analyze and process input text to generate relevant responses. You will be able to train your customized AI models on your data and/or use massive amount of data from neural network to improve its language processing abilities.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>Is it free to use?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Certainly, Younet is completely free to use. We offer a 150 credits free trial, which allows users to experiment with our AI-based technology. </p>
                    <p>If you're not completely satisfied with our AI writing platform during the free trial, you can cancel your subscription at any time. </p>
                    <p>Sign up today to take advantage of Younet's free trial and unlock the full potential of our AI capabilities.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>Can Younet understand multiple languages?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Yes, Younet can understand and respond in multiple languages. However, its ability to accurately process and generate responses may vary depending on the language.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>Where Younet can be used?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Younet is an AI-powered platform that enables users to create a personalized AI Model capable of producing human-like and engaging responses to inquiries based on the knowledge provided. It uses advanced natural language processing and Logic Learning Machine (LLM) technologies to deliver accurate and customized answers. </p>
                    <p>Younet's applications span across a variety of fields such as customer service, marketing, science, sales, analytics, and customer engagement. Businesses can leverage Younet to gain insights into customer behavior and deliver personalized experiences. It can also be used for personal use to tackle intellectual tasks. </p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>Can Younet replace human customer service representatives?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Younet can assist in answering customer queries and providing support, but it may not be able to replace human representatives completely. It is best used as a complementary tool to improve efficiency and response time.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>How accurate are Younet's responses?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Younet's accuracy depends on the quality of the input data and the complexity of the task. In general, it can provide accurate responses for many common queries and prompts.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>
                <Grid item py={1} xs={12} className='center-align'>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                    <h4>How can I integrate Younet into my website, application or robotic technology?</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                    <p>Younet can be integrated into websites, applications or robotic technologies through Younet API and other integration tools. Younet provides documentation and support for developers looking to integrate their trained AI Models in software or robotic technologies.</p>
                    </AccordionDetails>
                </Accordion> 
                </Grid>

            </Grid>
    );
}

export default OverviewFaq;