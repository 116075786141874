import { removeItem } from './localStorageManagement';

export const logout = () => {
  removeItem('token');
  removeItem('cachedModel');
  removeItem('guestId');
  removeItem('activePage');
  removeItem('guestId');
  removeItem('userData');
}
