import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import {Grid,Grow,Button,Link} from '@mui/material';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import { useInView } from 'react-intersection-observer'

const RabbitAIArticle=()=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const rows=[
        {
            title:'Your go-to assistant',
            content:'You may always count on instant support and prompt responses to any queries you may have. Say goodbye to your personal assistant and say hello to Rabbit, who is ready to provide real-time help and assistance whenever you need it. Experience the convenience of having a reliable AI companion by your side.',
            src:'/images/rabbit-ai/feature-1.png'
        },
        {
            title:'Seamless integration',
            content:'Consider Rabbit AI as your trusted companion on the Younet platform. From providing detailed explanations to answering platform-related questions, Rabbit AI is here to make your journey on Younet as smooth as possible. Experience the convenience of having a personal assistant dedicated to your success.',
            src:'/images/rabbit-ai/feature-2.png'
        },
        {
            title:'Your ultimate business companion',
            content:'With its advanced capabilities, Rabbit AI can assist you in a wide range of business tasks, including customer service, marketing, sales, analytics, and customer engagement. Whether you require insights into customer behavior or personalized experiences, Rabbit is here to provide unwavering support at every step of your journey.',
            src:'/images/rabbit-ai/feature-3.png'
        },
    ]
    return (
        <div className='rabbit-ai-article-container'>
            <WideContainer>
                <div className='rabbit-ai-article center-align'>
                    <Grid container className='center-align'>
                        <BasicRowA title={rows[0].title} contents = {rows[0].content} picSrc={rows[0].src} button={false} long={false}/>
                    </Grid>
                    <Grid container className='center-align'>
                        <BasicRowB title={rows[1].title} contents = {rows[1].content} picSrc={rows[1].src} button={false} long={false}/>
                    </Grid>
                    <Grid container className='center-align'>
                        <BasicRowA title={rows[2].title} contents = {rows[2].content} picSrc={rows[2].src} button={false} long={false}/>
                    </Grid>
                </div>
                <WideContainer>
                <div className='center-align'>
                    <NarrowContainer className='center-align'>
                                <Grow timeout={{enter:1200}} in={inView} >
                                    <Grid item xs={12} sm={12} md={8} 
                                    sx={{flexDirection:'column',backgroundImage: `url('/images/home/business.png')`,backgroundPosition: "center",
                                    color:'white',padding:'40px 30px',margin:'60px 0',borderRadius:'25px'}} 
                                    className='center-align'>
                                        <h1 style={{color:'white',textAlign:'center',margin:'20px 0',width:'80%'}}>Empower Yourself with Next-Generation Technology</h1>
                                        <Link href='https://younet.ai/portal/sign-up'>
                                        <Button variant="contained" sx={{padding:'15px',margin:'15px 0'}}>Get Started</Button>
                                        </Link>
                                    </Grid>
                                </Grow>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
                </WideContainer>
            </WideContainer>
        </div>
    );
}

export default RabbitAIArticle;