import { useEffect, useState } from "react";

const isInAppBrowser = (userAgent) => {
  const ua = userAgent || navigator.vendor || window.opera;
  return (
    (ua.indexOf("FBAN") > -1) || // Facebook Mobile App's WebView
    (ua.indexOf("FBAV") > -1) || // Facebook Mobile App's WebView for iOS
    (ua.indexOf("Instagram") > -1) || // Instagram's WebView
    (ua.indexOf("LinkedInApp") > -1) || // Instagram's WebView
    (!ua.match(/Chrome/i) && !!ua.match(/Mobile Safari/i)) // not Chrome but mobile Safari, possibly an in-app browser
  );
};

const useDeviceDetect = () => {
  const [isMobileDevice, setMobile] = useState(false);

  const userAgent =
  typeof window.navigator === "undefined" ? "" : navigator.userAgent;

  const isInAppView = isInAppBrowser(userAgent);

  useEffect(() => {

    const mobile = Boolean(

      userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Mobile|SymbianOS|Opera Mini|IEMobile|Kindle|Silk|PlayBook|Nintendo|Mobile Safari|Mobile/i
      )
    );
    setMobile(mobile);
  }, []);

  return { isMobileDevice, userAgent, isInAppView };
};

export default useDeviceDetect;
