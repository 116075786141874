import React,{useState}  from 'react';
import { Grid, Avatar } from '@mui/material';
import { Description } from '@mui/icons-material';
import {AskingTypingEffect,AnsweringTypingEffect} from 'components/mui/TypingEffect';

const TypingContent=({object})=> {
    const [askingTextComplete, setAskingTextComplete] = useState(false);

    return (
        <Grid p={2} sx={{border:'solid 3px var(--ynt-lightpurple)', borderRadius:'20px'}}>
            <Grid container py={2} sx={{minWidth:'100%'}}>
              {/* the ask part */}
              <Grid container p={2} mb={3} sx={{backgroundColor:'var(--ynt-lightgrey)', borderRadius:'20px'}}>
              <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Avatar alt="User avatar" src={object?.avatar1} style={{ marginRight: '0.5rem' }}>{object?.avatar1}</Avatar>
                <h5>{object?.role}</h5>
                </Grid>
                <Grid item xs={12} p={2} sx={{height:{lg:'80px',xs:'110px'}}}>
                  <AskingTypingEffect onCompleted={() => setAskingTextComplete(true)} text={object?.sentence1}/>
                </Grid>
              </Grid>
              {/* the response part */}
              <Grid container sx={{minWidth:'100%'}}>
                <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Avatar sx={{ bgcolor: 'var(--ynt-primary)'}} alt="User avatar" src={object?.avatar2} style={{ marginRight: '0.5rem' }}/>
                <h5 style={{color:'var(--ynt-primary)'}}>AI Response</h5>
                </Grid>
                <Grid item xs={12} p={2} sx={{height:{lg:'220px',xs:'280px'}}}>
                    {!askingTextComplete && <AnsweringTypingEffect text=''/>}
                    {askingTextComplete && <AnsweringTypingEffect text={object?.sentence2}/>}
                </Grid>
              </Grid>
            </Grid>
            {/* the bottom navi bar part */}
                <Grid px={2} display='flex' flexDirection={{xs:'column',lg:'row'}} alignItems={{xs:'left',lg:'center'}} className='info-span' sx={{opacity:askingTextComplete? 1.0:0.0,minWidth:'257px'}}>
                      <Grid item pr={1} py={1}><p>Generated based on: </p></Grid>
                      {object?.fileNames.map((item,i)=>(
                        <Grid item display='flex' flexDirection='row' alignItems='center' key={i}><Description/><Grid px={0.5}><p>{item}</p></Grid></Grid>
                      ))}
                </Grid>
        </Grid>
    );
}

export default TypingContent;