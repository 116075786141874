import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { grey } from '@mui/material/colors';
import { Grid, Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NaviData from 'data/navi/naviLinkItem.json';
import { useLocation,Link } from "react-router-dom";

// Jesus is needed here when available

const MobileMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [resourceSubDisplay, setResourceSubDisplay] = useState(false)
    const [companySubDisplay, setCompanySubDisplay] = useState(false)
    const [productsSubDisplay, setProductsSubDisplay] = useState(false)
    const [exploreSubDisplay, setExploreSubDisplay] = useState(false)
    const [solutionDisplay, setSolutionDisplay] = useState(false)
    const [hideNaviLogo,setHideNaviLogo] = useState(false)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const location = useLocation()
    useEffect(()=>{
        const hideLogoNavi =[
          '/',
          '/landing'
      ]
        if(hideLogoNavi.includes(location.pathname)){
          setHideNaviLogo(true)
        }
    },[hideNaviLogo,location])

    return (
        <div className='nav-mobile-menu'>
            <Button
                className='settingIcon'
                id="mobile-navi-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            ><MenuIcon/></Button>
            <Menu
                id="mobile-menu"
                width='100%'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'mobile-navi-button',
                }}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 35,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                <Grid container px={3} py={1} width={300} sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    <Grid item xs={12}>
                        {/* {NaviData && NaviData[0].list.map((item, i) => (
                                <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer"> 
                                <MenuItem divider onClick={handleClose}>
                                    {item.text}
                                </MenuItem></Link>
                                ))
                        } */}
                        <MenuItem
                            divider
                            sx={{ color: 'black', justifyContent: 'space-between' }} onClick={() => { setProductsSubDisplay(!productsSubDisplay); setCompanySubDisplay(false); setSolutionDisplay(false);setResourceSubDisplay(false);setExploreSubDisplay(false);}}>
                            {NaviData[0].title}
                            {productsSubDisplay ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                        </MenuItem>
                        <Collapse timeout="auto" unmountOnExit in={productsSubDisplay}>
                            <div>
                                {NaviData && NaviData[0].list.map((item, i) => (
                                    <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer">
                                    <MenuItem divider onClick={handleClose}>
                                        <ListItemText inset sx={{ color: grey[700], fontWeight: 300 }} onClick={handleClose}>{item.text}</ListItemText>
                                    </MenuItem>
                                    </Link>
                                ))}
                            </div>
                        </Collapse>
                        <MenuItem
                            divider
                            sx={{ color: 'black', justifyContent: 'space-between' }} onClick={() => { setCompanySubDisplay(!companySubDisplay); setProductsSubDisplay(false); setSolutionDisplay(false);setResourceSubDisplay(false);setExploreSubDisplay(false);}}>
                            {NaviData[1].title}
                            {companySubDisplay ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                        </MenuItem>
                        <Collapse timeout="auto" unmountOnExit in={companySubDisplay}>
                            <div>
                                {NaviData && NaviData[1].list.map((item, i) => (
                                    <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer">
                                    <MenuItem divider onClick={handleClose}>
                                    <ListItemText inset sx={{ color: grey[700], fontWeight: 300 }} onClick={handleClose}>{item.text}</ListItemText>
                                    </MenuItem>
                                    </Link>
                                ))}
                            </div>
                        </Collapse>
                        <MenuItem
                            divider
                            sx={{ color: 'black', justifyContent: 'space-between' }} onClick={() => { setResourceSubDisplay(!resourceSubDisplay); setProductsSubDisplay(false); setSolutionDisplay(false);setCompanySubDisplay(false) ;setExploreSubDisplay(false);}}>
                            {NaviData[2].title}
                            {resourceSubDisplay ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                        </MenuItem>
                        <Collapse timeout="auto" unmountOnExit in={resourceSubDisplay}>
                            <div>
                                {NaviData && NaviData[2].list.map((item, i) => (
                                    <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer">
                                    <MenuItem divider onClick={handleClose}>
                                    <ListItemText inset sx={{ color: grey[700], fontWeight: 300 }} onClick={handleClose}>{item.text}</ListItemText>
                                    </MenuItem>
                                    </Link>
                                ))}
                            </div>
                        </Collapse>
                        <MenuItem
                            divider
                            sx={{ color: 'black', justifyContent: 'space-between' }} onClick={() => { setExploreSubDisplay(!exploreSubDisplay); setResourceSubDisplay(false); setSolutionDisplay(false);setProductsSubDisplay(false); setCompanySubDisplay(false) }}>
                            {NaviData[3].title}
                            {exploreSubDisplay ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                        </MenuItem>
                        <Collapse timeout="auto" unmountOnExit in={exploreSubDisplay}>
                            <div>
                                {NaviData && NaviData[3].list.map((item, i) => (
                                    <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer">
                                    <MenuItem divider onClick={handleClose}>
                                    <ListItemText inset sx={{ color: grey[700], fontWeight: 300 }} onClick={handleClose}>{item.text}</ListItemText>
                                    </MenuItem>
                                    </Link>
                                ))}
                            </div>
                        </Collapse>
                        <MenuItem
                            divider
                            sx={{ color: 'black', justifyContent: 'space-between' }} onClick={() => { setSolutionDisplay(!solutionDisplay); setProductsSubDisplay(false); setCompanySubDisplay(false);setResourceSubDisplay(false);setExploreSubDisplay(false);} }>
                            {NaviData[4].title}
                            {solutionDisplay ? <ExpandLess fontSize='small' /> : <ExpandMore fontSize='small' />}
                        </MenuItem>
                        <Collapse timeout="auto" unmountOnExit in={solutionDisplay}>
                            <div>
                                {NaviData && NaviData[4].list.map((item, i) => (
                                    <Link key={i} to={item.url} target={item.outSource ? "_blank" : ''} rel="noopener noreferrer">
                                    <MenuItem divider onClick={handleClose}>
                                    <ListItemText inset sx={{ color: grey[700], fontWeight: 300 }} onClick={handleClose}>{item.text}</ListItemText>
                                    </MenuItem>
                                    </Link>
                                ))}
                            </div>
                        </Collapse>

                        {
                            !hideNaviLogo &&
                            <>
                                <a href="https://younet.ai/portal/login" rel="noreferrer" style={{ color: 'black' }}>
                                    <MenuItem divider onClick={handleClose}>
                                        <div>Sign in</div></MenuItem></a>
                                <a href="https://younet.ai/portal/sign-up" rel="noreferrer" style={{ color: 'black' }}>
                                    <MenuItem onClick={handleClose}>
                                        <div>Get Started</div></MenuItem></a>
                            </>
                        }

                    </Grid>
                </Grid>
            </Menu>
        </div>
    )
}

export default MobileMenu;
