import React from 'react';

const LogoSvg = ()=> {
    return (
        <svg width="150" height="36.965" viewBox="0 0 150 36.965">
        <defs>
            <linearGradient id="linear-gradient" x1="0.09" y1="0.293" x2="0.894" y2="0.743" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a061f3"/>
            <stop offset="0.471" stopColor="#6074ee"/>
            <stop offset="1" stopColor="#1fa6f4"/>
            </linearGradient>
        </defs>
        <g id="Group_8768" data-name="Group 8768" transform="translate(0 0)">
            <path id="Union_1" data-name="Union 1" d="M17.155,35.331a1.634,1.634,0,1,1,1.634,1.634A1.634,1.634,0,0,1,17.155,35.331Zm7.585-5.208H13.859a1.634,1.634,0,1,1-.053-.817H24.793a1.634,1.634,0,1,1-.053.817Zm-9.57-6.842H8.537a2.042,2.042,0,1,1,0-.817h6.582a3.268,3.268,0,0,1,6.523,0h7.4a2.043,2.043,0,0,1,4,0h6.617a2.042,2.042,0,1,1,0,.817H33.044a2.043,2.043,0,0,1-4,0h-7.45a3.268,3.268,0,0,1-6.421,0ZM9.838,15.419H3.186a1.634,1.634,0,1,1,.053-.817H9.816a3.676,3.676,0,0,1,7.327,0h6.581a2.553,2.553,0,0,1,5.041,0h4.758a1.634,1.634,0,0,1,3.21,0H47.206a1.634,1.634,0,1,1,.053.817H36.68a1.634,1.634,0,0,1-3.105,0H28.764a2.553,2.553,0,0,1-5.041,0h-6.6a3.677,3.677,0,0,1-7.282,0ZM39.072,8.68H23.218a2.043,2.043,0,0,1-3.956,0H9.739a2.043,2.043,0,1,1,.042-.817h9.44a2.043,2.043,0,0,1,4.039,0H39.031a2.042,2.042,0,1,1,.042.817ZM31.107,2.553H19.542a2.043,2.043,0,1,1,.041-.817H31.066a2.042,2.042,0,1,1,.041.817Z" fill="url(#linear-gradient)"/>
        </g>
        <path id="Path_5941" data-name="Path 5941" d="M33.868,7.365h-2.7L26.907,17.952,26.5,19.286l-.348-1.305L21.918,7.365H19.249L25.224,21.81l-2.7,6.149h2.7Zm8.76-.493A7.774,7.774,0,1,0,50.4,14.645,7.756,7.756,0,0,0,42.628,6.872Zm0,2.262A5.512,5.512,0,1,1,37.2,14.616,5.472,5.472,0,0,1,42.628,9.134ZM65.02,7.365v9.282a6.755,6.755,0,0,1-5.308,3.423c-2.582,0-3.916-1.856-3.916-4.409v-8.3h-2.32v8.586c0,3.626,2.088,6.323,5.714,6.323a7.258,7.258,0,0,0,5.888-3.162v2.756h2.262V7.365Zm14.735-.406a7.258,7.258,0,0,0-5.888,3.162V7.365H71.6v14.5h2.349V12.586a6.664,6.664,0,0,1,5.308-3.423c2.553,0,3.887,1.856,3.887,4.409v8.3H85.5V13.282C85.5,9.656,83.381,6.959,79.755,6.959Zm24.191,7.425a7.457,7.457,0,0,0-7.658-7.513,7.777,7.777,0,1,0,.319,15.547,9.629,9.629,0,0,0,5.714-1.827l-.493-2.349a8.86,8.86,0,0,1-5.221,1.972,5.422,5.422,0,0,1-5.656-4.554H103.83A7.682,7.682,0,0,0,103.946,14.384ZM96.289,9.047a5.067,5.067,0,0,1,5.25,4.67H90.922A5.343,5.343,0,0,1,96.289,9.047ZM115.2,19.257a6.135,6.135,0,0,1-2.843.667,2.187,2.187,0,0,1-2.378-2.349V9.54h5.4V7.365h-5.4V3.188h-2.32V7.365h-2.64V9.54h2.64v8.006c0,2.988,1.711,4.641,4.583,4.641a9,9,0,0,0,3.394-.725Z" transform="translate(34.365 0.595)" fill="#454545"/>
        </svg>
    );
}

export default LogoSvg;