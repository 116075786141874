import BusinessArticle from 'components/business/BusinessArticle';
import Header from 'components/business/Header';
import React,{useEffect} from 'react';
import 'assets/styles/business.css'
import 'assets/styles/home.css'

const Business =()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <Header/>
            <BusinessArticle/>
        </div>
    );
}

export default Business;