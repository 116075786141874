import React from 'react';

const LogoSvgBig=()=> {
    return (
        <svg width="300" height="73.5" viewBox="0 0 300 73.5" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="linear-gradient2" x1="0.09" y1="0.293" x2="0.894" y2="0.743" gradientUnits="objectBoundingBox">
            <stop offset="0" stopColor="#a061f3"/>
            <stop offset="0.471" stopColor="#6074ee"/>
            <stop offset="1" stopColor="#1fa6f4"/>
            </linearGradient>
        </defs>
        <g id="Group_8768" data-name="Group 8768" transform="translate(0 0)">
            <path id="Union_1" data-name="Union 1" d="M34.092,70.213a3.247,3.247,0,1,1,3.247,3.247A3.247,3.247,0,0,1,34.092,70.213Zm15.074-10.35H27.542a3.246,3.246,0,1,1-.1-1.623H49.271a3.247,3.247,0,1,1-.1,1.623Zm-19.019-13.6H16.965a4.059,4.059,0,1,1,0-1.623H30.046a6.494,6.494,0,0,1,12.963,0h14.7a4.06,4.06,0,0,1,7.955,0h13.15a4.058,4.058,0,1,1,0,1.623H65.668a4.06,4.06,0,0,1-7.955,0H42.907a6.5,6.5,0,0,1-12.76,0Zm-10.6-15.625H6.332a3.247,3.247,0,1,1,.1-1.623H19.506a7.306,7.306,0,0,1,14.561,0H47.144a5.074,5.074,0,0,1,10.017,0h9.456a3.248,3.248,0,0,1,6.38,0H93.81a3.246,3.246,0,1,1,.1,1.623H72.893a3.248,3.248,0,0,1-6.171,0H57.161a5.074,5.074,0,0,1-10.017,0H34.022a7.306,7.306,0,0,1-14.471,0Zm58.1-13.393H46.14a4.06,4.06,0,0,1-7.861,0H19.353a4.06,4.06,0,1,1,.083-1.624H38.2a4.059,4.059,0,0,1,8.026,0H77.564a4.057,4.057,0,1,1,.083,1.624ZM61.818,5.073H38.835a4.059,4.059,0,1,1,.082-1.623H61.736a4.057,4.057,0,1,1,.082,1.623Z" transform="translate(0 0)" 
            fill="url(#linear-gradient2)"/>
        </g>
        <path id="Path_5941" data-name="Path 5941" d="M48.3,11.488H42.94L34.467,32.528l-.807,2.652-.692-2.594-8.416-21.1h-5.3L31.123,40.194l-5.361,12.22h5.361Zm17.408-.98A15.448,15.448,0,1,0,81.157,25.957,15.412,15.412,0,0,0,65.709,10.509Zm0,4.5A10.953,10.953,0,1,1,54.929,25.9,10.873,10.873,0,0,1,65.709,15Zm44.5-3.516V29.934c-1.556,3.228-6.341,6.8-10.549,6.8-5.13,0-7.782-3.689-7.782-8.762V11.488H87.267V28.551c0,7.205,4.15,12.566,11.355,12.566a14.423,14.423,0,0,0,11.7-6.283V40.31h4.5V11.488Zm29.282-.807a14.423,14.423,0,0,0-11.7,6.283V11.488h-4.5V40.31h4.669V21.864c1.5-3.228,6.283-6.8,10.548-6.8,5.073,0,7.724,3.689,7.724,8.762V40.31H150.9V23.247C150.9,16.042,146.7,10.681,139.49,10.681Zm48.073,14.756a14.819,14.819,0,0,0-15.217-14.929A15.449,15.449,0,0,0,156.9,25.957c0,8.589,6.514,15.448,16.082,15.448a19.136,19.136,0,0,0,11.355-3.631l-.98-4.669a17.607,17.607,0,0,1-10.376,3.92c-6.052,0-10.376-3.747-11.24-9.05h25.593A15.266,15.266,0,0,0,187.564,25.438ZM172.346,14.832c5.88,0,9.684,3.8,10.433,9.28h-21.1A10.619,10.619,0,0,1,172.346,14.832Zm37.583,20.29a12.191,12.191,0,0,1-5.649,1.326,4.346,4.346,0,0,1-4.727-4.669V15.812h10.721V11.488H199.553v-8.3h-4.611v8.3H189.7v4.323h5.245V31.721c0,5.937,3.4,9.223,9.107,9.223a17.882,17.882,0,0,0,6.744-1.441Z" transform="translate(89.206 4.329)" fill="#454545"/>
        </svg>
    );
}

export default LogoSvgBig;