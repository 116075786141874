import BasicHeader from 'components/mui/containers/basicHeader';
import React from 'react';

const Header=()=> {
    return (
        <div>
            <BasicHeader title='Welcome to Younet!' article='At Younet, we believe in the power of AI to transform businesses and enhance productivity. As a leading AI platform, we are constantly seeking talented individuals who are passionate about technology and innovation to join our team.'
             picsrc='/images/career/career-header.png'/>
        </div>
    );
}

export default Header;