import React,{useState}  from 'react';
import { Grid,BottomNavigation, BottomNavigationAction, Avatar } from '@mui/material';
import { HistoryEdu, Explore, BusinessCenter, Person, SpeakerNotes, EmojiObjects, AccountBalance, School } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import {AskingTypingEffect,AnsweringTypingEffect} from 'components/mui/TypingEffect';

const TypingBottomNavi = styled(BottomNavigation)(({theme})=>({
  display:"flex",
  width:"100%",
  height:"auto",
  minHeight:'60px',
  flexWrap: "wrap",
  backgroundColor:'rgba(255,255,255,0)'
}));
const OverviewFeatures=()=> {
    const [selectedTag, setSelectedTag] = useState(0);
    const [askingTextComplete, setAskingTextComplete] = useState(false);
    const tagContent = [
          {
            id: 0,
            label: 'Writing',
            icon: <HistoryEdu />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to AI model',
            sentence1: 'Write an article about our cellphone plans we offer and their benefits',
            sentence2: 'Title: The Perfect Fit Cellphone Plans For Your Business Needs\n\nArticle: In today‘s fast-paced world, staying connected is more important than ever, especially for businesses. We understand the significance of reliable and cost-effective communication solutions. That’s why we offer a range of comprehensive cellphone plans designed to meet the diverse needs of businesses of all sizes.<…>',
          },
          {
            id: 1,
            label: 'Sales',
            icon: <BusinessCenter />,
            avatar1: 'C',
            avatar2: '/images/btn.png',
            role:'Client',
            sentence1: 'Could you recommend a suitable cellphone plan for our company? We have 6 employees, and 4 of them frequently travel. ',
            sentence2: 'I can definitely help you with that. Based on your company size and needs, I can offer you the following cellphone plans: \n1) 2 corporate Business Standard plans that cost $49.99/month. \n2) 4 corporate Business Travel plans that cost $89.99/month. The total monthly cost for all 6 plans would be $459.94/month.\nIn order to secure this offer, please register using this link.'
          },
          {
            id: 2,
            label: 'Advice',
            icon: <SpeakerNotes />,
            avatar1: 'C',
            avatar2: '/images/btn.png',
            role:'Client',
            sentence1: 'Could you provide guidance on the pricing of international calls from the US to Canada using our Business Standard plan?',
            sentence2: 'Thanks for reaching out. Happy to help.\nAccording to Business Standard plan pricing, the cost per minute would be $0.05 when calling from the USA to Canada.\nLet me know if you have any additional questions.',
          },
          {
            id: 3,
            label: 'Service',
            icon: <Person />,
            avatar1: 'C',
            avatar2: '/images/btn.png',
            role:'Client',
            sentence1: 'I’m writing to you regarding the bill I received this month. It seems to be much higher than usual, and I‘m not sure why.',
            sentence2: 'Thank you for reaching out to us. We would be happy to help you understand the charges on your bill. Could you please provide us with your account number and the specific charges that you are questioning?',
          },{
            id: 4,
            label: 'Legal',
            icon: <AccountBalance />,
            avatar1: 'C',
            avatar2: '/images/btn.png',
            role:'Client',
            sentence1: 'I request legal representation for the recent purchase of my house. I would like to ensure that all legal requirements are met.',
            sentence2: 'Thank you for reaching out to our firm for legal representation. We are pleased to offer our services for the purchase of your house.\nTo proceed, please fill out the attached representation request form and return it to us at your earliest convenience. This form will provide us with the necessary information to begin representing you in the purchase process. We look forward to working with you.',
          },
          {
            id: 5,
            label: 'Ideas',
            icon: <EmojiObjects />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to AI model',
            sentence1: 'Can you create an email letter to a company based on services we provide.',
            sentence2: 'We hope this email finds you well. We wanted to inform you about the various cellphone plans we offer to businesses like yours.\nOur plans are designed to cater to different business needs and budgets. We have a Business Startup plan for $49.99/month, a Business Travel plan for $89.99/month, and a Business Unlimited plan for $129.99/month.\nThank you for your time.',
          },
          {
            id: 6,
            label: 'Support',
            icon: <Explore />,
            avatar1: 'C',
            avatar2: '/images/btn.png',
            role:'Client',
            sentence1: 'I‘m having issues with my cellphone signal at home. I’ve noticed that the signal is very weak and sometimes I can‘t even make calls.',
            sentence2: 'Thank you for contacting us about this issue. I‘d be happy to help you troubleshoot your signal. Can you please tell me your phone number, location, and the type of phone you’re using?',
          },
          {
            id: 7,
            label: 'Education',
            icon: <School />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to AI model',
            sentence1: 'Based on my essay "Moonbase life", list the main points for survival during lunar nights.',
            sentence2: 'Based on your "Moonbase life" essay, here are the main points for survival during lunar nights:\n\n1. Power Management.\n2. Thermal Control.\n3. Life Support Systems.\n4. Lighting Solutions.\n<...>',
          },
    ]
    const handleTagChange = (event, newValue) => {
        setSelectedTag(newValue);
      };
    
    const tagContentObj = tagContent.find((item) => item.id === selectedTag);

    return (
        <Grid p={2}>
            <Grid container
            p={3} 
            sx={{border:'solid 3px var(--ynt-lightpurple)', borderRadius:'20px'}}
            >
              {/* the ask part */}
              <Grid container p={2} mb={3} sx={{backgroundColor:'var(--ynt-lightgrey)', borderRadius:'20px'}}>
              <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Avatar alt="User avatar" src={tagContentObj.avatar1} style={{ marginRight: '0.5rem' }}>{tagContentObj.avatar1}</Avatar>
                <h5>{tagContentObj.role}</h5>
                </Grid>
                <Grid item xs={12} p={2} sx={{height:{lg:'80px',xs:'110px'},minWidth:'100%'}}>
                  <p>{tagContentObj.sentence1}</p>
                </Grid>
              </Grid>
              {/* the response part */}
              <Grid container>
                <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Avatar sx={{ bgcolor: 'var(--ynt-primary)'}} alt="User avatar" src={tagContentObj.avatar2} style={{ marginRight: '0.5rem' }}/>
                <h5 style={{color:'var(--ynt-primary)'}}>Response</h5>
                </Grid>
                <Grid item xs={12} p={2} sx={{height:{lg:'220px',xs:'380px'},minWidth:'100%'}}>
                    <AnsweringTypingEffect text={tagContentObj.sentence2}/>
                </Grid>
              </Grid>
            </Grid>
            {/* the bottom navi bar part */}
            <Grid container>
                <Grid item xs={12} my={1}>
                <TypingBottomNavi value={selectedTag} onChange={handleTagChange} showLabels>
                {tagContent.map((item) => (
                    <BottomNavigationAction sx={{py:1}} key={item.id} value={item.id} label={item.label} icon={item.icon} onClick={()=>setAskingTextComplete(false)}/>
                ))}
                </TypingBottomNavi>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default OverviewFeatures;