import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Box } from '@mui/material';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ChromeExtensionVideo from './ChromeExtensionVideos';

const ChromeExtensionArticle=()=> {

    return (
        <WideContainer>
            <Grid container className='chrome-extension-header-container'>
            <ChromeExtensionVideo/>
            <Box mx={4}>
                <GetStartedCard/>
            </Box>
            </Grid>
        </WideContainer>
    );
}

export default ChromeExtensionArticle;