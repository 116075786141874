import React from "react";
import 'assets/styles/base.css';
import { Grid } from '@mui/material';
import { NarrowContainer } from 'components/mui/containers/PxftContainer';

const Info = (status) => {
  let msg = '';
  switch (status.status){
    case 404:
      msg = 'The page you are trying to access does not exist';
    break;
    case 500:
      msg = 'The server encountered an unexpected condition that prevented it from fulfilling the request.';
    break;
    default:
      msg = '';
    break;
  }
  return (
    <>
        <div id="home-page" className="info-container center-align">
          <NarrowContainer className="center-align">
            <Grid item xs={12} md={6}>
              <img loading='lazy' className='contentPic' src='/images/not-found.png' alt=''/>
            </Grid>
            <Grid xs={12} md={6}>
            <h2 style={{color:'black'}}>Info Page</h2>
            <br/>
            <p>{msg}</p>
            </Grid>
          </NarrowContainer >
        </div>
    </>
  )
};

export default Info;
