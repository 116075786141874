import Engineering from 'components/solution/engineering/Engineering';
import React,{ useEffect} from 'react';

const SolutionEngineering = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)}
    )

    return (
        <div>
            <Engineering/>
        </div>
    );
}

export default SolutionEngineering;