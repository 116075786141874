import React from 'react';
import 'assets/styles/navbar.css';
import { WideContainer} from 'components/mui/containers/PxftContainer';
import { Button, Grid, Link } from '@mui/material';
import LogoSvg from 'components/logoSvg/logoSvg';
import HeaderNavigation from 'components/HeaderNavigation/HeaderNavigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';


const NavigationBar = () => {

const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('md'));
const navigate = useNavigate();

const WindowMenu= () =>{
return(
  <Grid container className='nav-navi-contents'>
    <Grid item md={9} xs={12} className='center-align'>
      <Grid container justifyContent={{md:'left',xs:'space-between'}} className='center-align'>
        <Grid item pr={4} display='flex' alignItems='center' justifyContent='left' className='center-align' onClick={()=>{navigate("/")}}>
          <LogoSvg/>
        </Grid >
        <HeaderNavigation/>
      </Grid>  
    </Grid>
    <Grid item md={3} xs={0} className='center-align' style={{justifyContent:'flex-end'}}>
    {!isMobile &&
    <div style={{display:'flex'}}>
      <Link href='https://younet.ai/portal/login' rel="noreferrer" className='navibtn'><Button sx={{color:'var(--ynt-primary)',width:100, height:50,mx:2}}>Sign in</Button></Link>
      <Link href='https://younet.ai/portal/sign-up' rel="noreferrer" className='navibtn'><Button variant="outlined" sx={{color:'var(--ynt-primary)',width:160, height:50,border:'2px solid'}}>Get Started</Button></Link>
    </div>
    }
    </Grid>
    </Grid>
);
}

    return(
      <div>
        <WideContainer className='nav'>
          <div className='center-align'>
              {WindowMenu()}
          </div>
        </WideContainer>
      </div>
    );

}
export default NavigationBar;