import React,{useRef} from 'react';
import styled from '@emotion/styled'
import ShareIcon from '@mui/icons-material/Share';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FacebookButton from './facebookButton';
import TwitterButton from './twitterButton';
import LinkedinButton from './linkedinButton';

const ShareBtn = styled.button`
        background-color: var(--ynt-mildgrey);
        border-radius: 5px;
        border: 0;
        color: var(--ynt-darkgrey);
        font-size: var(--ynt-font-normal);

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms ease;
        cursor: pointer;

        &:hover {
            background-color: var(--ynt-lightgrey);
            border: 2px solid var(--ynt-mildgrey);
        }
        & .active {
            backgroundColor: var(--ynt-mildgrey);
        }
        & .focusVisible {
            boxShadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
            outline: none;
        }
        & .disabled {
            opacity: 0.5;
            cursor: not-allowed;
 `;
const ShareButtonDetails =({width='40px', height='40px',url='',title=''})=> {
    const twitterRef = useRef(null);
    const facebookRef = useRef(null);
    const linkedinRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <ShareBtn style={{'height':height,'width':width}}
            id="share-button"
            aria-controls={open ? 'share-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}>
                <ShareIcon/>
            </ShareBtn>
            <Menu
                id="share-menu"
                aria-labelledby="share-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{width:'72px'}}
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
                }}>
                <MenuItem onClick={()=>{twitterRef.current.openDialog();setAnchorEl(null);}} sx={{display:'flex',justifyContent:'center'}}>
                    <TwitterButton url={url} quote={title} ref={twitterRef}/>
                </MenuItem>
                <MenuItem onClick={()=>{facebookRef.current.openDialog();setAnchorEl(null);}}sx={{display:'flex',justifyContent:'center'}}>
                    <FacebookButton url={url} quote={title} hashtag="#Younet" ref={facebookRef}/>
                </MenuItem>
                <MenuItem onClick={()=>{linkedinRef.current.openDialog();setAnchorEl(null);}} sx={{display:'flex',justifyContent:'center'}}>
                    <LinkedinButton url={url} ref={linkedinRef}/>
                </MenuItem>
            </Menu>
        </div>

    );
}

export default ShareButtonDetails;