import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid } from '@mui/material';
import BasicRowA from 'components/mui/containers/basicRowA';
import BasicRowB from 'components/mui/containers/basicRowB';
import BusinessFeatures from './businessFeatures';
import GetStartedCard from 'components/mui/cards/getStartedCard';

const BusinessArticle=()=> {
    const rows=[
        {
            title:'Personalized business assistant',
            content:'Create a custom AI model with knowledge of your expertise. With the help of our intuitive algorithms, you may convert knowledge to digital form in AI to help you with intellectual tasks.',
            src:'/images/business/feature-1.png'
        },
        {
            title:'AI that truly knows your business',
            content:'Safely automate your intellectual daily tasks. Everything you know becomes accessible by communicating with your personalized AI model.',
            src:'/images/home/feature-1.png'
        },
        {
            title:'Train AI to mimic your style of writing',
            content:'By connecting your Gmail/Outlook conversations to your AI model, you can train it to write the same style as you. It will also learn what you do and how you communicate allowing it to gain more knowledge about your business.',
            src:'/images/business/feature-2.png'
        }
    ]
    return (
        <div className='business-article-container'>
            <WideContainer>
                <div className='business-feature-column column-container center-align'>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowA title={rows[0].title} contents={rows[0].content} picSrc={rows[0].src} button={false} long={false}/>
                    </Grid>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowB title={rows[1].title} contents={rows[1].content} picSrc={rows[1].src} button={false} long={false}/>
                    </Grid>
                    <Grid container sx={{py:{md:3}}} className='center-align'>
                        <BasicRowA title={rows[2].title} contents={rows[2].content} picSrc={rows[2].src} button={false} long={false}/>
                    </Grid>
                </div>
            </WideContainer>
            <WideContainer>
                <BusinessFeatures/>
            </WideContainer>
            <WideContainer>
                <GetStartedCard/>
            </WideContainer>
        </div>
    );
}

export default BusinessArticle;