import { Button } from "@mui/base";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import FacebookIcon from '@mui/icons-material/Facebook';
import { API_PATHS } from "../../../../constants/apiPaths";

const FacebookAuthBtn = ({
  redirectUrl,
  text = "",
  fullWidth = false,
  facebookOptions = {},
}) => {
  const [url, setUrl] = useState(null);

  const Btn = styled.a`
    background-color: #3b5998;
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px !important;
    font-size:14px;
    font-weight:500;

    & > svg {
      margin-right: 5px;

    }

    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed; pointer-events: none;" : ""}
  `;

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        redirectUrl: redirectUrl,
      };

      try {
        const response = await fetch(API_PATHS.facebookAuth(), {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.status == 418) {
          setUrl("/portal");
          return;
        }

        if (response.code >= 400) {
          throw Error("Something went wrong..");
        }

        const data = await response.json();
        let authUrl = data.authUrl || "";

        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    fetchData();
  }, []);

  return (
    <Btn
      variant="contained"
      color="primary"
      href={url}

      disabled={!Boolean(url)}
    >
      <FacebookIcon /> {text}
    </Btn>
  );
};

export default FacebookAuthBtn;
