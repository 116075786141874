import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { API_PATHS } from "../../../../constants/apiPaths";
import Button from "@mui/material/Button";
import useDeviceDetect from "hooks/useDeviceDetect";
import useCopyToDevice from "hooks/useCopyToDevice";

const GoogleAuthBtn = ({
  redirectUrl,
  text = "",
  fullWidth = false,
  googleOptions = {},
}) => {

  const { isInAppView } = useDeviceDetect();
  const { message, copy } = useCopyToDevice();
  const [url, setUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  

  const Icon = styled.i`
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 0;
    width: 25px;
    height: 20px;
  `;

  const Btn = styled.a`
    justify-content: center;
    display: flex;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 8px 10px !important;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    color: #757575;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
    }

    &:active {
      background-color: #eeeeee;
    }

    &:focus {
      outline: none;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
        0 0 0 3px #c8dafc;
    }

    ${props => props.disabled ? "opacity: 0.5; cursor: not-allowed; pointer-events: none;" :""}
  `;

  const handleButtonWarning = (e) => {
    // Prevent default behaviour if inAppBrowser is true
    if (isInAppView) {
      e.preventDefault();
      // Show the user the alert message: "Unable to open, please copy link to phone browser to proceed." with the option to copy the link to the clipboard
      setShowModal(true);
    }
  };

  const handleCopyClick = () => {
    copy(window.location.href);
  };

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        redirectUrl: redirectUrl,
      };


      try {
        let response;
        if (googleOptions.method === "get") {
          response = await fetch(API_PATHS.googleAuth(googleOptions.url));
        } else if (googleOptions.method === "post") {
          response = await fetch(API_PATHS.googleAuth(googleOptions.url),
            {
              method: "POST",
              body: JSON.stringify(params),
              headers: {
                "Content-Type": "application/json",
              },
            });
        } else {
          return;
        }

        if(response.status == 418) {
          setUrl("/portal");
          return;
        }

        if (response.code >= 400 ) {
          throw Error("Something went wrong..");
        }

        const data = await response.json();
        const authUrl = data.authUrl || "";

        setUrl(authUrl);
      } catch (error) {
        console.error(error); // Handle any errors
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <Btn
        variant="contained"
        color="primary"
        href={isInAppView ? undefined : url}
        onClick={handleButtonWarning}
        disabled={!Boolean(url)}
      >
        <Icon /> {text}
      </Btn>
      
      {isInAppView &&
        <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <DialogTitle>Unable to open</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please copy the link to the phone browser to proceed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowModal(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCopyClick} color="primary">
              {message}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

export default GoogleAuthBtn;
