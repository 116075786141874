import React,{useEffect} from 'react';
import HomeArticle from 'components/home/HomeArticle';
import 'assets/styles/home.css'

const Home=()=>{
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <HomeArticle/>
        </div>
    );
}

export default Home;