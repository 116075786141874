import AIBotArticle from 'components/products/AIBotArticle';
import AIBotHeader from 'components/products/AIBotHeader';
import React,{useEffect} from 'react';
import 'components/products/products.css'

const AIBot=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })

    return (
        <div>
            <AIBotHeader/>
            <AIBotArticle/>
        </div>
    );
}

export default AIBot;