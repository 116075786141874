import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import {Grid,Box,Grow,Slide,Link} from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useInView } from 'react-intersection-observer'
import BasicButton from 'components/mui/buttons/basicButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const BusinessFeatures=()=> {
    const [inViewRef1, inView1] = useInView({
        triggerOnce: true,
    });
    const [inViewRef2, inView2] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
            <>
                <div className='business-features-container center-align' style={{overflow:'hidden',flexDirection:'column'}}>
                    <NarrowContainer className='business-feature-column'>
                        <Grid container sx={{py:{md:3}}}>
                        <Slide timeout={{enter:800}} in={inView1} direction="right">
                            <Grid item xs={12} sm={12} md={6} sx={{py:6,px:6}} className='center-align'>
                                <div className='business-feature-column-des'>
                                <h1>AI agent just like your best employee</h1>
                                <p>- Analyze your business with AI;</p>
                                <p>- Generate responses to clients' questions;</p>
                                <p>- Train your AI agent to know your business just like you;</p>
                                <p>- Create articles, letters, emails, agreements, presentations, and more.</p>
                                </div>
                            </Grid>
                        </Slide>
                        <Grow timeout={{enter:1200}} in={inView1}>
                            <Grid item xs={12} sm={12} md={6} className='center-align'>
                                <Carousel
                                    NextIcon={<ChevronRightIcon/>}
                                    PrevIcon={<ChevronLeftIcon/>}
                                    sx={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <div className='center-align'>
                                            <img loading='lazy' style={{width:'90%'}} src='/images/business/business1.png' alt=''/>
                                        </div>
                                        <div className='center-align'>
                                            <img loading='lazy' style={{width:'90%'}} src='/images/business/business2.png' alt=''/>
                                        </div>
                                        <div className='center-align'>
                                            <img loading='lazy' style={{width:'90%'}} src='/images/business/business3.png' alt=''/>
                                        </div>
                                        <div className='center-align'>
                                            <img loading='lazy' style={{width:'90%'}} src='/images/business/business4.png' alt=''/>
                                        </div>
                                </Carousel>
                            </Grid>
                        </Grow>
                        </Grid>
                        <div className='observer' ref={inViewRef1}></div>
                    </NarrowContainer>
                    <NarrowContainer className='business-feature-column'>
                        <Grid container className='mobile-reverse' sx={{py:{md:3}}}>
                        <Grow timeout={{enter:1200}} in={inView2}>
                            <Grid item xs={12} sm={12} md={6} className='center-align'>
                                <div className='center-align'>
                                    <img loading='lazy' className='contentPic' src='/images/business/business3-1.png' alt=''/>
                                </div>
                            </Grid>
                        </Grow>
                        <Slide timeout={{enter:800}} in={inView2} direction="left">
                            <Grid item xs={12} sm={12} md={6} sx={{py:{md:6},px:6}} className='center-align'>
                                <div className='business-feature-column-des'>
                                <h1>Use your AI agent anywhere</h1>
                                <p>Add the Younet extension and simplify your response across all platforms, including CRM, email, help desk, social media, online forms, websites, and more.</p>
                                <Grid my={2}>
                                    <Box display='flex' flexDirection={{xs:'column',md:'row'}} alignItems={{xs:'center',md:'start'}}>
                                    <Link py={1} href='https://chrome.google.com/webstore/detail/younet-personalized-ai-to/ahabdciobjaamioehoaifaoabkimhegj' 
                                    target='_blank' rel='noreferrer' underline="none">
                                        <BasicButton width='200px' content='Add to Chrome, it‘s free'/>
                                    </Link>
                                    <Link href='/chrome-extension' underline="none" py={2.5} px={{md:3}} sx={{color:'var(--ynt-primary)',fontSize:'14px',fontWeight:400}}>
                                    Learn more...
                                    </Link>
                                    </Box>
                                    <Box my={2}>
                                    {isMobile? <img src='/images/home/extension-support-mobile.png' alt='extension-logos'/>:<img src='/images/home/extension-support.png' alt='extension-logos'/>}
                                    </Box>
                                </Grid>
                                </div>
                            </Grid>
                        </Slide>
                        </Grid>
                        <div className='observer' ref={inViewRef2}></div>
                    </NarrowContainer>
            </div>
        </>
    );
}

export default BusinessFeatures;