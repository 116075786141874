import React from 'react';
import {Grid,Paper,Divider,Button,Link} from '@mui/material';
import GoogleAuthBtn from 'components/home/auth/GoogleAuthBtn';
import FacebookAuthBtn from 'components/home/auth/FacebookAuthBtn';
import useDeviceDetect from 'hooks/useDeviceDetect';
import G2Button from 'components/mui/buttons/G2Button';
import HighPerformanceBadgeButton from 'components/mui/buttons/HighPerformanceBadgeButton';

const LandingLogin =()=> {
    const googleOptions = {
        method: 'post',
        url: 'google/oauth2/url',
      };
    const {isInAppView} = useDeviceDetect();
    return (
      <Paper elevation={3} sx={{ padding: {xs:'15px',lg:'30px'}, maxWidth: "600px", margin: "0 auto" }}>
        <Grid container className='center-align'>
          <Grid display='flex' alignItems='center'>
            <G2Button/>
            <HighPerformanceBadgeButton/>
          </Grid>

            {!isInAppView && (
            <Grid sx={{ mt: 2, mb: 0, padding: 0 }} item xs={12}>
              <GoogleAuthBtn
                googleOptions={googleOptions}
                redirectUrl={`${window.location.origin}/portal/google/login`}
                text="Sign in with Google"
                fullWidth
              />
            </Grid>)}
            <Grid sx={{ mt: 2, mb: 2, padding: 0 }} item xs={12}>
              <FacebookAuthBtn
                redirectUrl={`${window.location.origin}/portal/facebook/login/`}
                text="Sign in with Facebook"
              />
            </Grid>
            <Grid item xs={12}>
                <Divider>or</Divider>
            </Grid>
            <Grid item sx={{ mt: 2, mb: 2, padding: 0 }} xs={12} className='center-align'>
              <Link href="https://younet.ai/portal/sign-up" variant="body2">Continue with your email</Link>
            </Grid>
        </Grid>
      </Paper>
    );
}

export default LandingLogin;