import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Box } from '@mui/material';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ContentRowA=({title='',contents='',picSrc='',picSrc2x='',videoSrc=''})=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const containerRef = React.useRef(null);
    return (
            <WideContainer>
                <div className='center-align products-column-background-cover'>
                    <NarrowContainer className='mobile-reverse'sx={{alignItems:'center'}}>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} md={7} className='center-align'>
                            {videoSrc?
                            <div className='video-container center-align'>
                                {isMobile ? <iframe width="332" height="249" src={videoSrc} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                    : <iframe width="576" height="432" src={videoSrc} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                                </div>
                            :
                            <img loading='lazy' className='contentPic' src={picSrc2x}
                            // srcset={picSrc+' 1x, '+picSrc2x+' 2x'}
                            // sizes="(max-width: 599px) 1x, (min-width: 600px) 2x"
                            alt=''/>
                            }
                        </Grid>
                    </Grow>
                    <Slide timeout={{enter:800}} in={inView} direction="left" container={containerRef.current}>
                        <Grid item xs={12} md={5} flexDirection='column' className='center-align'>
                            <Box p={2}>
                                <Box py={{xs:1,lg:3}}><h1>{title}</h1></Box>
                                <Box py={{xs:1,lg:2}}><p>{contents}</p></Box>
                            </Box>
                        </Grid>
                    </Slide>
                    </NarrowContainer>
                    <div className='observer' ref={inViewRef}></div>
                </div>
            </WideContainer> 
    );
}

export default ContentRowA;