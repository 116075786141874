import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useDeviceDetect from "hooks/useDeviceDetect";
import { useRef } from "react";
import { TypeAnimation } from 'react-type-animation';

const ANIMATION_DURATION = 1800;
const DISSAPEAR_ANIMATION_DURATION = 500;
const GO_DOWN_ANIMATION_CLASS_NAME = 'go-down-and-disappear';
const GO_LEFT_ANIMATION_CLASS_NAME = 'go-left-and-disappear';

const TextAnimation = () => {

    const textEl = useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const ANIMATION_CLASS_NAME = isMobile ? GO_LEFT_ANIMATION_CLASS_NAME : GO_DOWN_ANIMATION_CLASS_NAME;

    const toggleClass = (className, add) => {
        if (textEl.current) {
            const element = textEl.current;
            const isActive = element.classList.contains(className);

            if (add && !isActive) {
                element.classList.add(className);
            } else if (!add && isActive) {
                element.classList.remove(className);
            }
        }
    };

    const disappearAnimation = () => {
        toggleClass(ANIMATION_CLASS_NAME, true);
    };

    const cancelDisappearAnimation = () => {
        toggleClass(ANIMATION_CLASS_NAME, false);
    };

    const breakAnimation = [
        ANIMATION_DURATION,
        disappearAnimation,
        DISSAPEAR_ANIMATION_DURATION,
        cancelDisappearAnimation,
    ]

    const phrases = [
        "Write an email to introduce our new products and services.",
       ...breakAnimation,
        "Respond to an email with additional information about our company and services.",
       ...breakAnimation,
        "What is the total sales figure for the year 2023?",
       ...breakAnimation,
        "Analyze a CSV file containing sales data and forecast sales for Q4.",
       ...breakAnimation,
        "Compose an article about our new products and services.",
       ...breakAnimation,
        "Write a blog post about new products and services we introduced.",
       ...breakAnimation,
        "Create a LinkedIn post that highlights new features.",
       ...breakAnimation,
        "Generate some ideas for a social media post about our products.",
       ...breakAnimation,
        "Draft an employment letter based on the employee agreement.",
       ...breakAnimation,
        "Prepare employee onboarding instructions in accordance with our policy.",
       ...breakAnimation,
        "Provide documentation on how to utilize our new features.",
       ...breakAnimation,
        "Compose a knowledge base article about new features.",
       ...breakAnimation,
        "Based on a weight loss program, list the best nutrition options for me.",
       ...breakAnimation,
        "Create an appealing menu using knowledge of healthy food.",
       ...breakAnimation,
        "Debug my code and suggest ways to enhance it.",
       ...breakAnimation,
        "Identify potential security issues with the profile registration code.",
       ...breakAnimation,
        "Write a description for a YouTube video.",
       ...breakAnimation,
        "Respond to a comment on a YouTube video about our new feature.",
       ...breakAnimation,
        "Create key points for a weekly meeting with my team based on last week's performance.",
       ...breakAnimation,
        "Develop presentation topics based on our year-end results.",
       ...breakAnimation,
        "Brainstorm business ideas based on our sales performance for the year.",
       ...breakAnimation,
        "Assist me in developing a marketing strategy to boost sales.",
        ...breakAnimation
    ];

    return (
        <h2 className="title">
            <TypeAnimation
                ref={textEl}
                className="type-animation-span"
                sequence={phrases}
                speed={70}
                omitDeletionAnimation
                repeat={Infinity}
                cursor={false}
            />
        </h2>
    )

}


export default TextAnimation;