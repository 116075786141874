import React from 'react';
import { Grid,Box,Grow,Slide } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { useInView } from 'react-intersection-observer'

const AIBotHeader=()=> {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    return (
        <div className='basic-header-container ai-bot-background-cover'>
            <WideContainer>
            <Slide timeout={{ enter: 1200 }} in={inView} direction="down" >
            <Grid py={4}>
                <Box className='center-align ai-header-title' py={4}>
                    <h1>AI-Enabled Website Chatbot</h1>
                </Box>
                <Grid className='center-align ai-header-subtitle' flexDirection='column'>
                    <p>
                    Your Own Generative AI Private Large Language Model. 
                    </p>
                    <p>
                    Designed and launched in <span>minutes without coding</span>, based on your resources.
                    </p>
                </Grid>
            </Grid>
            </Slide>
            <Grow timeout={{ enter: 1800 }} in={inView} className='center-align'>
            <Grid className='center-align' pt={6}>
                <Box maxWidth='800px'>
                    <img loading='lazy' width='100%' src='/images/products/aibot/header-pic.png' 
                    srcSet='/images/products/aibot/header-pic.png 1x, /images/products/aibot/header-pic@2x.png 2x'
                    alt=''/>
                </Box>
                <div className='long-observer' ref={inViewRef}></div>
            </Grid>
            </Grow>
            </WideContainer>
        </div>
    );
}

export default AIBotHeader;