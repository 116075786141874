import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Grow,Slide,Button,Link} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import G2Button from 'components/mui/buttons/G2Button';
import HighPerformanceBadgeButton from 'components/mui/buttons/HighPerformanceBadgeButton';

const Header=()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    const containerStyle = {
        backgroundImage: `url('/images/home/background.png')`, 
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        padding:'50px 0'
    }
    const header = {
        title:'Boost Your Business with AI',
        article:'Scalable artificial intelligence solution that delivers game-changing results for your team. Seamless integration in any business via an intuitive user interface.',
        src:'/images/home/page-home-pic.png'
    }
    return (
        <div>
            <WideContainer className='basic-header-container '>
            <div className='center-align'>
                <Grid container className='header-content'>
                    <Slide timeout={{enter:1200}} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='center-align'>
                            <div className='overview-title'>
                                <h1>Boost Your</h1>
                                <h1>Business with AI</h1>
                                <div style={{padding:'10px 0'}}>
                                <p>{header.article}</p>
                                </div>
                                <Grid display='flex' flexDirection={isMobile?'column':'row'} alignItems='center'>
                                    <Link href='https://support.younet.ai/submit-ticket/2-Sales' pr={{xs:0,md:2}}><Button sx={{ margin: '10px 0', padding: '12px', width:'200px' }} variant='outlined'>Request A Demo</Button></Link>
                                    <Grid display='flex' alignItems='center'>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><G2Button/></Link>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><HighPerformanceBadgeButton/></Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{enter:1200}} in={inView}>
                        <Grid item xs={12} sm={12} md={6} lg={7} className='center-align'>
                            <div className='overview-header-pic center-align'>
                                  {isMobile? <iframe width="300" height="170" src="https://www.youtube.com/embed/TxqG38csx7U?&controls=0&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  :<iframe width="840" height="472.5" src="https://www.youtube.com/embed/TxqG38csx7U?&controls=0&hd=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                            </div>
                        </Grid>
                    </Grow>
                    <div className='observer' ref={inViewRef}></div>
                </Grid>
            </div>
            </WideContainer>
        </div>
    );
}

export default Header;