import React, {useEffect, useState} from 'react';
import {Box, Grid, Avatar, Button, TextField, MenuItem, Link} from '@mui/material';
import {styled} from '@mui/material/styles';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConstructionIcon from '@mui/icons-material/Construction';
import RocketIcon from '@mui/icons-material/Rocket';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import {getItem} from 'helpers/localStorageManagement';

const ContainerGrid = styled(Grid)`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 700px;
    padding: 20px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 15px;
    color: var(--ynt-midgrey);
    border: 1px solid var(--ynt-mildgrey);
    transition: 0.2s linear;

    ${({theme}) => theme.breakpoints.down('md')} {
        height: 700px;
        flex-wrap: nowrap;
    }

    & .pricing-badge {
        position: absolute;
        top: -12.5px;
        right: 35px;
        padding: 5px 10px;
        color: white;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        background: linear-gradient(227deg, rgba(133, 124, 245, 1) 0%, rgba(42, 123, 244, 1) 100%);
    }

    & .pricing-title {
        text-align: left;
        height: 100px;
    }

    & .pricing-title h4 {
        color: black;
        padding: 5px 0;
    }

    & .pricing-title .des {
        font-size: 12px;
        padding: 5px 0;
    }

    & .pricing-tag {
        position: relative;
        padding: 30px 0 0 0;
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    & .pricing-details {
        display: flex;
        flex-direction: row;
        padding: 10px 5px;
        align-items: center;
        color: black;
    }

    & .pricing-details p {
        padding: 0px 10px;
        text-align: left;
    }

    & .pro-select-box div {
        display: flex;
        flex-ridection: row;
        align-items: center
    }

    & hr {
        height: 1px;
        background-color: var(--ynt-mildgrey);
        border: none;
        margin: 0 0 20px;
    }

    & .supp-info {
        font-size: 12px;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }
    
    & .supp-info.billing {
        //padding-top: 0;
    }

    & .supp-info-container {
        min-height: 70px;
    }

    & .cta-block {
        width: 100%;
        min-height: 140px;
        padding-top: 40px;
    }

`

const PRO_ANNUAL_PLAN_IDS = [10, 11, 12, 13];

const pricingOptions = [
    {ids: [3, 10], value: 'pro-5k', label: 'Pro 5k', originalPrice: 39, discountedPrice: 30, credits: 5000},
    {ids: [4, 11], value: 'pro-20k', label: 'Pro 20k', originalPrice: 149, discountedPrice: 119, credits: 20000},
    {ids: [5, 12], value: 'pro-50k', label: 'Pro 50k', originalPrice: 369, discountedPrice: 295, credits: 50000},
    {ids: [6, 13], value: 'pro-100k', label: 'Pro 100k', originalPrice: 699, discountedPrice: 559, credits: 100000},

];

const handleRedirect = (id) => {
    window.location.href = `${window.origin}/portal/login?redirect=/billing/subscribe/${id}&newPlan=true`;
};


const PricingCard = () => {

    const userLocalStorageData = getItem('userData');
    const userData = userLocalStorageData ? JSON.parse(userLocalStorageData) : null;
    const planId = userData?.planId || 0;
    const checkForAnnual = userData ? PRO_ANNUAL_PLAN_IDS.includes(planId) : false;
    const [annualPlan, setAnnualPlan] = useState(checkForAnnual)
    const [proValue, setProValue] = useState('pro-5k');
    const selectedOption = pricingOptions.find((option) => option.value === proValue);

    useEffect(() => {

        const selectedProPlan = pricingOptions.find((option) => option.ids.includes(planId));
        if (selectedProPlan) {
            setProValue(selectedProPlan.value);
        }
    }, []);


    const PlanButton = ({selectedPlanId = 0, label = "GET STARTED"}) => {
        const isSelected = selectedPlanId == planId;
        return <Button onClick={() => {
            handleRedirect(selectedPlanId)
        }} variant={isSelected ? "contained" : "outlined"} fullWidth>{isSelected ? "SELECTED" : label}</Button>
    }

    return (
        <>
            <Grid pt={2} className='prescription-btns center-align'>
                <Box sx={{backgroundColor: 'var(--ynt-mildgrey)', borderRadius: '5px', padding: '3px'}}>
                    <Button className={annualPlan ? 'selected' : ''} onClick={() => {
                        setAnnualPlan(true)
                    }}>Annual <span className='annual-badge'>-20%</span></Button>
                    <Button className={annualPlan ? '' : 'selected'} onClick={() => {
                        setAnnualPlan(false)
                    }}>Monthly</Button>
                </Box>
            </Grid>
            <Grid container py={4}>
                <Grid item xs={12} md={3} p={2}>
                    <ContainerGrid container >
                        <Grid item sx={{width: '100%', alignItems: 'left'}}>
                            <Avatar sx={{bgcolor: 'var(--ynt-primary)'}}><ConstructionIcon/></Avatar>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-title'>
                            <h4>Trial</h4>
                            <p className='des'>Ideal to try Younet to build 1 AI model.</p>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-tag'>
                            <h2>$0</h2><span>/mon</span>
                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>
                        <Grid item sx={{width: '100%'}}>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>200 credits</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>1 AI Model</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Limited daily use</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Limited data entry</p>
                            </div>
                        </Grid>
                        <Grid item sx={{width: '100%', minHeight: '138px',paddingTop: '33px'}} >
                            <PlanButton selectedPlanId={1} label={"Join for free"}/>

                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>
                    </ContainerGrid>
                </Grid>

                <Grid item xs={12} md={3} p={2}>
                    <ContainerGrid container>
                        <Grid item sx={{width: '100%', alignItems: 'left'}}>
                            <Avatar sx={{bgcolor: 'var(--ynt-primary)'}}><RocketIcon/></Avatar>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-title'>
                            <h4>Starter</h4>
                            <p className='des'>No limitations pack to help you start using Younet for your needs.</p>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-tag'>
                            {annualPlan ?
                                <><h2 className='delete-price'>$14.99</h2><h2>$9.99</h2></> : <h2>$14.99</h2>
                            }<span>/mon</span>
                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>
                        <Grid item sx={{width: '100%'}}>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>2,000 credits</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited AI Model</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited daily use</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited data entry</p>
                            </div>
                        </Grid>
                        <Grid className="cta-block">
                            <Grid item sx={{width: '100%'}}>
                                <PlanButton selectedPlanId={annualPlan ? 9 : 2}/>

                            </Grid>

                            <div className="supp-info-container">
                                <div className="supp-info">
                                    <span>*cancel anytime</span>
                                </div>

                                {annualPlan &&

                                    <div className="supp-info billing">
                                        <span>**Billed annually $119.88</span>
                                    </div>
                                }
                            </div>

                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>

                    </ContainerGrid>
                </Grid>

                <Grid item xs={12} md={3} p={2}>
                    <ContainerGrid container sx={{border: '1px solid var(--ynt-primary)'}}>
                        <div className='pricing-badge'>Most Popular</div>
                        <Grid item sx={{width: '100%', alignItems: 'left'}}>
                            <Avatar sx={{bgcolor: 'var(--ynt-primary)'}}><WorkspacePremiumIcon/></Avatar>
                        </Grid>
                        <Grid item className='pricing-title'>
                            <TextField
                                sx={{width: '100%', pt: 1}}
                                className='pro-select-box'
                                select
                                value={proValue}
                                defaultValue={proValue}
                                onChange={(e) => setProValue(e.target.value)}
                            >
                                {pricingOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value} sx={{flexDirection: 'row', cta: 3}}
                                              divider>
                                        <h4>{option.label}</h4>
                                        <span style={{
                                            margin: '0 10px',
                                            padding: '0 10px',
                                            borderRadius: '5px',
                                            border: '1px solid var(--ynt-primary)'
                                        }}>
                                            {option.credits}
                                        </span>
                                    </MenuItem>
                                ))}
                            </TextField>
                            <p className='des'>Pro choice for unlimited AI tasks and business needs.</p>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-tag'>
                            {annualPlan ? (
                                <>
                                    <h2 className='delete-price'>${selectedOption.originalPrice}</h2>
                                    <h2>${selectedOption.discountedPrice}</h2>
                                </>
                            ) : (
                                <h2>${selectedOption.originalPrice}</h2>
                            )}
                            <span>/mon</span>
                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>
                        <Grid item sx={{width: '100%'}}>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/>
                                <p style={{fontWeight: '600'}}>{selectedOption.credits} credits</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited AI Model</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited daily use</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Unlimited data entry</p>
                            </div>
                        </Grid>

                        <Grid className="cta-block">
                            <Grid item sx={{width: '100%'}}>
                                <PlanButton selectedPlanId={annualPlan ? 9 : 2}/>

                            </Grid>

                            <div className="supp-info-container">
                                <div className="supp-info">
                                    <span>*cancel anytime</span>
                                </div>

                                {annualPlan &&

                                    <div className="supp-info billing">
                                        <span>**Billed annually $360</span>
                                    </div>
                                }
                            </div>

                        </Grid>
                        <Grid item sx={{width: '100%'}} my={2}>
                            <hr/>
                        </Grid>
                    </ContainerGrid>
                </Grid>

                <Grid item xs={12} md={3} p={2}>
                    <ContainerGrid container>
                        <Grid item sx={{width: '100%', alignItems: 'left'}}>
                            <div style={{width: '40px', padding: '5px 0'}}><img src='./images/btn.png' alt='younet-logo'
                                                                                width='40px'/></div>
                        </Grid>
                        <Grid item sx={{width: '100%'}} className='pricing-title'>
                            <h4>Managed Service</h4>
                            <p className='des'>Younet professionals help to integrate AI into your business.</p>
                        </Grid>

                        {/* <Grid item sx={{ width: '100%' }} className='pricing-tag'>
                            <p style={{ position: 'absolute', top: '15px', fontSize: '10px' }}>Starting at </p>
                            <h2>$4,999</h2><span>/mon</span>
                        </Grid> */}
                        {/* <Grid item sx={{ width: '100%' }} my={1}>
                            <hr />
                        </Grid> */}
                        <Grid item sx={{width: '100%'}} py={2}>
                            <Link href='https://support.younet.ai/submit-ticket/2-Sales'>
                                <Button variant="outlined" fullWidth>Request a demo</Button>
                            </Link>
                        </Grid>
                        <Grid item sx={{width: '100%'}} my={1}>
                            <hr/>
                        </Grid>
                        <Grid item sx={{width: '100%'}}>
                            <div className='pricing-details'>
                                <h6>What's included</h6>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Custom Younet AI Integration</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Consulting</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Enterprise support</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Big data integration</p>
                            </div>
                            <div className='pricing-details'>
                                <TaskAltIcon fontSize='small'/><p>Professional training </p>
                            </div>
                        </Grid>
                    </ContainerGrid>
                </Grid>
            </Grid>
        </>

    );
}

export default PricingCard;