import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Marketing = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Create a perfect marketing strategy',
            contents:'Younet AI helps to make a perfect marketing strategy based on the uploaded knowledge about the company and core audience.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'Draft me the content plan for social networks for the next month.',
                answerContent:'Content Plan for Social Networks - Next Month\n\nWeek 1:\n - Monday: Post a video demonstration of <…>'
            },
        },
        {
            title:'Increase performance in social networks',
            contents:'Utilize AI to effortlessly craft captivating campaigns and strategic marketing plans that resonate with your social media audience. Elevate your brand with AI-powered marketing solutions.',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'Write a short post for Social networks featuring the latest update. Add hashtags.',
                answerContent:'📢 Exciting News! 🚀 With the latest update, we are bringing more power and customization to <…>'
            }
        },
        {
            title:'Draft SEO-optimized articles in minutes',
            contents:'Revolutionize content creation by utilizing AI technology to generate high-quality, search engine-friendly content based on your company information in record time.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'Draft a 3 sentence description of our service. Make it SEO-optimized.',
                answerContent:'Sure! Here is the 3-sentence SEO-optimized article:<…>'
            }
        },
        {
            title:'Seamless employee onboarding',
            contents:'No more lengthy training. New employees can hit the ground running from day one, as they have immediate access to an AI model well-versed in the company‘s product, audience, and communication style. ',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'Tell me about the main features of our product that are the most attractive to the audience.',
                answerContent:'Sure! Here‘s a brief overview of our features that you can share with the audience:<…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Sales',
            content:'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions.',
            link:'/solution-sales',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for Marketing Teams',
        'Empower Your',
        'Marketing Team with AI'
    ]
    const headerContent = 'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling, high-conversion campaigns and unforgettable content with unprecedented speed. Transform your digital marketing strategy with AI-driven insights and efficiency.'
    const tagContentObj =           
    {
      label: 'Sales',
      avatar1: 'P',
      avatar2: '/images/btn.png',
      role:'Prompt to Sales & Marketing',
      fileNames:[
        'Products & Services info.pdf',
        'Price-list.xlsx'
      ],
      sentence1: 'Write an email to David and introduce our new products and services.',
      sentence2: 'Subject: Discover What‘s New – Innovation Meets Affordability!\n\nDear David,\n\nWe’re excited to unveil our latest range of products and services designed to elevate your mobile experience, including cutting-edge smartphones at exclusive discounts and tailored < ... >',
    }
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Marketing;