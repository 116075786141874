import Legal from 'components/solution/legal/Legal';
import React,{ useEffect} from 'react';

const SolutionLegal = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)}
    )

    return (
        <div>
            <Legal/>
        </div>
    );
}

export default SolutionLegal;