import React from 'react';
import { Grid,Box,Avatar } from '@mui/material';

const ConversationCard = ({card})=> {
    return (
        <Grid container px={2} py={6}>
            <Grid item xs={12} sx={{position:'relative'}}>
                {
                card.askAvatar && <Avatar sx={{position:'absolute',top:'-20px',left:'3%'}} src={card.askAvatar}/>
                }
                <Box p={3} sx={{backgroundColor:'#F8F8F8',borderRadius:'15px',width:'70%',whiteSpace:'pre-wrap'}}>
                    {
                        card.askContent && <p>{card.askContent}</p>
                    }
                </Box>
            </Grid>
            <Grid item xs={12} sx={{position:'relative'}} my={4}>
                <Avatar sx={{position:'absolute',top:'-20px',right:'5%'}} src='/images/btn.png'/>
                <Box p={3} sx={{backgroundColor:'rgba(181,213,255, 0.2)',borderRadius:'15px',width:'85%',marginRight:'0',marginLeft:'auto',whiteSpace:'pre-wrap'}}>
                    {
                        card.answerContent && <p>{card.answerContent}</p>
                    }
                </Box>
            </Grid>
            
        </Grid>
    );
}

export default ConversationCard;