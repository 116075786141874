import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import UseCasesVideos from './UseCasesVideos';
import UseCasesSamples from './UseCasesSamples';

const UseCasesArticle = () => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <WideContainer>
            <Grid container className='center-align usecases-article-container'>
                <Grid item mt={6} xs={12} sm={12} md={12} lg={12} className='center-align'>
                    <div className='video-container center-align'>
                        {isMobile ? <iframe width="380" height="215" src="https://www.youtube.com/embed/DqIL_28fEe0?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=DqIL_28fEe0&hd=1" title="Younet presentation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                            : <iframe width="840" height="472.5" src="https://www.youtube.com/embed/DqIL_28fEe0?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=DqIL_28fEe0&hd=1" title="Younet presentation" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
                    </div>
                </Grid>
            </Grid>
            <Grid container className='center-align usecases-article-container'>
                <UseCasesVideos />
            </Grid>
            {/* <Grid container className='center-align usecases-article-container'>
                <UseCasesSamples />
            </Grid> */}
        </WideContainer>
    );
}

export default UseCasesArticle;