import PricingArticle from 'components/pricing/PricingArticle';
import React,{useEffect} from 'react';
import 'assets/styles/business.css'

const Pricing=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <PricingArticle/>
        </div>
    );
}

export default Pricing;