import React from 'react';
import styled from '@emotion/styled';
import { Grid, Rating, Typography } from '@mui/material';

const G2Btn = styled.button`
background: white;
border: 0px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

& .text-content{
    padding: 0px;
    font-size: 14px;
}
`
const G2Button = () => {
    return (
        <G2Btn>
            <img loading='lazy' src='/images/g2icon.png' height='45px' alt='g2-button'/>
            <Grid p={1}>
                <Rating name="read-only" value={5} readOnly/>
                <Typography className='text-content'><b>4.9/5 Stars</b> on G2</Typography>
            </Grid>
        </G2Btn>
    );
}

export default G2Button;