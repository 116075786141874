import React from 'react';
import { Link } from 'react-router-dom'
import {Grid} from '@mui/material';

const TermsAndConditions=()=> {
    return (
        <Grid item xs={12}>
        <div className="updated-time">Last Revised: Jun 1, 2023</div>
        <h3 className="policy-title">Terms of Service</h3>
        <h3>Introduction</h3>
            <p>Welcome to Younet, 2624465 Ontario Inc. ("Company", "we", "our", "us")! As you have accessed our Terms of Service, we kindly request that you take a moment to carefully review the following pages. </p>
            <p>These Terms of Service ("Terms", "Terms of Service") govern the utilization of our web pages located at <Link to="/"> https://younet.ai  </Link>, which are operated by Younet, 2624465 Ontario Inc. at 7191 Yonge St, Suite 812, Thornhill, ON, L3TOC4, Canada</p>
            <p>In addition, our Privacy Policy serves as a governing document for the use of our Service. It outlines the collection, safeguarding, and disclosure of information that results from your interaction with our web pages. To review the Privacy Policy, please visit: <Link to="/terms-and-conditions#privacy-policy"> https://younet.ai/terms-and-conditions#privacy-policy  </Link>.</p>
            <p>By continuing to use our web pages, you acknowledge that you have read and understood both the Terms of Service and the Privacy Policy ("Agreements"), and you agree to be legally bound by them.</p>
            <p>If you do not agree with, or are unable to comply with, the Agreements, then you are not permitted to use the Service. However, we encourage you to contact us at support@younet.ai to discuss any concerns and explore potential solutions. Please note that these Terms apply to all individuals, including visitors, users, and others who access or use our Service.</p>
            <p>We appreciate your commitment to responsible usage.</p>
        <h3>Communications</h3>
            <p>By creating an Account on our Service, you consent to receiving newsletters, marketing or promotional materials, and other informational communications from us. However, you have the option to unsubscribe from receiving any, or all, of these communications by utilizing the unsubscribe link provided or by emailing us at <a href="mailto:support@younet.ai"> support@younet.ai  </a>.</p>
        <h3>Purchases</h3>
            <p>If you intend to make a purchase of any product or service available through our Service ("Purchase"), you may be required to provide certain relevant information for your Purchase, including but not limited to your credit card number, credit card expiration date, billing address, and shipping information.</p>
            <p>By making a Purchase, you represent and warrant that: (i) you possess the legal right to use any credit card(s) or other payment method(s) in connection with the Purchase; and (ii) the information you provide to us is accurate, truthful, and complete.</p>
            <p>To facilitate payment processing and the fulfillment of Purchases, we may engage the services of third-party providers. By submitting your information, you authorize us to share the necessary details with these third parties, subject to the terms of our Privacy Policy.</p>
            <p>We reserve the right to refuse or cancel your order at any time for various reasons, including but not limited to the unavailability of the product or service, errors in product or service descriptions or pricing, inaccuracies in your order, or other legitimate grounds.</p>
            <p>We also reserve the right to modify the number of words charged for input or output, or to impose usage restrictions per hour, irrespective of previous commitments made at the time of subscription. These adjustments will be implemented based on our model's capacity, aiming to safeguard the interests of all users and ensure equitable access to the service. This measure is taken to offer equal opportunities to every user and uphold the quality of our service.</p>
            <p>Furthermore, we retain the right to refuse or cancel your order if there are suspicions of fraud, unauthorized transactions, or any illegal activity.</p>
        <h3>Promotions</h3>
            <p>Any contests, sweepstakes, or other promotions (collectively referred to as "Promotions") offered through the Service may have separate rules that govern them, distinct from these Terms of Service. If you choose to participate in any Promotions, we advise you to carefully review the relevant rules in addition to our Privacy Policy. In the event of any conflict between the rules of a Promotion and these Terms of Service, the rules specific to the Promotion shall take precedence.</p>
        <h3>Subscriptions</h3>
            <p>Certain portions of the Service are provided on a subscription basis ("Subscriptions"). When you purchase a Subscription, you will be billed in advance on a recurring and periodic basis according to a predetermined schedule ("Billing Cycle"). The Billing Cycle is determined based on the type of subscription plan you select, which can be either monthly or annual.</p>
            <p>Upon the conclusion of each Billing Cycle, your Subscription will automatically renew under the same conditions, unless either you or Younet, 2624465 Ontario Inc. elect to cancel it. You have the option to cancel the renewal of your Subscription by either accessing your online account management page or by contacting Younet, 2624465 Ontario Inc.'s customer support team.</p>
            <p>In order to process payment for your Subscription, a valid payment method, such as a credit card or PayPal, must be provided. You are responsible for providing accurate and complete billing information, including your full name, address, state, zip code, telephone number, and valid payment method details. By submitting such payment information, you grant automatic authorization to Younet, 2624465 Ontario Inc. to charge all Subscription fees incurred through your account to the designated payment instrument.</p>
            <p>In the event that automatic billing fails for any reason, Younet, 2624465 Ontario Inc. will issue an electronic invoice indicating that you must manually complete the full payment for the corresponding billing period, as specified on the invoice, within a specified deadline.</p>
        <h3>Free Trial</h3>
            <p>Younet, 2624465 Ontario Inc. reserves the right, at its sole discretion, to offer a Subscription with a limited-time free trial period ("Free Trial"). During this Free Trial, you may be required to provide your billing information.</p>
            <p>If you provide your billing information when signing up for the Free Trial, Younet, 2624465 Ontario Inc. will not charge you until the Free Trial period has expired. On the last day of the Free Trial period, unless you have cancelled your Subscription, Younet, 2624465 Ontario Inc. will automatically charge you the applicable Subscription fees for the chosen Subscription type.</p>
            <p>Younet, 2624465 Ontario Inc. retains the right to modify the Terms of Service for the Free Trial offer or to cancel the Free Trial offer at any time and without prior notice.</p>
        <h3>Fee Changes</h3>
            <p>Younet, 2624465 Ontario Inc., in its sole discretion and at any time, reserves the right to modify the subscription fees for the subscriptions it offers. Any changes to the subscription fees will take effect at the end of the current billing cycle.</p>
            <p>Modification or Cancellation of Free Trial Offer</p>
            <p>Younet, 2624465 Ontario Inc. reserves the right to modify the terms of service for its free trial offer or to cancel the offer altogether, at any time and without prior notice.</p>
            <p>Agreement to Modified Subscription Fees</p>
            <p>Your continued use of the service after a change in subscription fees has taken effect signifies your agreement to pay the modified amount for the subscription.</p>
        <h3>Refunds</h3>
            <p>Refunds for contracts will be issued within seven (7) days of the original purchase, provided that your account's usage of the Younet platform is less than 500 requests for any plans.</p>
        <h3>Fair Usage Policy (FUP)</h3>
            <p>Younet maintains a Fair Usage Policy (FUP) to ensure the delivery of high-quality, fast, and reliable service. This policy is in place to prevent excessive use of the service and to maintain fairness for all users. Users are expected to adhere to the guidelines outlined below.</p>
            <p>By using this service, you acknowledge and commit to selecting a plan that aligns with your profession and business size. If your usage exceeds the allocated limits, it is your responsibility to upgrade to a higher plan, request a custom plan, or utilize our business API.</p>
            <p>Our goal is to prevent automated and robotic behavior and to ensure the safety of our service and partners. Please note that distributing unlimited account login details to others for monetary gain is considered illegal activity and is strictly prohibited. Each seat in the account is intended for a single user, and sharing login details with multiple users is not permitted. Our system identifies abnormal and heavy usage patterns within a short period of time, and in such cases, certain parameters may be temporarily adjusted, potentially affecting the output quality. This adjustment typically resolves itself upon the renewal of your monthly cycle.</p>
            <p>Engaging in abnormally high usage or participating in any illegal activities, such as sharing login details with others, may result in the blocking or permanent deletion of your account without prior notice. Please be aware that no claims or refunds will be accepted in relation to such actions. By using our service, you automatically agree that any claims or disputes raised by you or your bank in this regard will be rejected.</p>
            <p>If you have any further questions or concerns about these terms, please let us know.</p>
        <h3>Prohibited Uses</h3>
            <p>You are authorized to utilize the Service exclusively for lawful purposes and in strict accordance with the specified Terms. By utilizing the Service, you expressly agree not to engage in any of the following activities:</p>
            <p>Violating any applicable national or international laws or regulations.</p>
            <p>Exploiting, causing harm to, or attempting to exploit or harm minors, including exposing them to inappropriate content.</p>
            <p>Transmitting or soliciting the transmission of any advertising, promotional materials, or unsolicited communications, such as "junk mail," "chain letters," "spam," or similar solicitations.</p>
            <p>Impersonating or making an attempt to impersonate the Company, a Company employee, another user, or any other individual or entity.</p>
            <p>Infringing upon the rights of others or participating in any unlawful, threatening, fraudulent, or detrimental activities.</p>
            <p>Engaging in conduct that restricts or inhibits the use or enjoyment of the Service by any individual, as determined by us, or that may cause harm or offense to the Company, users of the Service, or expose them to liability.</p>
            <p>Generating sexual, religious, or political content, as our AI service provider, "Open AI," strictly prohibits the generation of such content. Any attempt to generate such content will result in an error message labeled "Unsafe Content" as a final warning. Further attempts will result in the termination of your subscription, debarring you from utilizing our services again without issuing any refunds.</p>
            <p>Furthermore, you agree not to:</p>
            <p>Utilize the Service in a manner that could disable, overload, damage, or impair the Service or disrupt the usage of the Service by any other party, including real-time activities.</p>
            <p>Employ any automated means, such as robots, spiders, or similar tools, to access the Service unless expressly authorized by us in writing.</p>
            <p>Employ any manual process to monitor or copy any material on the Service without our prior written consent.</p>
            <p>Employ any device, software, or routine that interferes with the proper functioning of the Service.</p>
            <p>Introduce any viruses, trojan horses, worms, logic bombs, or any other malicious or harmful materials.</p>
            <p>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any portions of the Service, the server on which it is hosted, or any connected server, computer, or database.</p>
            <p>Initiate a denial-of-service attack or a distributed denial-of-service attack against the Service.</p>
            <p>Take any action that may impair or falsify the rating of the Company.</p>
            <p>Attempt to interfere with the proper functioning of the Service in any other manner.</p>
        <h3>Analytics</h3>
            <p>As per the terms and conditions of our Service, we reserve the right to engage third-party Service Providers, including but not limited to Google Analytics, for the purpose of monitoring and analyzing the usage of our Service. It should be noted that Google Analytics collects and tracks data in order to provide analytical insights and personalize advertisements. For detailed information regarding Google's privacy practices, we advise you to refer to their Privacy Terms web page at <a href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en  </a>. Additionally, we strongly recommend reviewing Google's data safeguarding policy, available at <a href="https://support.google.com/analytics/answer/6004245"> https://support.google.com/analytics/answer/6004245  </a>.</p>
            <p>We kindly request your compliance with these stipulations while utilizing our Service.</p>
        <h3>No Use By Minors</h3>
            <p>The Service provided by the Company is exclusively intended for individuals who have attained the age of eighteen (18) years or above. By accessing or utilizing any aspect of the Company's offerings, you hereby warrant and represent that you are at least eighteen (18) years old and possess complete authority, rights, and legal capacity to enter into this agreement and comply with all the terms and conditions set forth in these Terms. In the event that you are below the age of eighteen (18) years, you are strictly prohibited from accessing and using the Service.</p>
        <h3>Accounts</h3>
            <p>By creating an account with Younet, 2624465 Ontario Inc. ("Company"), you hereby represent and warrant that you are of legal age, specifically above 18 years, and that all the information provided by you is accurate, complete, and up to date at all times. Please note that the provision of inaccurate, incomplete, or outdated information may result in immediate termination of your account on the Service.</p>
            <p>As a user, you are solely responsible for maintaining the confidentiality of your account credentials, including but not limited to ensuring that access to your computer and/or account is restricted. You agree to accept full responsibility for any and all activities or actions undertaken using your account and/or password, whether such password is associated with our Service or a third-party service. It is your duty to promptly notify us upon becoming aware of any breach of security or unauthorized use of your account.</p>
            <p>Furthermore, it is strictly prohibited to use the name of another individual or entity as your username, unless you have obtained appropriate authorization to do so. Additionally, you shall not select a username that infringes upon the rights of any other individual or entity, including but not limited to names or trademarks protected by third-party rights. Moreover, usernames that are offensive, vulgar, or obscene are strictly prohibited.</p>
            <p>We hereby reserve the right, at our sole discretion, to refuse service, terminate accounts, remove or modify content, or cancel orders, as we deem appropriate and necessary.</p>
            <p>Please be advised that by continuing to use the Service, you acknowledge and agree to comply with the aforementioned terms and conditions.</p>
            <p>Intellectual Property</p>
            <p>The Service, including its original content (excluding any content provided by users), features, and functionality, shall at all times remain the sole and exclusive property of Younet, 2624465 Ontario Inc. and its licensors. The Service is protected by the applicable laws of the United States and foreign countries, including but not limited to copyright and trademark laws. The trademarks and trade dress associated with the Service may not be utilized in conjunction with any product or service without the express prior written consent of Younet, 2624465 Ontario Inc.</p>
        <h3>Copyright Policy</h3>
            <p>We acknowledge and uphold the intellectual property rights of others. It is our established policy to promptly address any assertion that Content published on the Service violates the copyright or other intellectual property rights ("Infringement") of any individual or entity.</p>
            <p>If you are the owner of a copyright or are authorized to act on behalf of the owner, and you believe that a copyrighted work has been reproduced in a manner that constitutes copyright infringement, please proceed to submit your claim via email to <a href="mailto:support@younet.ai"> support@younet.ai  </a>, with the subject line: "Copyright Infringement." In your claim, kindly provide a comprehensive description of the alleged Infringement in accordance with the specifications outlined below, as presented under the section titled "DMCA Notice and Procedure for Copyright Infringement Claims."</p>
            <p>Please be advised that should you misrepresent or make a bad-faith claim regarding the infringement of any Content discovered on and/or through the Service in relation to your copyright, you may be held liable for damages, including legal costs and attorney's fees.</p>
        <h3>DMCA Notice and Procedure for Copyright Infringement Claims</h3>
            <p>Please be advised that in accordance with the provisions set forth in the Digital Millennium Copyright Act (DMCA), you have the right to submit a notification to our designated Copyright Agent by providing the following information in a written format, as further elaborated in 17 U.S.C 512(c)(3):</p>
            <p>An electronic or physical signature of the individual authorized to act on behalf of the copyright owner's interest.</p>
            <p>A detailed description of the copyrighted work that you allege has been infringed, including the URL (i.e., web page address) where the copyrighted work is located or a copy thereof.</p>
            <p>Identification of the URL or any specific location on the Service where the allegedly infringing material can be found.</p>
            <p>Your complete address, telephone number, and email address.</p>
            <p>A statement from you affirming that you possess a genuine belief, in good faith, that the disputed use of the copyrighted material is not authorized by the copyright owner, their agent, or applicable law.</p>
            <p>A declaration made by you, under penalty of perjury, asserting that the aforementioned information provided in your notification is accurate and that you are the lawful copyright owner or authorized to act on behalf of the copyright owner.</p>
            <p>To communicate with our designated Copyright Agent, please utilize the following email address: <a href="mailto:support@younet.ai"> support@younet.ai  </a></p>
        <h3>Error Reporting and Feedback</h3>
            <p>You may furnish us, either directly at <a href="mailto:support@younet.ai"> support@younet.ai  </a>&nbsp;or through third-party websites and tools, with information and feedback pertaining to errors, suggestions for enhancements, ideas, issues, grievances, and other matters associated with our Service ("Feedback"). You hereby acknowledge and agree that: (i) you shall not retain, acquire, or assert any intellectual property right or any other right, title, or interest in or to the Feedback; (ii) the Company may possess development concepts akin to the Feedback; (iii) the Feedback does not comprise confidential or proprietary information belonging to you or any third party; and (iv) the Company is not bound by any obligation of confidentiality concerning the Feedback. In the event that the transfer of ownership of the Feedback is rendered impracticable due to applicable compulsory laws, you grant the Company and its affiliates an exclusive, transferable, irrevocable, cost-free, sublicensable, unrestricted, and perpetual right to utilize (including but not limited to copying, modifying, creating derivative works, publishing, distributing, and commercializing) the Feedback in any manner and for any purpose.</p>
        <h3>Links To Other Web Sites</h3>
            <p>Our Service may include hyperlinks or references to external websites or services that are not owned or controlled by Younet, 2624465 Ontario Inc. It is important to note that Younet, 2624465 Ontario Inc. has no authority or jurisdiction over these external entities and assumes no responsibility for their content, privacy policies, or operational practices. The inclusion of such links or references does not imply any endorsement or warranty regarding the offerings provided by these entities or individuals, nor the reliability or accuracy of their websites.</p>
            <p>By using our Service, you acknowledge and agree that Younet, 2624465 Ontario Inc. shall not be held responsible or liable, directly or indirectly, for any damage or loss arising from or allegedly caused by your use of, or reliance upon, the content, products, or services available on or through such external websites or services. We strongly encourage you to review the applicable Terms of Service and Privacy Policies of any third-party websites or services you choose to access.</p>
            <p>It is essential to exercise caution and prudence when navigating and interacting with external websites, as they may have different terms, policies, and practices than those of Younet, 2624465 Ontario Inc.</p>
            <p>Please note that this communication is for informational purposes only and does not constitute legal advice. For specific legal concerns, it is advisable to consult with a qualified attorney.</p>
        <h3>Disclaimer Of Warranty</h3>
            <p>These services are provided by the Company on an "as is" and "as available" basis. The Company makes no representations or warranties, express or implied, regarding the operation of their services, or the information, content, or materials included therein. You explicitly agree that your use of these services, their content, and any services or items obtained from the Company is solely at your own risk.</p>
            <p>Neither the Company nor any person associated with the Company makes any warranty or representation concerning the completeness, security, reliability, quality, accuracy, or availability of the services. Without limiting the foregoing, neither the Company nor anyone associated with the Company represents or warrants that the services, their content, or any services or items obtained through the services will be accurate, reliable, error-free, or uninterrupted. They do not guarantee that defects will be corrected, that the services or the server making them available are free of viruses or other harmful components, or that the services or any services or items obtained through the services will meet your specific needs or expectations.</p>
            <p>The Company hereby disclaims all warranties of any kind, whether express or implied, statutory, or otherwise, including but not limited to any warranties of merchantability, non-infringement, and fitness for a particular purpose.</p>
            <p>The aforementioned disclaimer does not affect any warranties that cannot be excluded or limited under applicable law.</p>
        <h3>Limitation Of Liability</h3>
            <p>To the fullest extent permitted by law, you shall indemnify and hold harmless Younet, 2624465 Ontario Inc. ("Company") and its officers, directors, employees, and agents from any and all indirect, punitive, special, incidental, or consequential damages, regardless of the cause of action (including attorneys' fees, litigation and arbitration costs and expenses, or costs incurred at trial or on appeal, if applicable, whether or not litigation or arbitration is initiated). Such damages may arise from a breach of contract, negligence, or any other tortious act, or in connection with this agreement, including any claim for personal injury or property damage, arising out of or relating to this agreement and your violation of any federal, state, or local laws, statutes, rules, or regulations, even if the Company has been previously informed of the possibility of such damages.</p>
            <p>Except where prohibited by law, in the event that the Company is found liable, its liability shall be limited to the amount paid for the products and/or services, and under no circumstances shall there be any consequential or punitive damages. Please note that some states may not allow the exclusion or limitation of punitive, incidental, or consequential damages, so the above limitation or exclusion may not apply to you.</p>
        <h3>Termination</h3>
            <p>Under our sole discretion, we reserve the right to terminate or suspend your account, and deny access to the Service, without prior notice or liability. This action may be taken for any reason whatsoever, without limitation, including, but not limited to, a breach of the Terms. Should you desire to terminate your account, you may do so by discontinuing the use of the Service. Furthermore, we maintain the authority to terminate or suspend your account and deny access to the Service immediately, without prior notice or liability, at our sole discretion, for any reason whatsoever, without limitation, including but not limited to a breach of the Terms.</p>
        <h3>Governing Law</h3>
            <p>These Terms shall be governed and interpreted in accordance with the laws of the Province of Ontario, Canada, without giving effect to its conflict of law principles.</p>
            <p>The failure to enforce any right or provision of these Terms shall not be deemed a waiver of such rights. In the event that any provision of these Terms is held to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions of these Terms shall remain in full force and effect. These Terms represent the complete agreement between the parties concerning the Service and supersede any prior agreements, whether written or oral, between the parties regarding the Service.</p>
        <h3>Changes To Service</h3>
            <p>We retain the authority to unilaterally revoke or modify our Service, as well as any service or content provided through the Service, without prior notice. We shall not be held accountable in the event that the Service or any portion thereof becomes unavailable, either temporarily or permanently, for any reason. Periodically, we may impose limitations on user access to specific sections or the entirety of the Service, including registered users.</p>
        <h3>Amendments To Terms</h3>
            <p>The Terms of Service may be modified at any time, and such amendments will be made known by posting the revised terms on this website. It is incumbent upon you to periodically review these Terms in order to remain informed of any changes. Your continued use of the Platform subsequent to the posting of revised Terms indicates your acceptance and agreement to the modifications. It is expected that you regularly consult this page to ensure awareness of any alterations, as they shall be binding upon you.</p>
            <p>By persisting in accessing or utilizing our Service subsequent to the effective date of any revisions, you affirm your consent to be legally bound by the revised terms. If you do not consent to the newly introduced terms, your authorization to use the Service shall be terminated.</p>
        <h3>Waiver And Severability</h3>
            <p>The Company does not waive any term or condition stated in the Terms of Service by failing to enforce it, and such failure does not imply a waiver of that term or condition or any other term or condition. Furthermore, if any provision within the Terms of Service is deemed invalid, illegal, or unenforceable by a court or other competent authority, that provision shall be either eliminated or limited to the minimum extent necessary, while the remaining provisions of the Terms will remain in full force and effect.</p>
        <h3>Acknowledgement</h3>
            <p>By utilizing the service or any other services rendered by Younet, 2624465 Ontario Inc., you hereby acknowledge and affirm that you have perused and comprehended these terms of service, and consequently, you willingly and knowingly consent to be legally obligated by them.</p>
        <br/>
        <h3>Use of Sharing For a Fee Tool</h3>
        {/* <hr/> */}
        <h3>Subscription Fees and Payments</h3>
            <p>· Fee Collection: Subscription fees set by Creators will be collected by Younet and disbursed on a Net 30 days basis after the end of each month.</p>
            <p>· Payment Processing Fees: Fees charged by payment gateways such as Stripe will be deducted from the payout to Creators.</p>
        <h3>Creator Responsibilities</h3>
            <p>· Copyright Ownership: Creators must ensure they have the copyright or appropriate rights to the knowledge and materials used in their AI models.</p>
            <p>· Content Legality: AI models and associated content must not violate any laws or contain prohibited materials.</p>
        <h3>Platform Management and Content Review</h3>
            <p>· No Content Review by Younet: Younet, Inc. does not review the content of AI models. Information within these models is considered private and confidential to the Creator.</p>
            <p>· Copyright Claims: Copyright infringement claims can be processed through Younet's support portal: <a href='https://support.younet.ai/submit-ticket/1-Support' target='a_blank'>https://support.younet.ai/submit-ticket/1-Support</a> </p>
        <h3>Processing Fees</h3>
            <p>· Payout Fees: A 1% processing service fee will be charged on the balance transferred to Creators to cover administrative costs.</p>
        <h3>Amendments to Terms of Service</h3>
            <p>Younet reserves the right to modify these terms at any time. Such amendments will be effective immediately upon posting on the Younet platform.</p>
        <h3>Acceptance of Terms</h3>
            <p>By using the "Sharing For a Fee" tool, Creators agree to comply with these terms and acknowledge that they are legally binding.</p>
        <h3>Contact Us</h3>
            <p>Please direct any feedback, comments, or requests for technical support to the following email address:&nbsp;<a href="mailto:support@younet.ai"> support@younet.ai  </a>&nbsp;&nbsp;</p>
    </Grid>

    );
}

export default TermsAndConditions;