import React, {useState} from 'react';
import YouTube from 'react-youtube';

const YoutubeVideo = ({ videoWidth,videoHeight,videoId,poster=''}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const onReady = (event) => {
    const player = event.target;
    player.setPlaybackQuality('hd1080'); 
    setIsLoaded(true);
}

  const opts = {
    height: videoHeight,
    width: videoWidth,
    playerVars: {
      autoplay: 0, 
      modestbranding: 1,
      loop: 1,
      fullScreen:1,
      playlist: videoId
    },
  };

  return (
    <div className='video-responsive'>
      {!isLoaded &&
      (
        <img
          src={poster}
          alt="Loading..."
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            top: 0,
            left: 0
          }}
        />
      )}
      <YouTube videoId={videoId} opts={opts} onReady={onReady} />
    </div>
  );
};

export default YoutubeVideo;