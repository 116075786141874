export const getInitials = (str, num = 2) => {

  const words = str.split(" ");

  const firstLetters = words
    .slice(0, num) 
    .map((word) => word.charAt(0))
    .join(""); 

  return firstLetters
};