import React,{useEffect} from 'react';
import 'assets/styles/company.css'
import CareerArticle from 'components/career/CareerArticle';
import Header from 'components/career/Header';

const Career=()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <Header/>
            <CareerArticle/>
        </div>
    );
}

export default Career;