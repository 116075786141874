import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from 'views/base/Layout.js';
import Info from 'views/base/Info.js';
import Home from 'views/home/Home.js';
import Business from 'views/business/Business';
import Blogs from 'views/blogs/Blogs';
import BlogDetails from 'views/blogs/BlogDetails';
import ContactUs from 'views/contactUs/ContactUs';
import TermsAndConditions from 'views/resources/TermsAndConditions';
import Pricing from 'views/pricing/Pricing';
import Overview from 'views/overview/Overview';
import Brandbook from 'views/brandbook/Brandbook';
import About from 'views/about/About';
import RabbitAI from 'views/rabbitAI/RabbitAI';
import ChromeExtension from 'views/chromeExtension/ChromeExtension';
import Career from 'views/career/Career';
import UseCases from 'views/useCases/UseCases';
import Landing from 'views/landing/Landing';
import SolutionSales from 'views/solution/SolutionSales';
import SolutionMarketing from 'views/solution/SolutionMarketing';
import SolutionLegal from 'views/solution/SolutionLegal';
import SolutionSupport from 'views/solution/SolutionSupport';
import SolutionHR from 'views/solution/SolutionHR';
import SolutionFinance from 'views/solution/SolutionFinance';
import SolutionEngineering from 'views/solution/SolutionEngineering';
import AIBot from 'views/products/AIBot';
import MonetizeAI from 'views/products/MonetizeAI';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/business" element={<Business />} />
          <Route path='/pricing' element={<Pricing/>} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/page/:id" element={<Blogs />} />
          <Route path="/blogs/details/:id" element={<BlogDetails />} />
          <Route path="/blogs/:categories" element={<Blogs />} />
          <Route path="/blogs/:categories/:pageId" element={<Blogs />} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
          <Route path='/brandbook' element={<Brandbook/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/rabbit' element={<RabbitAI/>} />
          <Route path='/chrome-extension' element={<ChromeExtension/>} />
          <Route path='/careers' element={<Career/>}/>
          <Route path='/features' element={<UseCases/>}/>
          <Route path='/landing' element={<Landing/>}/>
          <Route path='/solution-sales' element={<SolutionSales/>}/>
          <Route path='/solution-marketing' element={<SolutionMarketing/>}/>
          <Route path='/solution-legal' element={<SolutionLegal/>}/>
          <Route path='/solution-support' element={<SolutionSupport/>}/>
          <Route path='/solution-hr' element={<SolutionHR/>}/>
          <Route path='/solution-finance' element={<SolutionFinance/>}/>
          <Route path='/solution-engineering' element={<SolutionEngineering/>}/>
          <Route path="/products/ai-assistant" element={<Overview/>} />
          <Route path='/products/ai-bot' element={<AIBot/>}/>
          <Route path='/products/ai-monetize' element={<MonetizeAI/>}/>
          <Route path="*" element={<Info status={404}/>} />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
