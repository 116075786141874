import React from 'react';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid, Grow, Slide, Button,Link } from '@mui/material';
import { useInView } from 'react-intersection-observer'
import TypingContent from './TypingContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import G2Button from 'components/mui/buttons/G2Button';
import HighPerformanceBadgeButton from 'components/mui/buttons/HighPerformanceBadgeButton';


const Header = ({titles,content,tagContentObj}) => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <WideContainer>
            <div className='center-align'>
                <Grid container className='basic-header-container'>
                    <Slide timeout={{ enter: 1200 }} in={inView} direction="right" container={containerRef.current}>
                        <Grid item xs={12} sm={12} md={6} lg={5} className='center-align'>
                            <div className='overview-title'>
                                <p style={{ padding: '10px 0', fontSize: 20, color: 'var(--ynt-purple)' }}>{titles[0]}</p>
                                <h1>{titles[1]}</h1>
                                <h1>{titles[2]}</h1>
                                <div style={{ padding: '10px 0' }}>
                                    <p>{content}</p>
                                </div>
                                <Grid display='flex' flexDirection={isMobile?'column':'row'} alignItems='center'>
                                    <Link href='https://younet.ai/portal/sign-up' pr={{xs:0,md:2}}><Button sx={{ margin: '10px 0', padding: '12px' }} variant='outlined'>Sign up for free</Button></Link>
                                    <Grid display='flex' alignItems='center'>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><G2Button/></Link>
                                        <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><HighPerformanceBadgeButton/></Link>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Slide>
                    <Grow timeout={{ enter: 1200 }} in={inView}>
                        <Grid item xs={12} sm={12} md={6} lg={7} className='center-align'>
                            <Grid sx={{maxWidth:'850px',width:'100%'}}>
                                <TypingContent object={tagContentObj}/>
                            </Grid>
                        </Grid>
                    </Grow>
                    <div className='long-observer' ref={inViewRef}></div>
                </Grid>
            </div>
        </WideContainer>
    );
}

export default Header;