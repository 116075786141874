import { useState } from "react";

const useCopyToDevice = (data = "") => {
 const [message, setMessage] = useState("Copy Link");

 const CopyToClipboard = async (data) => {
  try {
   if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(data);
   } else {
    const textArea = document.createElement("textarea");
    textArea.value = data;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
   }
   setMessage("Copied")
  } catch (error) {
   console.error("Failed to copy text to clipboard:", error);
  }

 }

 return { copy: CopyToClipboard, message }
};

export default useCopyToDevice;