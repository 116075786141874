import React from 'react';
import { Grid, Divider, Button, Card, CardContent,Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const PricingDes = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const plans = [
        {
            id: 1,
            name: 'Trial',
            credits: '200',
            models: '1',
            dailyUse: '50 req/day',
            textData: true,
            qandaData: true,
            gmailData: 10,
            outlookData: 10,
            fileData: 1,
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: false,
            speakToFile: true,
        },
        {
            id: 2,
            name: 'Starter',
            credits: '2,000',
            models: 'Unlimited',
            dailyUse: 'Unlimited',
            textData: true,
            qandaData: true,
            gmailData: 'Unlimited',
            outlookData: 'Unlimited',
            fileData: 'Unlimited',
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: false,
            speakToFile: true,
        },
        {
            id: 3,
            name: 'Pro5K',
            credits: '5,000',
            models: 'Unlimited',
            dailyUse: 'Unlimited',
            textData: true,
            qandaData: true,
            gmailData: 'Unlimited',
            outlookData: 'Unlimited',
            fileData: 'Unlimited',
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: false,
            speakToFile: true,
        },
        {
            id: 4,
            name: 'Pro20K',
            credits: '20,000',
            models: 'Unlimited',
            dailyUse: 'Unlimited',
            textData: true,
            qandaData: true,
            gmailData: 'Unlimited',
            outlookData: 'Unlimited',
            fileData: 'Unlimited',
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: false,
            speakToFile: true,
        },
        {
            id: 5,
            name: 'Pro50K',
            credits: '50,000',
            models: 'Unlimited',
            dailyUse: 'Unlimited',
            textData: true,
            qandaData: true,
            gmailData: 'Unlimited',
            outlookData: 'Unlimited',
            fileData: 'Unlimited',
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: false,
            speakToFile: true,
        },
        {
            id: 6,
            name: 'Pro100K',
            credits: '100,000',
            models: 'Unlimited',
            dailyUse: 'Unlimited',
            textData: true,
            qandaData: true,
            gmailData: 'Unlimited',
            outlookData: 'Unlimited',
            fileData: 'Unlimited',
            privacy: true,
            security: true,
            reliability: true,
            playground: true,
            extension: true,
            aiShare: true,
            languages: true,
            support: true,
            manager: true,
            speakToFile: true,
        },


    ]
    return (
        <>
            <div className='price-table-container'>
                {isMobile ?
                    <Grid container>
                        {plans.map((plan, i) =>
                            <Grid key={i} my={2} sx={{ width: '100%' }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <h3>{plan.name}</h3>
                                        <Link href={`${window.origin}/portal/login?redirect=/billing/subscribe/${plan.id}&newPlan=true`}>
                                            {plan.name === 'Trial' ? <Button variant='outlined' sx={{ margin: '10px 0' }}>Join for free</Button> : ''}
                                            {plan.name === 'Starter' ? <Button variant='outlined' sx={{ margin: '10px 0' }}>Get Started</Button> : ''}
                                            {plan.name !== 'Trial' && plan.name !== 'Starter' ? <Button variant='contained' sx={{ margin: '10px 0' }}>Get Started</Button> : ''}
                                        </Link>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Credits</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p className='price-table-credits'>{plan.credits}</p></Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Models</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p>{plan.models}</p></Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Daily Use</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p>{plan.dailyUse}</p></Grid>
                                        </Grid>
                                        <Divider sx={{ margin: '20px 0 0 10px', color: 'black', fontWeight: '500', fontSize: '16px' }}>Training</Divider>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Text</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.textData ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Q&A</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.qandaData ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Gmail Conversations</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p>{plan.gmailData}</p></Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Outlook Conversations</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p>{plan.outlookData}</p></Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Files</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'><p>{plan.fileData}</p></Grid>
                                        </Grid>

                                        <Divider sx={{ margin: '20px 0 0 10px', color: 'black', fontWeight: '500', fontSize: '16px' }}>Security</Divider>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Data Privacy</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.privacy ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Security</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.security ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Reliability</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.reliability ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>

                                        <Divider sx={{ margin: '20px 0 0 10px', color: 'black', fontWeight: '500', fontSize: '16px' }}>Communication</Divider>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Playground</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.playground ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Browser Extension</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.extension ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>AI Model Sharing</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.aiShare ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>50+ Languages</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.languages ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>

                                        <Divider sx={{ margin: '20px 0 0 10px', color: 'black', fontWeight: '500', fontSize: '16px' }}>Support</Divider>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Technical Support</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.support ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                        <Grid container px={1} pt={1.5} display='flex' flexDirection='row' width='100%'>
                                            <Grid item xs={6} display='flex' alignItems='center'><h5>Dedicated Manager</h5></Grid>
                                            <Grid item xs={6} display='flex' alignItems='center'>{plan.manager ? <CheckIcon color='success' /> : <CloseIcon color='error' />} </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>)
                        }
                    </Grid>
                    :
                    <Grid>
                        <Grid container py={3} sx={{ borderBottom: '1px solid var(--ynt-mildgrey)', flexWrap: 'nowrap' }}>
                            <Grid item xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h4>Plans</h4>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item key={i} xs={1} md={1.5} sx={{ display: 'flex', flexDirection: 'column' }} className='center-align price-table-header price-table-cell'>
                                        <h5>{plan.name}</h5>
                                        <Link href={`${window.origin}/portal/login?redirect=/billing/subscribe/${plan.id}&newPlan=true`}>
                                            {plan.name === 'Trial' ? <Button variant='outlined' sx={{ margin: '0 5px' }}>Join for free</Button> : ''}
                                            {plan.name === 'Starter' ? <Button variant='outlined' sx={{ margin: '0 5px' }}>Get Started</Button> : ''}
                                            {plan.name !== 'Trial' && plan.name !== 'Starter' ? <Button variant='contained' sx={{ margin: '0 5px' }}>Get Started</Button> : ''}
                                        </Link>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Credits</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        <p className='price-table-credits'>{plan.credits}</p>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Models</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.models}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Daily Use</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.dailyUse}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Divider sx={{ margin: '20px 0', color: 'black', fontWeight: '500', fontSize: '18px' }}>Training</Divider>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Text</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.textData ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Q&A</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.qandaData ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Gmail Conversations</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                {plan.gmailData}
                                </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Outlook Conversations</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                {plan.outlookData}
                                </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Files</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                {plan.fileData}
                                </Grid>
                                )
                            }
                        </Grid>
                        <Divider sx={{ margin: '20px 0', color: 'black', fontWeight: '500', fontSize: '18px' }}>Security</Divider>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Data Privacy</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.privacy ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Security</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.security ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Reliability</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.reliability ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Divider sx={{ margin: '20px 0', color: 'black', fontWeight: '500', fontSize: '18px' }}>Communication</Divider>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Playground</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.playground ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Browser Extension</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.extension ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>AI Model Sharing</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.aiShare ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>50+ Languages</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.languages ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Speak to File</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.speakToFile ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Divider sx={{ margin: '20px 0', color: 'black', fontWeight: '500', fontSize: '18px' }}>Support</Divider>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Technical Support</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.support ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ flexWrap: 'nowrap' }}>
                            <Grid item py={2} xs={3} md={3} className='center-align price-table-header price-table-cell'>
                                <h5>Dedicated Manager</h5>
                            </Grid>
                            {
                                plans.map((plan, i) =>
                                    <Grid item py={2} key={i} xs={1} md={1.5} className='center-align price-table-cell'>
                                        {plan.manager ? <CheckIcon color='success' /> : <CloseIcon color='error' />}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                }
            </div>
        </>
    );
}

export default PricingDes;