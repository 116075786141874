import { useState, useEffect, useMemo } from 'react';
import {
  Avatar,
  Paper,
  Typography,
  FormHelperText,
  Grid,
  Box,
  TextField,
  Link,
  Button,
  Divider,
} from '@mui/material';
import { getInitials } from '../../../../helpers/getInitials';
import LogoutIcon from '@mui/icons-material/Logout';
import GoogleAuthBtn from '../GoogleAuthBtn';
import FacebookAuthBtn from '../FacebookAuthBtn';
import { setItem, getItem } from '../../../../helpers/localStorageManagement';
import { API_PATHS } from "../../../../constants/apiPaths";
import { logout } from '../../../../helpers/auth';
import useDeviceDetect from 'hooks/useDeviceDetect';

const AuthForm = () => {

  const { userAgent, isInAppView } = useDeviceDetect();
  const isWebView = useMemo(() => {
    return userAgent.includes("LinkedInApp");
  }, [userAgent])

  const [userState, setUserState] = useState({
    globalErr: '',
    email: {
      errMsg: '',
      isRequired: true,
    },
    password: {
      errMsg: '',
      isRequired: true,
    },
  });
  const [userData, setUserData] = useState(null);
  const userLocalStorageData = getItem('userData');

  useEffect(() => {
    if (userLocalStorageData) {
      setUserData(JSON.parse(userLocalStorageData));
    }
  }, [userLocalStorageData]);

  const googleOptions = {
    method: 'post',
    url: 'google/oauth2/url',
  };

  // TODO: create a custom hook for logging in
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData(e.currentTarget);
    const email = form.get('email');
    const password = form.get('password');
    let emptyErr = false;

    let updatedState = { ...userState };
    let emptyMsg = 'The field is empty';
    if (!email) {
      updatedState.email.errMsg = emptyMsg;
      emptyErr = true;
    } else {
      updatedState.email.errMsg = '';
    }

    if (!password) {
      updatedState.password.errMsg = emptyMsg;
      emptyErr = true;
    } else {
      updatedState.password.errMsg = '';
    }
    setUserState(updatedState);

    if (emptyErr) {
      return;
    }

    const data = {
      username: email,
      password,
      redirectUrl: '/portal/',
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(API_PATHS.loginCheck(), options);
      const responseData = await response.json();

      if (responseData.code === 401) {
        setUserState((prevState) => ({
          ...prevState,
          globalErr: 'Invalid credentials.',
        }));
        return;
      }

      if (responseData.code === 200 && responseData.autologin !== '') {
        const { user, token } = responseData;
        const userData = { fullName: user.fullName, email: user.email, avatar: user.avatar, planId: user.tariff_plan?.id || 1};

        setItem('token', token);
        setItem('userData', userData);
        window.location.href = `${window.location.origin}/portal/`;
      } else {
        setUserState((prevState) => ({
          ...prevState,
          globalErr: 'Something went wrong.',
        }));
      }

      // Update state with the response message (if available)
      // setResponseMessage(responseData.message || 'Request successful.');
    } catch (error) {
      console.error('Error:', error);
      // Handle errors here
    }
  };

  const onLogOutHandler = () => {
    logout();
    setUserData(null);
  }

  if (userLocalStorageData) {
    return (
      <Paper elevation={3} sx={{ padding: "30px", maxWidth: "600px", margin: "0 auto" }}>
        <Box
          component="div"
          noValidate
          sx={{ backgroundColor: "white" }}
        >
          <Grid sx={{ mb: 0, padding: 0 }} item xs={12}>
            <Grid container sx={{ mb: 3, gap: '14px', pl: '5px' }}>
              <Grid item sx={{ display: 'flex' }}>
                <Avatar src={userData?.avatar ? userData.avatar : ''} sx={{ fontSize: 15, border: '1px solid #00000011' }}>{userData?.fullName && getInitials(userData.fullName)}</Avatar>
              </Grid>
              <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography sx={{ width: '100%', fontWeight: "bold" }} variant="div">
                  {userData?.fullName}
                </Typography>
                <Typography sx={{ width: '100%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} variant="span">
                  {userData?.email}
                </Typography>
              </Grid>
            </Grid>
            <Button href="/portal/" sx={{ mb: 3 }} variant="contained" className="auth-cta">
              Go to Portal
            </Button>
            <Button variant="outlined" startIcon={<LogoutIcon />} className="auth-cta" onClick={onLogOutHandler}>
              Log Out
            </Button>
          </Grid>
        </Box>
      </Paper>
    )
  }

  return (
    <Paper elevation={3} sx={{ padding: "30px", maxWidth: "600px", margin: "0 auto"  }}>
      <FormHelperText sx={{ color: "#d32f2f" }}>
        {userState.globalErr ? userState.globalErr : ""}
      </FormHelperText>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, backgroundColor: "white" }}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          error={userState.email.errMsg !== ""}
          helperText={
            userState.email.errMsg ? userState.email.errMsg : ""
          }
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          error={userState.password.errMsg !== ""}
          helperText={
            userState.password.errMsg ? userState.password.errMsg : ""
          }
        />
        <Grid container>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Link href="/portal/reset-password" variant="body2">
              Forgot Password
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="end"
            alignItems="center"
          >
            <Link href="https://younet.ai/portal/sign-up" variant="body2">
              Sign Up
            </Link>
          </Grid>

        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In
        </Button>
          <Grid container>
            <Grid item xs={12}>
              <Divider>or</Divider>
            </Grid>
            {!isInAppView && (
              <Grid sx={{ mt: 2, mb: 0, padding: 0 }} item xs={12}>
                <GoogleAuthBtn
                  googleOptions={googleOptions}
                  redirectUrl={`${window.location.origin}/portal/google/login`}
                  text="Sign in with Google"
                  fullWidth
                />
              </Grid>
            )}
            <Grid sx={{ mt: 2, mb: 2, padding: 0 }} item xs={12}>
              <FacebookAuthBtn
                redirectUrl={`${window.location.origin}/portal/facebook/login/`}
                text="Sign in with Facebook"
              />
            </Grid>
          </Grid>
      </Box>
    </Paper>

  )
}

export default AuthForm;
