import React from 'react';
import { NarrowContainer, WideContainer} from 'components/mui/containers/PxftContainer';
import {Grid,Link} from '@mui/material';
import CenterTitle from 'components/mui/containers/centerTitle';
import PricingCard from 'components/pricing/pricingCard';
import PricingDes from './PricingDes';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import G2Button from 'components/mui/buttons/G2Button';
import HighPerformanceBadgeButton from 'components/mui/buttons/HighPerformanceBadgeButton';

function PricingArticle(props) {
    return (
        <WideContainer>
            <div className='business-article-container center-align'>
                <NarrowContainer>
                    <Grid container sx={{mt:10,pt:10}} className='center-align'>
                        <Grid item xs={12} className='center-align' sx={{maxWidth:'80%'}}>
                            <h1 style={{textAlign:'center'}}>Empower Your Business with Personalized AI</h1>
                        </Grid>
                        <Grid item xs={12} pt={1} pb={3} className='center-align' sx={{maxWidth:'80%'}}><h1 style={{textAlign:'center'}}>No Coding Required, Get Started in Minutes</h1></Grid>
                        <Grid item xs={12} flexDirection='column' className='center-align' sx={{maxWidth:'80%'}}>
                            <p style={{textAlign:'center'}}>Start using Younet AI in your daily life to advance.</p>
                            <p style={{textAlign:'center'}}>Sign up to start for free and go pro when you're ready.</p>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className='center-align' pt={2}>
                                <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><G2Button/></Link>
                                <Link href='https://www.g2.com/products/younet/reviews' underline='none' target='_blank'><HighPerformanceBadgeButton/></Link>
                            </Grid>
                            <PricingCard/>
                        </Grid>
                        <CenterTitle title='Compare Plans'/>
                        <Grid item xs={12} px={3} sx={{overflow:'hidden'}}>
                            <PricingDes/>
                        </Grid>
                        <Grid item xs={12}>
                            <GetStartedCard/>
                        </Grid>
                    </Grid>
                </NarrowContainer>
            </div>
        </WideContainer>
    );
}

export default PricingArticle;