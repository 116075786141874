import React,{useEffect} from 'react';
import 'components/products/products.css'
import MonetizeAIHeader from 'components/products/MonetizeAIHeader';
import MonetizeAIArticle from 'components/products/MonetizeAIArticle';

const MonetizeAI =()=> {
    useEffect(()=>{
        window.scrollTo(0, 0);
      })
    return (
        <div>
            <MonetizeAIHeader/>
            <MonetizeAIArticle/>
        </div>
    );
}

export default MonetizeAI;