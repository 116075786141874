import React,{useState, useEffect}  from 'react';
import { Grid,BottomNavigation, BottomNavigationAction, Avatar } from '@mui/material';
import { HistoryEdu, Explore, BusinessCenter, Person, SpeakerNotes, EmojiObjects, AccountBalance, School, Description, ControlPoint} from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import {AskingTypingEffect,AnsweringTypingEffect} from 'components/mui/TypingEffect';

const TypingBottomNavi = styled(BottomNavigation)(({theme})=>({
  display:"flex",
  width:"100%",
  height:"auto",
  minHeight:'60px',
  flexWrap: "wrap",
  backgroundColor:'rgba(255,255,255,0)'
}));

const LandingFeatures=()=> {
    const [selectedTag, setSelectedTag] = useState(0);
    const [askingTextComplete, setAskingTextComplete] = useState(false);

    const tagContent = [
          {
            id: 0,
            label: 'Writing',
            icon: <HistoryEdu />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Content Writing',
            fileNames:[
              'Products & Services Info.pdf',
              'Price-list.xlsx',
              'Company Info.doc',
            ],
            sentence1: 'Write a blog post about our new products and services.',
            sentence2: 'Title: Elevating Business Efficiency with Our Latest Subscriptions\n\nIn today‘s fast-paced world, staying connected is not just a luxury; it’s a necessity. Our company is proud to announce a suite of new subscription plans that are set to revolutionize the way you work. \n\nWith a focus on convenience, affordability, and cutting-edge technology, our latest offerings are designed\n< ... >',
          },
          {
            id: 1,
            label: 'Sales',
            icon: <BusinessCenter />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Sales & Marketing',
            fileNames:[
              'Products & Services info.pdf',
              'Price-list.xlsx'
            ],
            sentence1: 'Write an email to David and introduce our new products and services.',
            sentence2: 'Subject: Discover What‘s New – Innovation Meets Affordability!\n\nDear David,\n\nWe’re excited to unveil our latest range of products and services designed to elevate your mobile experience, including cutting-edge smartphones at exclusive discounts and tailored < ... >',
          },
          {
            id: 2,
            label: 'HR',
            icon: <Person />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to HR',
            fileNames:[
              'Employment agreement.pdf',
              'Policy.pdf',
              'Employment letter template.doc'
            ],
            sentence1: 'Write an employment letter for David Jefferson.',
            sentence2: 'Subject: Employment Confirmation Letter\n\nWe are pleased to confirm your employment. This letter serves to affirm the terms of your employment and your contributions to our team as a valued member.\nPosition: Senior Software Engineer\nStart Date: December 2, 2019\nAnnual Compensation: $150,000\n\nAs a Senior Software Engineer, you have consistently demonstrated exceptional < ... >',
          },
          {
            id: 3,
            label: 'Analytics',
            icon: <SpeakerNotes />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Financial Analytics',
            fileNames:[
              'YOY Sales Report 2021-2023.xlsx',
            ],
            sentence1: 'Analyze our sales by year and show me results.',
            sentence2: 'Certainly, let‘s take a look at the sales data by year:\n\nIn 2021, a total of 2,774,093 units were sold, generating a total revenue of $708,170,461.04.\nIn 2022, there was a slight increase with 2,831,548 units sold and a revenue of $722,837,573.44.\nThe year 2023 saw a small decrease in units sold to 2,664,883, but the revenue was still quite high at $680,291,332.24. \n< ... >',
          },{
            id: 4,
            label: 'Legal',
            icon: <AccountBalance />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Legal Consultant',
            fileNames:[
              'MasterServiceAgreement.docx',
            ],
            sentence1: 'Summarize the key points of the agreement.',
            sentence2: 'The Master Service Agreement (Exhibit 10.2) is a contract effective as of May 14, 2023. The agreement outlines the terms under which the Provider will outsource certain services for the Customer, who is in the business of healthcare services.\nKey points include:\n\tDefinitions of terms used within the agreement.\n\tThe Provider‘s commitment to deliver customer contact center and < ... >',
          },
          {
            id: 5,
            label: 'Engineering',
            icon: <EmojiObjects />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Customized Coding',
            fileNames:[
              'Master Code.txt',
              'API documentation.txt'
            ],
            sentence1: 'Write a form that authorize user with our authorization API.',
            sentence2: 'Certainly! Below is an example of a simple HTML form that could be used to authorize a user with your API:\n\n\t<!DOCTYPE html>\n\t<html lang="en">\n\t<head><meta charset="UTF-8">\n\t<meta name="viewport" content="width=device-width, initial-scale=1.0">\n\t<title>Login Form</title>\n\t< ... >',
          },
          {
            id: 6,
            label: 'Support',
            icon: <Explore />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Customer Support',
            fileNames:[
              'Knowledge base.pdf',
              'Troubleshooting-tips.xlsx'
            ],
            sentence1: 'My client is asking how to update our Chrome Extension on Mac. What are the steps?',
            sentence2: 'To update the Chrome extension on a Mac computer, your client can follow these steps:\n\n\t1. Open the Google Chrome browser on the Mac.\n\t2. Click on the three-dot menu icon in the upper-right corner of the browser window.\n\t3. From the dropdown menu, select "More Tools" and then "Extensions."\n\t4. This will open the Extensions page. Here, your client should enable < ... >',
          },
          {
            id: 7,
            label: 'Education',
            icon: <School />,
            avatar1: 'P',
            avatar2: '/images/btn.png',
            role:'Prompt to Education Advice',
            fileNames:[
              'Lifetimes of Lunar Satellite Orbits.pdf',
            ],
            sentence1: 'What is the average lifetime of a mission on a lunar orbit?',
            sentence2: 'According to "Lunar Satellite Research," an analysis conducted using the Ferrari 5x5 lunar gravitational model examined the lifetimes of orbits with initial perigee altitudes of 100 km and 300 km. The results showed that some orbits had lifetimes of less than 40 days, while others exceeded a year. It is important to note that these lifetimes < ... >',
          },
    ]
    const handleTagChange = (event, newValue) => {
        setSelectedTag(newValue);
      };
    
    const tagContentObj = tagContent.find((item) => item.id === selectedTag);

    useEffect(()=>{
      let location = window.location.href;
      let tabs = ['writing','sales','hr','analytics','legal','coding','support','education']
      const pattern1 = location.match(/tab=(.*?)&/);
      const pattern2 = location.match(/content=(.*)/);
      let tags = []
      if(pattern1){
        tags = pattern1
      }else tags = pattern2
      if (tags && tags[1]) {
        if(tabs.indexOf(tags[1]) > 0){
          setSelectedTag(tabs.indexOf(tags[1]))
        }else setSelectedTag(0)
      }
  },[])

    return (
        <Grid p={2} sx={{border:'solid 3px var(--ynt-lightpurple)', borderRadius:'20px'}}>
            <Grid container>
                <Grid item xs={12} my={1}>
                <TypingBottomNavi value={selectedTag} onChange={handleTagChange} showLabels>
                {tagContent.map((item) => (
                    <BottomNavigationAction sx={{py:1, minWidth:{xs:'80px',lg:'60px'},maxWidth:{xs:'100px',lg:'150px'}}} key={item.id} value={item.id} label={item.label} icon={item.icon} onClick={()=>setAskingTextComplete(false)}/>
                ))}
                <BottomNavigationAction sx={{py:1, minWidth:{xs:'80px',lg:'60px'},maxWidth:{xs:'100px',lg:'150px'}}} value='' label='More' icon={<ControlPoint/>} onClick={()=>{window.location.href = 'https://younet.ai/portal/sign-up'}}/>
                </TypingBottomNavi>
                </Grid>
            </Grid>
            {tagContentObj &&
              <>
              <Grid container py={2}>
                <Grid container p={2} mb={3} sx={{backgroundColor:'var(--ynt-lightgrey)', borderRadius:'20px'}}>
                <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <Avatar alt="User avatar" src={tagContentObj.avatar1} style={{ marginRight: '0.5rem' }}>{tagContentObj.avatar1}</Avatar>
                  <h5>{tagContentObj.role}</h5>
                  </Grid>
                  <Grid item xs={12} p={2} sx={{height:{lg:'80px',xs:'110px'},minWidth:'100%'}}>
                    <AskingTypingEffect onCompleted={() => setAskingTextComplete(true)} text={tagContentObj.sentence1}/>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} px={1} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <Avatar sx={{ bgcolor: 'var(--ynt-primary)'}} alt="User avatar" src={tagContentObj.avatar2} style={{ marginRight: '0.5rem' }}/>
                  <h5 style={{color:'var(--ynt-primary)'}}>AI Response</h5>
                  </Grid>
                  <Grid item xs={12} p={2} sx={{height:{lg:'220px',xs:'380px'},minWidth:'100%'}}>
                      {!askingTextComplete && <AnsweringTypingEffect text=''/>}
                      {askingTextComplete && <AnsweringTypingEffect text={tagContentObj.sentence2}/>}
                  </Grid>
                </Grid>
              </Grid>
                <Grid px={2} display='flex' flexDirection={{xs:'column',lg:'row'}} alignItems={{xs:'left',lg:'center'}} className='info-span' sx={{opacity:askingTextComplete? 1.0:0.0,minWidth:'257px'}}>
                      <Grid item pr={1} py={1}><p>Generated based on: </p></Grid>
                      {tagContentObj.fileNames.map((item,i)=>(
                        <Grid item display='flex' flexDirection='row' alignItems='center' key={i}><Description/><Grid px={0.5}><p>{item}</p></Grid></Grid>
                      ))}
                </Grid>
              </>
        }
        </Grid>
    );
}

export default LandingFeatures;