import React,{ useState, useEffect }  from 'react';

export const AskingTypingEffect =({ text, onCompleted })=> {
  const [content, setContent] = useState('');

  useEffect(() => {
    let i = 0;
    const intervalId = setInterval(() => {
      if (i < text.length) {
        setContent(text.substring(0, i + 1));
        i++;
      } else {
        clearInterval(intervalId);
        if (onCompleted) {
          onCompleted();
        }
      }
    }, 50);

    return () => clearInterval(intervalId);
  }, [text]);

return (
  <p className="typing-effect" style={{whiteSpace: 'pre-line',lineHeight:'1.5em'}}>
    {content}
  </p>
);
}

export const AnsweringTypingEffect =({ text, onCompleted })=> {
    const [content, setContent] = useState('');
  
    useEffect(() => {
      let i = 0;
      const intervalId = setInterval(() => {
        if (i < text.length) {
          setContent(text.substring(0, i + 1));
          i++;
        } else {
          clearInterval(intervalId);
          if (onCompleted) {
            onCompleted();
          }
        }
      }, 50);
  
      return () => clearInterval(intervalId);
    }, [text]);

  return (
    <div>
      <p className="typing-effect" style={{whiteSpace: 'pre-wrap',lineHeight:'1.5em'}}>{content}<span className='typing-cursor'></span></p>
    </div>
  );
}

