import React from 'react';
import { NarrowContainer, WideContainer } from 'components/mui/containers/PxftContainer';
import { Grid,Slide } from '@mui/material';
import { useInView } from 'react-intersection-observer'

function SupportedCompanies(props) {
    const logoSrc = [
        '/images/home/logos/business_logo_1.png',
        '/images/home/logos/business_logo_2.png',
        '/images/home/logos/business_logo_3.png',
        '/images/home/logos/business_logo_4.png',
        '/images/home/logos/business_logo_5.png',
        '/images/home/logos/business_logo_6.png',
    ]
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });
    const containerRef = React.useRef(null);

    return (
        <WideContainer>
        <div className='center-align' style={{ overflow: 'hidden' }}>
            <Slide timeout={{enter:800}} in={inView} direction="up" container={containerRef.current}>
            <div>
            <NarrowContainer className='center-align supported-logos'>
                    <p>Supported by:</p>
                        {
                            logoSrc.map((item,i)=>
                                (
                                    <Grid key={i} px={2} py={1}>
                                        <img loading='lazy' src={item} alt=''/>
                                    </Grid>
                                )
                            )
                        }
            </NarrowContainer>
            </div>
            </Slide>
        <div className='observer' ref={inViewRef}></div>
        </div>
    </WideContainer>
    );
}

export default SupportedCompanies;