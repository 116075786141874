import React from 'react';
import { Grid, Grow} from '@mui/material';
import { useInView } from 'react-intersection-observer'
import CenterTitle from 'components/mui/containers/centerTitle';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import UseCasesVideo from './UseCasesVideo';

const rows = [
    {
        title: 'Playground',
        content: 'Chat and interact with your personalized AI model.',
        youtubeMobileSrc: 'https://www.youtube.com/embed/5bBo91LhZDo?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=5bBo91LhZDo&hd=1',
        youtubeEmbededSrc: 'https://www.youtube.com/embed/5bBo91LhZDo?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=5bBo91LhZDo&hd=1',
        youtubeTitle: 'Younet Playground',
    },
    {
        title: 'Email',
        content: 'Generate responses to emails effortlessly, reflecting your unique communication style.',
        youtubeMobileSrc: 'https://www.youtube.com/embed/_Y4YLB0nDe4?si=Av1-UK4KkWBfUI_Y?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=_Y4YLB0nDe4&hd=1',
        youtubeEmbededSrc: 'https://www.youtube.com/embed/_Y4YLB0nDe4?si=Av1-UK4KkWBfUI_Y?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=_Y4YLB0nDe4&hd=1',
        youtubeTitle: 'Younet Email',
    },
    {
        title: 'Text forms',
        content: 'Generate content with the @younet command. Simply include it in any text form and let AI assist you in writing.',
        youtubeMobileSrc: 'https://www.youtube.com/embed/ASYBk2eXGnw?si=GkSw02OB6oGI1oxr?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=ASYBk2eXGnw&hd=1',
        youtubeEmbededSrc: 'https://www.youtube.com/embed/ASYBk2eXGnw?si=GkSw02OB6oGI1oxr?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=ASYBk2eXGnw&hd=1',
        youtubeTitle: 'Younet Content',
    },
    {
        title: 'Social media',
        content: 'Generate personalized content and engage with your audience more effectively.',
        youtubeMobileSrc: 'https://www.youtube.com/embed/mq5CelBPbgE?si=9fyu-Uo4caqhXcBU?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=mq5CelBPbgE&hd=1',
        youtubeEmbededSrc: 'https://www.youtube.com/embed/mq5CelBPbgE?si=9fyu-Uo4caqhXcBU?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=mq5CelBPbgE&hd=1',
        youtubeTitle: 'Younet Social Media',
    },
    {
        title: 'Speak to any file',
        content: 'Select the uploaded document and ask it any questions using the neural network.',
        youtubeMobileSrc: 'https://www.youtube.com/embed/g4HoNWNQHII?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=g4HoNWNQHII&hd=1',
        youtubeEmbededSrc: 'https://www.youtube.com/embed/g4HoNWNQHII?&controls=1&autopause=0&&autoplay=0&mute=1&loop=1&playlist=g4HoNWNQHII&hd=1',
        youtubeTitle: 'Younet File',
    },
]

const UseCasesVideos = () => {
    const [inViewRef, inView] = useInView({
        triggerOnce: true,
    });

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <WideContainer>

            <div className='center-align'>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grow timeout={{ enter: 1200 }} in={inView}>
                        <Box>
                            <CenterTitle title='Personalized AI for work' />
                            <Box className='center-align' flexDirection='column'>
                                <Box py={2} maxWidth={{ xs: '100%', md: '40%' }}>
                                    <p style={{ textAlign: 'center' }}>Today, we are bringing the power of next-generation AI to work. It combines the power of large language models(LLMs) with your data and turns your words into the most powerful productivity tool on the planet to expedite your work.</p>
                                </Box>
                                <Box maxWidth={{ xs: '100%', md: '40%' }}>
                                    <p style={{ textAlign: 'center' }}>Also works on any web-based platform with a Browser Extension (Chrome or Edge).</p>
                                </Box>
                            </Box>
                            <div className='observer' ref={inViewRef}></div>
                        </Box>
                    </Grow>
                    <Box pt={3} className='center-align' display='flex' flexDirection='column'>
                        <Box pt={3} sx={{ width: '80%', maxWidth: '800px' }} className='center-align'>
                            {isMobile ? <img width='100%' src='/images/home/extension-support-mobile.png' alt='extension-logos' /> :
                                <img width='100%' src='/images/home/extension-support.png' alt='extension-logos' />}
                        </Box>
                    </Box>
                </Grid>
            </div>
            {rows.map((item, index) => (
                <Grid key={index}>
                    <UseCasesVideo info={item} />
                </Grid>
            ))}
        </WideContainer>
    );
}

export default UseCasesVideos;