import React, { useState,useEffect}  from 'react';
import BlogsArticle from 'components/blogs/BlogsArticle';
import BlogsHeader from 'components/blogs/Header';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { API_PATHS } from 'constants/apiPaths';
import axios from 'axios';

const chips = [
    {title:'All Categories',
    value:'all'},
    {title:'Announcements',
    value:'announcements'},
    {title:'News',
    value:'news'},
    {title:'Product Update',
    value:'product updates'},
    {title:'Uses Cases',
    value:'use cases'},
    {title:'Event',
    value:'event'},
]

const Blogs=()=> {
    const[blogs,setBlogs]=useState([])
    const[selectedBlogs,setSelectedBlogs]=useState([])
    const[layoutBlogs,setLayoutBlogs]=useState([])
    const blogsPerPage = 6
    const[pageCount,setPageCount] = useState(0)
    const[currpage,setCurrpage]=useState(1)
    const[blogOffset,setBlogOffset] = useState(0)
    const[isDataLoaded,setDataLoaded]=useState(false)


    const getBlogs = async () => {

        await axios.get(API_PATHS.getBlogs())
            .then(
                (res) => {
                    setBlogs(res.data.blogs || { blogs: [] });
                    setDataLoaded(true);
                }
            )
            .catch((error) => {
                setBlogs([]);
                setDataLoaded(true);
            });
    }


    useEffect(() => {
        getBlogs();
    }, []);

    const handlePageClick = (event,page) => {
        let pathname = window.location.pathname
        let url = pathname.split('/')
        let chipsValue=['announcements','product updates','use cases','event','news']
    
        if(url[1]==='blogs' && chipsValue.includes(url[2])){
            if(url[2]!=='page')
            window.location.href = `/blogs/${url[2]}/${page}`;
            else
            window.location.href = `/blogs/page/${page}`;
        }else
        window.location.href = `/blogs/page/${page}`;
    };

    const setPage = (blogs,currentPage)=>{
        setBlogOffset((currentPage-1) * blogsPerPage);
        let blogOff = (currentPage-1) * blogsPerPage;
    
        const endOffset = blogOff + blogsPerPage;
        setLayoutBlogs(blogs.slice(blogOff,endOffset));
        setPageCount(Math.ceil(blogs.length/blogsPerPage));
    }

    useEffect(()=>{
        if(blogs.length === 0) {
            return;
        }
        window.scrollTo(0, 0)
        let pathname = window.location.pathname
        let url = pathname.split('/')
        let chipsValue=['all','announcements','product updates','use cases','event','news']

        if(url[1]==='blogs' && url[2]==='page' && url[3]){
            setCurrpage(url[3])
            setPage(blogs,url[3])
        }else if(url[1]==='blogs' && chipsValue.includes(decodeURI(url[2]))){
            let category = decodeURI(url[2])
            if(category.toLowerCase()==='all'){
                setPage(blogs,1)
            }else{
                setSelectedBlogs(blogs.filter(blog=>{ 
                    return blog.categories.toLowerCase().includes(category.toLowerCase());
                }))
            }
        }
        else{
            const endOffset = blogOffset + blogsPerPage;
            setLayoutBlogs(blogs.slice(blogOffset,endOffset));
            setPageCount(Math.ceil(blogs.length/blogsPerPage));
        }
    },[isDataLoaded,setBlogs]);

    useEffect(()=>{
        let pathname = window.location.pathname
        let url = pathname.split('/')
        if(url[3]){setCurrpage(url[3])}
        setPage(selectedBlogs,currpage)
    },[selectedBlogs,currpage])


    return (
        <div>
            <BlogsHeader chips={chips}/>
            {blogs.length === 0 ? <p> </p> :<BlogsArticle blogs={layoutBlogs}/> }
            <Stack sx={{py:6}} spacing={2} className='center-align'> <Pagination page={parseInt(currpage)} onChange={handlePageClick} count={pageCount} variant="outlined" shape="rounded" /> </Stack>
        </div>
    );
}

export default Blogs;