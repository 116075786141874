import React from 'react';
import { Grid } from '@mui/material';
import HeaderNavigation from 'components/HeaderNavigation/HeaderNavigation';

const HomeNavigator=()=> {
    return (
        <Grid
            item
            xs={12}
            sx={{
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            maxHeight:'60px'
            }}
        >
            <div className='home-navi'>
            <HeaderNavigation/>
            </div>
        </Grid>
    );
}

export default HomeNavigator;