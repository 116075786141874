const createPixel = (url = '', onLoad = ()=> {}, onError = () => {},params = {}) => {
    return new Promise((resolve, reject) => {
        let img = document.createElement('img');

        img.onload = () => {
            onLoad();
            resolve('Image loaded successfully');
        };


        img.onerror = () => {

            if(params.allowError) {
                resolve('Pixel handled');
            } else {
                reject(new Error('Failed to load image'));
            }

            onError();
        };

        img.style.display = 'none';
        img.src = url;
        document.getElementById('body').appendChild(img);
    });
};



export default createPixel;