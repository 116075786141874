import React, { useState, useEffect} from 'react';
import {Grid} from '@mui/material';
import LogoSvgBig from 'components/logoSvg/logoSvgBig';
import LandingFeatures from './LandingFeatures';
import LandingLogin from './LandingLogin';
import StickyLink from './StickyLink';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const LandingArticle=()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [scroll, setScroll] = useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
    }
    }, []);

    return (
        <>
            {
                scroll > 200 && isMobile && <StickyLink/>
            }
            <Grid item xs={12} className="home-article-container">
            <Grid container className='home-article'>
                <Grid item xs={12}>
                    <Grid container px={{md:6}} className='center-align'>
                        <Grid className='home-logo'item xs={12} md={10} px={{md:6}}>
                            <LogoSvgBig/>
                        </Grid>
                        <Grid container className='center-align' flexDirection={{xs:'column-reverse',lg:'row'}}>
                            <Grid item xs={12} lg={8.5} xl={6} px={{md:6}} pt={{xs:3,md:6}}>
                                <LandingFeatures/>
                            </Grid>
                            <Grid item xs={12} lg={3.5} xl={4} pt={{xs:3,md:0}}>
                                <LandingLogin/>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ pt: { xs: 3 } }}>
                            <Grid item xs={12}>
                            <SupportedCompanies/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </>
    );
}

export default LandingArticle;