import React from 'react';
import { Grid } from '@mui/material';
import { WideContainer } from 'components/mui/containers/PxftContainer';
import Header from '../Header';
import GetStartedCard from 'components/mui/cards/getStartedCard';
import ContentRowA from '../ContentRowA';
import ContentRowB from '../ContentRowB';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ScrollableQuotes from 'components/overview/ScrollableQuotes';
import LinkCard from '../LinkCard';
import SupportedCompanies from 'components/mui/cards/SupportedCompanies';

const Sales = ()=> {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const rows = [
        {
            title:'Respond with personalized messages anywhere',
            contents:'Younet AI helps draft and respond anywhere in under a minute with personalized suggestions, ensuring efficient and professional communication. Add the browser extension to use your personalized AI across various applications.',
            card:{
                askAvatar:'/images/solution/sales/avatar1.png',
                askContent:'Need some more detailed info on products and services.',
                answerContent:'I‘m happy to help you with that! Our main product is <…>'
            },
            picsrc:'/images/home/extension-support.png',
            picsrcMobile:'/images/home/extension-support-mobile.png',
        },
        {
            title:'Keep track of performance',
            contents:'Get insights and predictions based on your data just by uploading files and asking questions. ',
            card:{
                askAvatar:'/images/solution/sales/avatar2.png',
                askContent:'Analyze growth for the past 6 years and show trends for each product.',
                answerContent:'Across all plans, the subscriber numbers have grown to <…>'
            }
        },
        {
            title:'Centralized knowledge',
            contents:'Imagine a sales force equipped with instant access to a centralized AI knowledge hub, capable of answering questions across all departments at the click of a button. No more waiting for human responses; your AI models provide immediate, accurate information to keep your sales momentum going.',
            card:{
                askAvatar:'/images/solution/sales/avatar3.png',
                askContent:'I need API documentation information for a client. Can you help?',
                answerContent:'I can help you with that. Here is information for API integration <…>'
            }
        },
        {
            title:'Seamless employee onboarding',
            contents:'No more weeks of training. New employees can hit the ground running from day one, as they have immediate access to an AI model well-versed in the company‘s products and operations.',
            card:{
                askAvatar:'/images/solution/sales/avatar4.png',
                askContent:'Prepare me for a meeting with a client to discuss our new product line.',
                answerContent:'Sure! Here‘s a brief overview of our new products and features that you can discuss with your client: <…>'
            }
        }
    ]
    const cards = [
        {
            title:'Solution for Support',
            content:'Boost your support team‘s efficiency and accuracy with Younet AI’s intelligent automation, more personalized customer service experiences.',
            link:'/solution-support',
        },
        {
            title:'Solution for Legal',
            content:'Leverage large language AI models to optimize contract terms, ensuring clarity and precision while safeguarding your interests.',
            link:'/solution-legal',
        },
        {
            title:'Solution for Marketing',
            content:'Use generative AI to supercharge your marketing team‘s capabilities, enabling them to create compelling content with unprecedented speed.',
            link:'/solution-marketing',
        },
        {
            title:'Solution for HR',
            content:'Enhance your HR operations with AI-driven solutions that streamline talent acquisition, optimize workforce management, and personalize employee engagement.',
            link:'/solution-hr',
        },
        {
            title:'Solution for Finance',
            content:'Transform your finance department with AI that provides real-time insights into financial health, automates complex accounting tasks, and predicts future trends.',
            link:'/solution-finance',
        },
        {
            title:'Solution for Engineering',
            content:'Supercharge your software development lifecycle with AI that assists in code generation, bug detection, and automated testing.',
            link:'/solution-engineering',
        },
    ]
    const headerTitles = [
        'Solution for Sales Teams',
        'Empower Your',
        'Sales Team with AI'
    ]
    const headerContent = 'Empower your sales teams with AI to proficiently manage deals simultaneously, guaranteeing smooth coordination of various transactions. Ensure every team member has rapid access to critical data, facilitating a quicker path to increased revenue generation.'
    const tagContentObj =           
    {
      label: 'Sales',
      avatar1: 'P',
      avatar2: '/images/btn.png',
      role:'Prompt to Sales & Marketing',
      fileNames:[
        'Products & Services info.pdf',
        'Price-list.xlsx'
      ],
      sentence1: 'Write an email to David and introduce our new products and services.',
      sentence2: 'Subject: Discover What‘s New – Innovation Meets Affordability!\n\nDear David,\n\nWe’re excited to unveil our latest range of products and services designed to elevate your mobile experience, including cutting-edge smartphones at exclusive discounts and tailored < ... >',
    }
    return (
        <WideContainer>
            <Header titles={headerTitles} content={headerContent} tagContentObj={tagContentObj}/>
            <Grid container sx={{ py: { md: 3 } }}>
                <SupportedCompanies/>
            </Grid>
            <Grid container className='center-align' flexDirection={'column'}>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[0].title} card={rows[0].card} contents={rows[0].contents} picSrc={isMobile? rows[0].picsrcMobile : rows[0].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}} mb={{xs:0,lg:8}}>
                    <ContentRowB title={rows[1].title} card={rows[1].card} contents={rows[1].contents} picSrc={isMobile? rows[1].picsrcMobile : rows[1].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowA title={rows[2].title} card={rows[2].card} contents={rows[2].contents} picSrc={isMobile? rows[2].picsrcMobile : rows[2].picsrc}  button={false}/>
                </Grid>
                <Grid item py={{xs:0,lg:4}}>
                    <ContentRowB title={rows[3].title} card={rows[3].card} contents={rows[3].contents} picSrc={isMobile? rows[3].picsrcMobile : rows[3].picsrc}  button={false}/>
                </Grid>
                <Grid item px={2} width='100%' maxWidth={'1500px'}>
                    <ScrollableQuotes/>
                </Grid>
                <Grid item px={2}>
                    <GetStartedCard showLink={false}/>
                </Grid>
                <Grid item pb={{xs:4}} px={2} width='100%' maxWidth={'1500px'}>
                    <Grid container>
                        {
                            cards.map((card,i)=>
                                <Grid key={i} item xs={12} lg={4} className='center-align'>
                                    <LinkCard card={card}/>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
        </WideContainer>
    );
}

export default Sales;