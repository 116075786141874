import Hr from 'components/solution/hr/Hr';
import React,{ useEffect} from 'react';

const SolutionHR = () => {
    useEffect(()=>{
        window.scrollTo(0, 0)}
    )

    return (
        <div>
            <Hr/>
        </div>
    );
}

export default SolutionHR;